import { useCallback, useEffect, useReducer } from 'react'

interface SuccessResult {
  type: 'success'
  stream: MediaStream
  error: undefined
  loading: false
}

interface ErrorResult {
  type: 'error'
  stream: undefined
  error: string | null
  loading: false
}

interface LoadingResult {
  type: 'loading'
  stream: undefined
  error: undefined
  loading: true
}

interface UnloadedResult {
  type: 'unloaded'
  stream: undefined
  error: undefined
  loading: false
}

interface LoadingState {
  type: 'loading'
}

interface ErrorState {
  type: 'error'
  error: string | null
}

interface ReadyState {
  type: 'ready'
  stream: MediaStream
}

interface ClosedState {
  type: 'closed'
}

interface UnloadedState {
  type: 'unloaded'
}

type State = LoadingState | ErrorState | ReadyState | ClosedState | UnloadedState

const closeStream = (stream: MediaStream) => {
  stream.getTracks().forEach(t => t.stop())
}

const stateReducer = (state: State, action: State) => {
  switch (state.type) {
    case 'ready': {
      closeStream(state.stream)
      return action
    }
    case 'closed': {
      if (action.type === 'ready') {
        //This hook was closed while getUserMedia callback was pending. We close the stream from the resolved callback and remain in closed state.
        closeStream(action.stream)
        return state
      }
      return action
    }
    default:
      return action
  }
}

export function useMediaStream(constraints: MediaStreamConstraints): SuccessResult | ErrorResult | LoadingResult {
  const [load, state] = useLazyMediaStream(constraints)
  useEffect(() => {
    load().catch(err => {
      console.log(err)
    })
  }, [load])
  return state.type === 'unloaded' ? { type: 'loading', loading: true, error: undefined, stream: undefined } : state
}

export type LoadFn = () => Promise<void>

export function useLazyMediaStream(
  constraints: MediaStreamConstraints
): [LoadFn, UnloadedResult | LoadingResult | SuccessResult | ErrorResult] {
  const [state, setState] = useReducer(stateReducer, { type: 'unloaded' })

  const onSuccess = useCallback(
    (stream: MediaStream) => {
      stream.getTracks().forEach(track => {
        track.onended = () => {
          setState({ type: 'error', error: 'Track ended' })
        }
      })
      setState({ type: 'ready', stream: stream })
    },
    [setState]
  )

  const onFailure = useCallback(
    (err: { message: string | null }) => {
      setState({ type: 'error', error: err.message })
    },
    [setState]
  )

  const load = useCallback<LoadFn>(() => {
    setState({ type: 'loading' })
    return new Promise<void>((resolve, reject) => {
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream: MediaStream) => {
            onSuccess(stream)
            resolve()
          })
          .catch((err: DOMException) => {
            onFailure(err)
            reject()
          })
      } else {
        onFailure({ message: `Can't find a video device` })
        reject()
      }
    })
  }, [constraints, onSuccess, onFailure])

  const isLoaded = state.type !== 'unloaded'
  useEffect(() => {
    if (isLoaded) {
      load().catch(err => {
        console.log(err)
      })
    }
  }, [constraints, load, isLoaded])

  switch (state.type) {
    case 'unloaded':
      return [load, { type: 'unloaded', stream: undefined, error: undefined, loading: false }]
    case 'ready':
      return [load, { type: 'success', stream: state.stream, error: undefined, loading: false }]
    case 'error':
      return [load, { type: 'error', stream: undefined, error: state.error, loading: false }]
    case 'loading':
      return [load, { type: 'loading', stream: undefined, error: undefined, loading: true }]
    case 'closed':
      throw new Error('Invalid state. Mediastream is closed')
  }
}
