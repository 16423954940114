import Rating from '@mui/material/Rating'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CustomValue } from '@tm/shared-lib/src/api'
import { CampaignSubmit } from '../../CampaignSubmit'

type StarQuestionViewProps = {
  name?: string
  value?: number
  label?: string
  onChange?: (value: CustomValue) => void
  description?: string
  disabled?: boolean
  showSubmit?: boolean
  onSubmit?: () => void
}

export function StarQuestionView(props: StarQuestionViewProps) {
  const { value, label, onChange, description, disabled, name, showSubmit, onSubmit } = props

  return (
    <>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        {label}
      </Typography>
      <Stack
        id="stars-question"
        sx={{
          background: '#fff',
          boxShadow: theme => theme.customShadows.card,
          borderRadius: 1,
          p: 3,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Rating
          value={value || null}
          size="large"
          disabled={disabled}
          precision={1}
          max={5}
          name={`q-${name || 'question'}`}
          sx={{
            '.MuiRating-icon svg': {
              width: { xs: 48, sm: 60 },
              height: { xs: 48, sm: 60 },
            },
          }}
          onChange={(_event, newValue) => {
            if (!onChange) return
            // In Rating, if current value is clicked it will set value to null
            // and in surveys we want to let user unset value
            // User will have current value if they return to the form
            onChange(newValue === null && typeof value === 'number' ? value : newValue)
          }}
        />
      </Stack>
      {!!description && (
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {description}
        </Typography>
      )}
      {!!showSubmit && (
        <CampaignSubmit
          onClick={() => {
            onSubmit && onSubmit()
          }}
        />
      )}
    </>
  )
}
