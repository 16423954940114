import { Field } from '@tm/shared-lib/src/field'
import { Translation } from '@tm/shared-lib/src/i18n'
import { useCallback, useMemo } from 'react'
import { useI18N } from '../../../../hooks/useI18n'
import { useConsentField } from '../../ConsentField/useConsentField'
import { ViewProps } from '../types'
import { useLogoPictureField } from './useLogoPictureField'
import { useProfilePictureField } from './useProfilePictureField'
import { ContactInfoFieldName, ReviewFields, isContactInfoFieldName } from './useReviewFields'
import { useTextReview } from './useTextReview'
import { useVideoReview } from './useVideoReview'
import { useSkipButton } from './useSkipButton'

const getLabelKey = (fields: Map<ContactInfoFieldName, Field>, name: ContactInfoFieldName, defaultKey: string) => {
  const field = fields.get(name)
  return field && 'labelKey' in field && field.labelKey ? field.labelKey : defaultKey
}

export function useContactInfo(props: ViewProps, reviewFields: ReviewFields, contactInfoLabel: Translation | null) {
  const { state, dispatch, validationErrors, submitForm } = props
  const consentFieldProps = useConsentField({ state, dispatch, validationErrors }, 'publicConsent')
  const { t } = useI18N()

  const fields = useMemo(() => {
    return new Map([...reviewFields.entries()].filter(([name]) => isContactInfoFieldName(name))) as Map<
      ContactInfoFieldName,
      Field
    >
  }, [reviewFields])

  const contactInfoValues = useMemo(() => {
    const contactInfoValues = {}

    for (const [name, field] of fields) {
      const fieldValue = state.values[name]
      if (isContactInfoFieldName(name)) {
        if (field.type === 'textfield') contactInfoValues[name] = fieldValue
        if (field.type === 'image') contactInfoValues[name] = true
      }
    }

    return contactInfoValues
  }, [state.values, fields])

  const labels = {
    name: t(getLabelKey(fields, 'name', 'contactInfo.name')),
    location: t(getLabelKey(fields, 'location', 'contactInfo.location')),
    organization: t(getLabelKey(fields, 'organization', 'contactInfo.organization')),
    imageFilename: t(getLabelKey(fields, 'imageFilename', 'contactInfo.imageFilename')),
    position: t(getLabelKey(fields, 'position', 'contactInfo.position')),
    team: t(getLabelKey(fields, 'team', 'contactInfo.team')),
    companyLogo: t(getLabelKey(fields, 'companyLogo', 'contactInfo.companyLogo')),
    website: t(getLabelKey(fields, 'website', 'contactInfo.website')),
    neighborhood: t(getLabelKey(fields, 'neighborhood', 'contactInfo.neighborhood')),
    state: t(getLabelKey(fields, 'state', 'contactInfo.state')),
    country: t(getLabelKey(fields, 'country', 'contactInfo.country')),
  }

  const onContactInfoChange = useCallback(
    (name: ContactInfoFieldName, value: string) => {
      dispatch({ type: 'set value', field: { name, system: true }, value })
    },
    [dispatch]
  )

  const contactInfoValidationErrors = useMemo(() => {
    const errors: Record<string, string[]> = {}

    for (const [name] of fields) {
      const fieldErrors = validationErrors[name]
      if (Array.isArray(fieldErrors)) errors[name] = fieldErrors.map(error => t(error))
    }

    return errors
  }, [validationErrors, fields, t])

  /**
   * Create array of required fields so we can show asterisk in ui
   */
  const requiredFields = useMemo(() => {
    const requiredFields: ContactInfoFieldName[] = []

    for (const [name, field] of fields.entries()) {
      if (field.validation?.includes('required')) requiredFields.push(name)
    }

    return requiredFields
  }, [fields])

  const title = contactInfoLabel?.[state.lang] || null

  const profilePictureFieldProps = useProfilePictureField(props, fields)
  const logoPictureFieldProps = useLogoPictureField(props, fields)

  return {
    requiredFields,
    consentFieldProps,
    values: contactInfoValues,
    labels,
    onChange: onContactInfoChange,
    errors: contactInfoValidationErrors,
    isSubmitting: false,
    title,
    onSubmit: submitForm,
    profilePictureFieldProps,
    logoPictureFieldProps,
    textReviewProps: useTextReview(props, reviewFields),
    videoReviewProps: useVideoReview(props, reviewFields),
    skipProps: useSkipButton(props, reviewFields),
  }
}

export type UseContactInfo = ReturnType<typeof useContactInfo>
