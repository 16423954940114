import { InputProps, stringValue, arrayValue } from './types'
import { DropdownField, MultidropdownField } from '@tm/shared-lib/src/field'
import TextField from '@mui/material/TextField'
import { getTranslatedValue } from './getTranslatedValue'
import MenuItem from '@mui/material/MenuItem'

export function DropdownInput(props: InputProps<DropdownField | MultidropdownField>) {
  const { field, value, onChange, isSubmitting, lang } = props
  const isMultiple = field.type === 'multidropdown'

  const label = getTranslatedValue(field.label, lang)

  return (
    <TextField
      value={isMultiple ? arrayValue(value) : stringValue(value, '')}
      onChange={event => {
        onChange(event.target.value)
      }}
      disabled={isSubmitting}
      fullWidth
      variant="filled"
      key={`f-${field.name}`}
      hiddenLabel={!label}
      label={label}
      SelectProps={{
        multiple: field.type === 'multidropdown',
      }}
      select>
      {field.options?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {getTranslatedValue(option.label, lang)}
        </MenuItem>
      ))}
    </TextField>
  )
}
