import { useEffect } from 'react'
import { useImmer } from 'use-immer'

interface State {
  loading: boolean
  devices?: MediaDeviceInfo[] | undefined
  error?: Error
}

//Warning: Permissions should be granted before using this hook or the returned devices array will contain empty labels and deviceIds.
export function useMediaDevices(): State {
  const [state, setState] = useImmer<State>({ loading: true })

  useEffect(() => {
    setState(draft => {
      draft.loading = true
      draft.error = undefined
      draft.devices = undefined
    })
    navigator.mediaDevices.enumerateDevices().then(
      (devices: MediaDeviceInfo[]) => {
        setState(draft => {
          draft.loading = false
          draft.error = undefined
          draft.devices = devices
        })
      },
      (reason: Error) => {
        setState(draft => {
          draft.loading = false
          draft.error = reason
          draft.devices = undefined
        })
      }
    )
  }, [setState])

  return state
}
