import { useMemo, useState } from 'react'
import { ReviewState } from './types'
import { ReviewFields } from './useReviewFields'

export function usePlatformFields(reviewFields: ReviewFields, lang: string) {
  /**
   * Get available sources from sources field
   */
  const platformFields = useMemo(() => {
    // If there's text and video review, use platform view to select type
    const hasTextReview = !!reviewFields.get('comment')
    const hasVideoReview = !!reviewFields.get('comment_video')

    const sourcesField = reviewFields.get('sources')
    if (!sourcesField || sourcesField.type !== 'third-party-collect')
      return {
        sources: null,
        sourcesLabel: null,
        showPlatformView: hasTextReview && hasVideoReview,
        hasTextReview,
        hasVideoReview,
        lang,
      }

    const sources = sourcesField.sources?.length ? sourcesField.sources : null
    const sourcesLabel = sourcesField.label?.[lang] || null

    // Platform view is used when redirecting
    const showPlatformView = sources && sources.filter(item => item.id !== 'trustmary').length > 0

    return {
      sources,
      sourcesLabel,
      showPlatformView,
      hasTextReview,
      hasVideoReview,
      lang,
    }
  }, [reviewFields, lang])

  const [reviewState, setReviewState] = useState<ReviewState>(
    platformFields.showPlatformView ? 'choose platform' : 'contact info'
  )

  return {
    ...platformFields,
    reviewState,
    setReviewState,
  }
}

export type UsePlatformFields = ReturnType<typeof usePlatformFields>
