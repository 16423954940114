import React, { useEffect, useReducer, useRef } from 'react'

interface Dimensions {
  width: number
  height: number
}

function dimensionReducer(state: Dimensions | undefined, action: Dimensions | undefined): Dimensions | undefined {
  if (state === undefined || action === undefined) {
    return action
  } else if (state.width !== action.width || state.height !== action.height) {
    return action
  } else {
    return state
  }
}

export function AutoSizer({
  children,
  className,
}: {
  children: (props: { width: number; height: number }) => JSX.Element
  className?: string
}): JSX.Element {
  const [dimensions, dispatchDimensions] = useReducer(dimensionReducer, undefined)
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const measureDiv = () => {
      if (divRef.current) {
        dispatchDimensions({ width: divRef.current.clientWidth, height: divRef.current.clientHeight })
      }
    }
    measureDiv()
    window.addEventListener('resize', measureDiv)
    return () => {
      window.removeEventListener('resize', measureDiv)
    }
  }, [dispatchDimensions])

  return (
    <div className={className} style={{ height: '100%', width: '100%' }} ref={divRef}>
      {dimensions && children(dimensions)}
    </div>
  )
}
