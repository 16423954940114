import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress'
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import Icon from '@trustmary/icons'
import { styled } from 'goober'
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Updater } from 'use-immer'
import { useI18N } from '../../../hooks/useI18n'
import { useMediaDevices } from '../../../hooks/useMediaDevices'
import { DeviceSelector } from './DeviceSelector'
import { AnchoredMenu } from './Menu'
import { CircleButton, RoundedSquareDiv, StartRecordingButton, StartRecordingPreviewButton } from './VideoButtons'
import { VideoElement } from './VideoPreview'
import { TransparentCircleButton, VideoToolbar } from './VideoToolbar'

interface VideoRecordProps {
  stream: MediaStream
  onStop: (blob: Blob, fileExtension: string, mimeType?: string) => void
  onUpload: (file: File) => void
  setConstraints: Updater<MediaStreamConstraints>
  fullscreenEnabled: boolean
  setFullscreenEnabled: (value: boolean) => void
  //onRequestFullscreen?: () => void
}

const StopRecordingOuterCircleButton = styled(CircleButton)({
  width: '70px',
  height: '70px',
  flex: '0 0 auto',
  backgroundColor: '#FFFFFF',
  '&:disabled': {
    cursor: 'default',
  },
  '&:hover:enabled': {
    backgroundColor: '#F4E9E7',
  },
  div: {
    width: '25%',
    height: '25%',
  },
  '@media (max-width: 900px)': {
    width: '40px',
    height: '40px',
  },
})

const RecordingHint = styled('div')({
  flex: '1 1 0',
  textAlign: 'center',
})

const RecordingCountDownDiv = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  zIndex: 2,
  fontSize: '85px',
  fontWeight: 600,
  color: '#FFFFFF',
  pointerEvents: 'none',
  '@media (max-width: 900px)': {
    fontSize: '65px',
  },
})

const RecordActionsWrapper = styled('div')({
  color: '#FFFFFF',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  padding: '0 30px',
  bottom: '30px',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
  },
})

const FullscreenButton = styled(TransparentCircleButton)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: 0,
  marginRight: 'min(3%, 25px)',
  marginBottom: 'min(3%, 25px)',
  zIndex: 2,
})

function StopRecordingButton(
  props: React.ClassAttributes<HTMLButtonElement> & React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  return (
    <StopRecordingOuterCircleButton {...props}>
      <RoundedSquareDiv borderRadius="25%" backgroundColor="#DE715D" />
    </StopRecordingOuterCircleButton>
  )
}

export function VideoRecord({
  stream,
  onStop,
  onUpload,
  setConstraints,
  fullscreenEnabled,
  setFullscreenEnabled,
}: //onRequestFullscreen,
VideoRecordProps): JSX.Element {
  const [recordingStatus, setRecordingStatus] = useState<boolean | number | 'start'>(false) //false = not started, true = recording, number = seconds until recording starts

  const { devices } = useMediaDevices()

  const { t } = useI18N()

  const [recordingTimer, setRecordingTimer] = useReducer(
    (state: number | undefined, action: number | undefined) => action,
    undefined
  )
  const chunks = useRef<Blob[]>([])

  const options = useMemo(() => {
    const mimeType = ['video/mp4', 'video/webm;codecs=vp8,opus'].find(mimeType => {
      return MediaRecorder.isTypeSupported(mimeType)
    })
    return {
      mimeType,
    }
  }, [])

  const fileExtension = useMemo(() => {
    return options.mimeType === 'video/mp4' ? '.mp4' : '.webm'
  }, [options.mimeType])

  const cameraDevices = useMemo(() => {
    if (!devices) return []
    return devices.filter(device => device.kind === 'videoinput')
  }, [devices])

  const microphoneDevices = useMemo(() => {
    if (!devices) return []
    return devices.filter(device => device.kind === 'audioinput')
  }, [devices])

  const mediaRecorder = useMemo(() => {
    const mediaRecorder = new MediaRecorder(stream, options)
    mediaRecorder.ondataavailable = (e: BlobEvent) => {
      chunks.current.push(e.data)
      setRecordingTimer(chunks.current.length * 1000)
    }
    return mediaRecorder
  }, [stream, options])

  useEffect(() => {
    setRecordingTimer(undefined)
    setRecordingStatus(false)
  }, [mediaRecorder])

  const mirror = useMemo(() => {
    return stream.getVideoTracks()[0]?.getSettings().facingMode !== 'environment'
  }, [stream])

  const selectedDevices = useMemo(() => {
    const res = new Set<string>()
    stream.getTracks().forEach(track => {
      const settings = track.getSettings()
      if (settings.deviceId) res.add(settings.deviceId)
    })
    return res
  }, [stream])

  const selectedCamera = cameraDevices.find(device => selectedDevices.has(device.deviceId))
  const selectedMicrophone = microphoneDevices.find(device => selectedDevices.has(device.deviceId))

  const [deviceMenu, setDeviceMenu] = useState<{ type: 'camera' | 'microphone'; anchor: HTMLElement }>()

  const videoEl = useMemo(() => {
    return (
      <VideoElement
        fullscreen={fullscreenEnabled}
        playsInline
        autoPlay
        muted
        ref={v => {
          if (v) v.srcObject = stream
        }}
        controls={false}
        mirror={mirror}
      />
    )
  }, [stream, fullscreenEnabled])

  const onCameraSelected = useCallback(
    (deviceId: string) => {
      setConstraints(draft => {
        if (typeof draft.video === 'object') {
          draft.video.deviceId = deviceId
          delete draft.video.facingMode
        } else {
          draft.video = { deviceId }
        }
      })
    },
    [setConstraints]
  )

  const onMicrophoneSelected = useCallback(
    (deviceId: string) => {
      setConstraints(draft => {
        if (typeof draft.audio === 'object') {
          draft.audio.deviceId = deviceId
        } else {
          draft.audio = { deviceId }
        }
      })
    },
    [setConstraints]
  )

  useEffect(() => {
    if (recordingStatus === 'start') {
      const timeout = setTimeout(() => {
        setRecordingStatus(true)
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    } else if (typeof recordingStatus === 'number') {
      const fn =
        recordingStatus === 0
          ? () => {
              mediaRecorder.start(1000)
              setRecordingTimer(0)
              setRecordingStatus('start')
            }
          : () => setRecordingStatus(recordingStatus - 1)
      const timeout = setTimeout(fn, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [mediaRecorder, recordingStatus, setRecordingStatus, setRecordingTimer])

  const recordHint = t('video2.recordHint')
  const recordHintParts = recordHint.split('�')

  return (
    <React.Fragment>
      <VideoToolbar
        onUpload={onUpload}
        onCameraClick={e => {
          setDeviceMenu(deviceMenu?.type === 'camera' ? undefined : { type: 'camera', anchor: e.currentTarget })
        }}
        onMicrophoneClick={e => {
          setDeviceMenu(deviceMenu?.type === 'microphone' ? undefined : { type: 'microphone', anchor: e.currentTarget })
        }}
        timer={recordingTimer}
        selectedCamera={selectedCamera}
        selectedMicrophone={selectedMicrophone}
      />
      {videoEl}
      {recordingStatus === false && cameraDevices.length && deviceMenu?.type === 'camera' && (
        <AnchoredMenu
          anchorEl={deviceMenu.anchor}
          onClickAway={() => {
            setDeviceMenu(undefined)
          }}>
          <DeviceSelector
            devices={cameraDevices}
            selectedDevices={selectedDevices}
            onDeviceSelected={onCameraSelected}
          />
        </AnchoredMenu>
      )}
      {recordingStatus === false && microphoneDevices.length && deviceMenu?.type === 'microphone' && (
        <AnchoredMenu
          anchorEl={deviceMenu.anchor}
          onClickAway={() => {
            setDeviceMenu(undefined)
          }}>
          <DeviceSelector
            devices={microphoneDevices}
            selectedDevices={selectedDevices}
            onDeviceSelected={onMicrophoneSelected}
          />
        </AnchoredMenu>
      )}
      {typeof recordingStatus === 'number' && <RecordingCountDownDiv>{recordingStatus + 1}</RecordingCountDownDiv>}
      {recordingStatus === 'start' && <RecordingCountDownDiv>{t('video2.countdown.start')}</RecordingCountDownDiv>}
      {!fullscreenEnabled && (
        <FullscreenButton
          onClick={e => {
            e.preventDefault()
            setFullscreenEnabled(true)
          }}>
          <div>
            <Icon icon={faExpand} />
          </div>
        </FullscreenButton>
      )}
      {fullscreenEnabled && (
        <FullscreenButton
          onClick={e => {
            e.preventDefault()
            setFullscreenEnabled(false)
          }}>
          <div>
            <Icon icon={faCompress} />
          </div>
        </FullscreenButton>
      )}
      <RecordActionsWrapper>
        <RecordingHint>
          {recordingStatus === false && (
            <>
              {recordHintParts[0]}
              <StartRecordingPreviewButton disabled={true} />
              {recordHintParts[1]}
            </>
          )}
        </RecordingHint>
        {recordingStatus === false ? (
          <StartRecordingButton
            onClick={e => {
              e.preventDefault()
              setRecordingStatus(2) //
              setRecordingTimer(0)
            }}></StartRecordingButton>
        ) : (
          <StopRecordingButton
            onClick={e => {
              e.preventDefault()
              setRecordingTimer(undefined)
              setRecordingStatus(false)
              if (typeof recordingStatus !== 'number') {
                //Stop recording
                mediaRecorder.onstop = () => {
                  onStop(new Blob(chunks.current), fileExtension, options.mimeType)
                }
                mediaRecorder.stream.getTracks().forEach(t => t.stop())
                mediaRecorder.stop()
              }
            }}></StopRecordingButton>
        )}
        <RecordingHint />
      </RecordActionsWrapper>
    </React.Fragment>
  )
}
