import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import Icon from '@trustmary/icons'
import { styled } from 'goober'
import React, { useEffect, useRef } from 'react'

interface DeviceSelectorProps {
  devices: MediaDeviceInfo[]
  selectedDevices: Set<string>
  onDeviceSelected: (deviceId: string) => void
  className?: string
  onClickAway?: () => void
}

const DeviceSelectorDiv = styled(
  'div',
  React.forwardRef
)({
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  overflow: 'hidden',
})

const DeviceButton = styled('button')(({ disabled }) => ({
  width: '100%',
  display: 'block',
  backgroundColor: '#FFFFFF',
  color: '#000000',
  border: 'none',
  height: '30px',
  fontSize: '10px',
  '&:hover': {
    backgroundColor: '#000000',
    color: '#FFFFFF',
  },
}))

export function DeviceSelector({
  devices,
  selectedDevices,
  onDeviceSelected,
  className,
  onClickAway,
}: DeviceSelectorProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const clickAwayListener = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement) {
        let currentElement: HTMLElement | null = e.target
        while (currentElement !== null) {
          if (currentElement === ref.current) {
            return
          }
          currentElement = currentElement.parentElement
        }
        if (onClickAway) onClickAway()
      }
    }
    document.addEventListener('click', clickAwayListener)
    return () => {
      document.removeEventListener('click', clickAwayListener)
    }
  }, [onClickAway])

  return (
    <DeviceSelectorDiv className={className} ref={ref}>
      {devices.map(device => {
        const selected = selectedDevices.has(device.deviceId)
        return (
          <DeviceButton
            key={device.deviceId}
            onClick={e => {
              e.preventDefault()
              onDeviceSelected(device.deviceId)
            }}
            disabled={selected}>
            {device.label} {selected && <Icon icon={faCheck} />}
          </DeviceButton>
        )
      })}
    </DeviceSelectorDiv>
  )
}
