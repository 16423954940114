export class HSLColor {
  //h (hue) 0-360 degrees
  //s (saturation) 0-100 percent
  //l (lightness) 0-100 percent

  constructor(public H: number, public S: number, public L: number) {
    if (!Number.isInteger(H)) {
      throw new Error(`Hue (${H}) is not a valid integer`)
    }
    if (H < 0 || H > 360) {
      throw new Error(`Hue (${H}) out of range`)
    }
    if (!Number.isInteger(S)) {
      throw new Error(`Saturation (${S}) is not a valid integer`)
    }
    if (S < 0 || S > 100) {
      throw new Error(`Saturation (${S}) out of range`)
    }
    if (!Number.isInteger(L)) {
      throw new Error(`Lightness (${L}) is not a valid integer`)
    }
    if (L < 0 || L > 100) {
      throw new Error(`Lightness (${L}) out of range`)
    }
  }

  darken(percent: number, fixedAmount = false): HSLColor {
    if (percent < 0) throw new Error('Use lighten instead of negative percent')
    return fixedAmount
      ? new HSLColor(this.H, this.S, Math.max(this.L - percent, 0))
      : new HSLColor(this.H, this.S, this.L - Math.round(((this.L - 100) * percent) / 100))
  }

  lighten(percent: number, fixedAmount = false): HSLColor {
    if (percent < 0) throw new Error('Use darken instead of negative percent')
    return fixedAmount
      ? new HSLColor(this.H, this.S, Math.min(this.L + percent, 100))
      : new HSLColor(this.H, this.S, this.L + Math.round(((100 - this.L) * percent) / 100))
  }

  toString(): string {
    return `hsl(${this.H}, ${this.S}%, ${this.L}%)`
  }
}

const lightGrey = new HSLColor(210, 10, 96) //#f4f5f6

const grey = new HSLColor(0, 0, 91) //#e7e7e7

const borderGrey = new HSLColor(0, 0, 61) //#9b9b9b

const fontColor = new HSLColor(219, 22, 27).darken(10, true)

export const colors = {
  lightGrey: lightGrey,
  lighterGrey: lightGrey.lighten(1),
  grey: grey,
  borderGrey: borderGrey,
  borderLightGrey: borderGrey.lighten(20, true),
  borderLighterGrey: borderGrey.lighten(26, true),
  inputBorderGrey: borderGrey.lighten(10, true),
  //$green: #95d4d1
  green: '#54d62b',
  green2: '#54d62b',
  yellow: '#ffca05',
  blue: '#0d3b70',
  tmblue: '#0d3b70',
  red: '#db2828',
  orange: '#f9a51a',
  orangeLight: '#fec907',
  concrete: '#7c828a',
  purple: '#ac36ac',

  focus: '#159ce4',

  pageBackground: '#f4f5f7',

  fontColor: fontColor, //'darken(#364155, 10%)',
  fontColorLight: borderGrey.darken(10, true), //'darken(#9b9b9b, 10%)',
  fontColorLighter: fontColor.lighten(20, true),
  fontColorDark: fontColor.darken(10, true),

  smallBreakpoint: '400px',
  mediumBreakpoint: '680px',

  containerWidth: '1100px',

  defaultBoxShadow: '0 2px 4px rgba(0, 0, 0, .05)',

  editorCondition: '#333',

  arrowDown:
    "url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDQ4IDUxMiI+PHBhdGggZD0iTTIwNy4wMjkgMzgxLjQ3NkwxMi42ODYgMTg3LjEzMmMtOS4zNzMtOS4zNzMtOS4zNzMtMjQuNTY5IDAtMzMuOTQxbDIyLjY2Ny0yMi42NjdjOS4zNTctOS4zNTcgMjQuNTIyLTkuMzc1IDMzLjkwMS0uMDRMMjI0IDI4NC41MDVsMTU0Ljc0NS0xNTQuMDIxYzkuMzc5LTkuMzM1IDI0LjU0NC05LjMxNyAzMy45MDEuMDRsMjIuNjY3IDIyLjY2N2M5LjM3MyA5LjM3MyA5LjM3MyAyNC41NjkgMCAzMy45NDFMMjQwLjk3MSAzODEuNDc2Yy05LjM3MyA5LjM3Mi0yNC41NjkgOS4zNzItMzMuOTQyIDB6IiBmaWxsPSIjOWI5YjliIi8+PC9zdmc+Cg==')",

  requiredColor: 'tomato',
}
