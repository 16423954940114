import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { CommentField } from '@tm/shared-lib/src/field'
import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React, { useMemo } from 'react'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyFile } from '../../hooks/useSurveyFile'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { TextArea } from '../field/TextArea'

const CommentText = styled('div')({
  textarea: {
    position: 'relative',
    zIndex: 4,
  },
})

const CommentInputUser = styled('div')({
  display: 'flex',
  position: 'relative',
  zIndex: 3,
  '&:after': {
    content: '" "',
    display: 'block',
    width: '10px',
    height: '10px',
    background: '#dddddd',
    transform: 'rotate(45deg)',
    position: 'absolute',
    bottom: 'calc(100% - 5px)',
    left: '18px',
  },
})

const CommentInputUserImage = styled('img')({
  color: '#fff',
  width: '26px',
  height: '26px',
  borderRadius: '50%',
  marginTop: '10px',
  marginLeft: '10px',
  objectFit: 'cover',
})

const CommentInputUserIcon = styled('div')(({ theme }) => ({
  backgroundColor: theme.values.primaryColor,
  color: '#fff',
  width: '26px',
  height: '26px',
  lineHeight: '26px',
  borderRadius: '50%',
  textAlign: 'center',
  fontSize: '12px',
  marginTop: '10px',
  marginLeft: '10px',
}))

const CommentInputUserInfo = styled('div')({
  lineHeight: '18px',
  marginTop: '14px',
  marginLeft: '10px',
  fontSize: '14px',
  fontWeight: 500,
})

function PersonalInfo(): JSX.Element {
  const info = []

  const [name] = useSurveyValue({ name: 'name', system: true })
  if (name) info.push(name)

  const [organization] = useSurveyValue({ name: 'organization', system: true })
  if (organization) info.push(organization)

  const [position] = useSurveyValue({ name: 'position', system: true })
  if (position) info.push(position)

  const [location] = useSurveyValue({ name: 'location', system: true })
  if (location) info.push(location)

  return <React.Fragment>{info.join(' / ')}</React.Fragment>
}

interface Props {
  field: CommentField
}

export function CommentQuestion({ field }: Props): JSX.Element {
  const { name, showPersonalInfo = false } = field
  const { lang } = useI18N()
  const [value = '', setValue] = useSurveyValue(field)
  const [imageValue] = useSurveyValue({ name: 'imageFilename', system: true })
  const [file] = useSurveyFile<File>({ name: 'imageFilename', system: true })

  const image = useMemo(() => {
    if (typeof imageValue === 'string') {
      return imageValue
    } else if (file) {
      return URL.createObjectURL(file)
    } else {
      return undefined
    }
  }, [imageValue, file])

  return (
    <div className="tm-comment tm-comment--text">
      <CommentText className="tm-comment-text">
        <div className="tm-comment-text-input">
          <TextArea
            value={typeof value === 'string' ? value : ''}
            rows="4"
            name={name}
            onValueChange={setValue}
            className="tm-input tm-textarea"
            lang={lang}
            type="textarea"
          />
          {showPersonalInfo ? (
            <CommentInputUser className="tm-comment-text-input-user">
              {image ? (
                <div className="tm-comment-text-input-user-image">
                  <CommentInputUserImage src={image} />
                </div>
              ) : (
                <CommentInputUserIcon className="tm-comment-text-input-user-icon">
                  <Icon icon={faUser} />
                </CommentInputUserIcon>
              )}
              <CommentInputUserInfo className="tm-comment-text-input-user-info">
                <PersonalInfo />
              </CommentInputUserInfo>
            </CommentInputUser>
          ) : null}
        </div>
      </CommentText>
    </div>
  )
}
