import { useRef, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useVideoContext } from '../VideoContext'

type VideoStreamVisualizerProps = {
  stream: MediaStream
  recordingStatus?: boolean | number | 'start'
}

export function VideoStreamVisualizer(props: VideoStreamVisualizerProps) {
  const { stream, recordingStatus } = props
  const { audioStatus, setAudioStatus } = useVideoContext()

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const theme = useTheme()

  const isRecording = recordingStatus !== false
  const showAudioError = isRecording && audioStatus === 'no-sound'

  /**
   * Create audio context and analyser to read audio from stream
   * and show that we are getting audio
   */

  useEffect(() => {
    setAudioStatus('no-sound') // Reset audio status
    const audioContext = new AudioContext()
    const analyser = audioContext.createAnalyser()

    const barCount = 3
    const barWidth = 4
    const barOffset = 2
    const maxBarHeight = 16
    const minBarHeight = 2

    analyser.fftSize = 64
    const source = audioContext.createMediaStreamSource(stream)
    source.connect(analyser)

    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    let active = true
    let hasSetAudioStatus = false

    const renderCanvas = () => {
      analyser.getByteFrequencyData(dataArray)

      // Check if we have sound from this stream
      const hasSound = dataArray.some(v => v > 0)
      if (hasSound && !hasSetAudioStatus) {
        setAudioStatus('has-sound')
        hasSetAudioStatus = true
      }

      const canvas = canvasRef.current

      const ctx = canvasRef.current?.getContext('2d')
      if (!ctx || !canvas) return

      let x = 0
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      for (let i = 0; i < barCount; i++) {
        const barVolume = dataArray[Math.floor(i * (bufferLength / 2 / barCount))] / 255
        const barHeight = Math.round(barVolume * maxBarHeight < minBarHeight ? minBarHeight : barVolume * maxBarHeight)

        ctx.fillStyle = '#ffffff'
        ctx.fillRect(x, canvas.height / 2 - barHeight / 2, barWidth, barHeight)
        x += barWidth + barOffset
      }

      if (active) requestAnimationFrame(renderCanvas)
    }

    renderCanvas()

    return () => {
      void audioContext.close()
      active = false
    }
  }, [stream])

  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        borderRadius: '50%',
        // bgcolor: 'black',
        position: 'relative',
        border: '2px solid ',
        borderColor: 'black',
        canvas: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0.5,
        },
        ...(showAudioError && {
          borderColor: theme.palette.error.main,
        }),
        ...(audioStatus === 'has-sound' && {
          borderColor: theme.palette.success.dark,
        }),
      }}>
      <canvas ref={canvasRef} width="16" height="16" />
    </Box>
  )
}
