import { useEffect, useCallback } from 'react'
import { useMediaStream } from '@tm/client-form/src/hooks/useMediaStream'
import { VideoLanding } from './VideoLanding'
import { VideoRecord } from './VideoRecord'
import { useVideoContext } from './VideoContext'

export function VideoCollect(): JSX.Element | null {
  const { constraints, setConstraints, onChange, setAspectRatio, setPage, defaultConstraints } = useVideoContext()
  const ms = useMediaStream(constraints)

  useEffect(() => {
    if (ms.error !== undefined) {
      setPage({ type: 'landing', error: ms.error })
      setConstraints(defaultConstraints)
    }

    if (!ms.loading && ms.stream) {
      const aspectRatio = ms.stream.getVideoTracks()[0].getSettings().aspectRatio
      if (aspectRatio) {
        setAspectRatio(aspectRatio)
      }
    }
  }, [ms.loading, ms.error])

  const onStop = useCallback(
    (blob: Blob, fileExtension: string, mimeType?: string) => {
      const file = new File([blob], `recording${fileExtension}`, { type: mimeType })
      onChange(file)
    },
    [onChange]
  )

  if (ms.loading) return <VideoLanding loading />

  if (ms.error !== undefined) return null

  return <VideoRecord stream={ms.stream} onStop={onStop} />
}
