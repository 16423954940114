import { createTheme, ThemeWithColors } from '@trustmary/form-styles'
import React, { useEffect, useMemo } from 'react'
import { SurveyStateContext } from '../../context/surveyStateContext'
import { TranslationsContext } from '../../context/translationsContext'
import { Action } from '../../hooks/useSurvey/actions/action'
import { State } from '../../hooks/useSurvey/states/state'
import { useSurvey } from '../../hooks/useSurvey/useSurvey'
import { useTheme } from '../../hooks/useTheme'
import { Footer } from '../page/Footer'
import { PageInner } from '../page/PageInner'
import PreviewInfo from '../PreviewInfo'
import { Survey } from './Survey'
import { SurveyLoadingIcon } from './SurveyLoadingIcon'
import { SurveyMessage } from './SurveyMessage'
import { SurveyThanks } from './thanks/SurveyThanks'

interface Props {
  surveyId: string
  invitationId?: string
  source?: string
  answerId?: string
  overrideLang?: string
  customParams?: Record<string, string>
  themeDispatch: (theme: ThemeWithColors | undefined) => void
  onResize: () => void
}

interface InnerProps {
  state: State
  dispatch: React.Dispatch<Action>
  resetSurvey: () => void
}

export function SurveyInner({ state, dispatch, resetSurvey }: InnerProps) {
  switch (state.type) {
    case 'error':
      return <SurveyMessage message="Error loading survey" />
    case 'closed':
      return <SurveyMessage message="Survey is closed" />
    case 'not found':
      return <SurveyMessage message="Survey not found" />
    case 'loading get':
      return <SurveyLoadingIcon />
    case 'in progress':
      return state.thanksPage ? (
        <React.Fragment>
          <PageInner className="page-inner">
            <SurveyThanks
              handleSurveyReset={() => {
                resetSurvey()
              }}
              surveyFeatures={state.features}
              meta={state.meta}
              thanks={state.thanksPage.thanks}
              share={state.thanksPage.share}
              answerId={state.answerId}
              values={state.values}
            />
          </PageInner>
          {!state.meta.whitelabel && <Footer />}
        </React.Fragment>
      ) : (
        <Survey state={state} dispatch={dispatch} />
      )
  }
}

export function SurveyLoader(props: Props): JSX.Element {
  const { surveyId, invitationId, source, answerId, overrideLang, customParams, themeDispatch, onResize } = props

  const { view } = useTheme()

  const options = useMemo(
    () => ({ invitationId, source, answerId, overrideLang, customParams }),
    [invitationId, source, answerId, overrideLang, customParams]
  )

  const [state, dispatch, resetSurvey] = useSurvey(surveyId, options)

  useEffect(() => {
    onResize()
  }, [state, onResize])

  const meta = 'meta' in state ? state.meta : undefined
  const theme = useMemo(() => {
    return createTheme({ ...meta?.theme, view })
  }, [meta, view])

  useEffect(() => {
    themeDispatch(theme)
  }, [theme, themeDispatch])

  let content: JSX.Element = <SurveyInner state={state} dispatch={dispatch} resetSurvey={resetSurvey} />
  if ('meta' in state) {
    content = (
      <TranslationsContext.Provider value={{ surveyLanguages: state.meta.languages, lang: state.lang }}>
        {content}
        <PreviewInfo />
      </TranslationsContext.Provider>
    )
  }
  return <SurveyStateContext.Provider value={[state, dispatch]}>{content}</SurveyStateContext.Provider>
}
