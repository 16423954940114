export const thirdPartyCollectSources = [
  {
    name: 'Google',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/google.svg',
  },
  {
    name: 'Facebook',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/facebook.svg',
  },
  {
    name: 'G2',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/g2.svg',
  },
  {
    name: 'Capterra',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/capterra.svg',
  },
  {
    name: 'Tripadvisor',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/tripadvisor.svg',
  },
  {
    name: 'Yelp',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/yelp.svg',
  },
  {
    name: 'Zillow',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/Zillow.svg',
  },
  {
    name: 'Yellow Pages',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/Logo_Yellow_Pages.png',
  },
  {
    name: 'Homestars',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/HomeStars_Logo_Cyan.png',
  },
  {
    name: 'Better Business Bureau',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/better_business_bureau.png',
  },
  {
    name: 'Sourceforge',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/Sourceforge_logo.svg.png',
  },
  {
    name: 'Home advisor',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/HomeAdvisor.webp',
  },
  {
    name: 'Vouchedfor',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/Vouchedfor.png',
  },
  {
    name: 'Getapp.com',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/getapp.png',
  },
  {
    name: 'Softwaresuggest.com',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/softwaresuggest.svg',
  },
  {
    name: 'Softwareadvice.com',
    logo: 'https://d2oanlgiaqo7a1.cloudfront.net/logos/softwareadvice.png',
  },
]
