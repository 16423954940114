import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { CSSAttribute, keyframes } from 'goober'
import React from 'react'

const spinAnimation = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

interface IconProps {
  icon: IconDefinition
  spin?: boolean
}

export function Icon(props: IconProps) {
  const { icon, spin = false } = props
  const [viewBoxWidth, viewBoxHeight] = icon.icon
  const svgPathData = icon.icon[4]

  const styles: CSSAttribute = { width: '1.25em', height: '1em', display: 'inline-block', verticalAlign: '-0.125em' }
  if (spin) styles.animation = `${spinAnimation} 1s infinite`

  return (
    <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} xmlns="http://www.w3.org/2000/svg" style={styles}>
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} fill="currentColor" />
      ) : (
        svgPathData.map((d, index) => {
          const opacity = svgPathData.length > 1 && !index ? 0.4 : 1

          return <path d={d} key={index} fill="currentColor" opacity={opacity} />
        })
      )}
    </svg>
  )
}

export default Icon
