import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import Icon from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../../hooks/useI18n'
import { ActionButton } from './VideoButtons'
import { VideoToolbar } from './VideoToolbar'

const VideoLandingWrapper = styled('div')<{ fullscreen?: boolean }>(({ fullscreen = false }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: '#f7f8f8',
  backgroundColor: '#17221E',
  borderRadius: fullscreen ? 0 : '10px',
  '@media (max-width: 900px)': {
    fontSize: '13px',
  },
}))

const VideoLandingContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: '1 1 auto',
  padding: '0 10%',
  gap: '20px',
  '@media (max-width: 900px)': {
    gap: '15px',
  },
})

const VideoLandingInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  '@media (max-width: 900px)': {
    gap: '7px',
    fontSize: '14px',
    lineHeight: '15px',
  },
})

const InfoText = styled('div')({
  maxWidth: '450px',
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '29px',
  letterSpacing: '-0.035em',
  '@media (max-width: 900px)': {
    gap: '7px',
    fontSize: '14px',
    lineHeight: '15px',
  },
})

const RequestPermissionButton = styled(ActionButton)({
  padding: '16px 25px',
  fontSize: '12px',
  lineHeight: '14px',
  color: 'white',
  border: 'none',
  backgroundColor: '#DE715D',
  '&:hover': {
    backgroundColor: '#BB5C4B',
  },
  svg: {
    animationIterationCount: 'infinite',
  },
  '@media (max-width: 900px)': {
    padding: '11px 13px',
    fontSize: '9px',
    lineHeight: '10px',
  },
})

const WarningCircle = styled('div')({
  color: 'white',
  display: 'inline-block',
  position: 'relative',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  width: '56px',
  height: '56px',
  borderRadius: '999px',
  fontSize: '20px',
  svg: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
  },
  '@media (max-width: 900px)': {
    width: '40px',
    height: '40px',
    fontSize: '14px',
  },
})

const RelativeToolbar = styled(VideoToolbar)({
  position: 'relative',
})

export function VideoLanding({
  onUpload,
  onRecord,
  error,
  loading,
}: {
  onUpload: (file: File) => void
  onRecord: () => void
  error?: string | null
  loading?: boolean
}): JSX.Element {
  const { t } = useI18N()
  return (
    <VideoLandingWrapper fullscreen={false}>
      <RelativeToolbar onUpload={onUpload} />
      <VideoLandingContent>
        <VideoLandingInfo>
          <WarningCircle>
            <Icon icon={faExclamationCircle} />
          </WarningCircle>
          <InfoText>{error ? `${t('error.errorNoCameraAccess')} (${error})` : t('video2.permissionHeader')}</InfoText>
        </VideoLandingInfo>
        <RequestPermissionButton
          onClick={e => {
            e.preventDefault()
            onRecord()
          }}>
          {t('video2.permissionButton')} {loading && <Icon icon={faSpinner} spin={true}></Icon>}
        </RequestPermissionButton>
      </VideoLandingContent>
    </VideoLandingWrapper>
  )
}
