import { InputProps, arrayValue } from './types'
import { CheckboxesField } from '@tm/shared-lib/src/field'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { getTranslatedValue } from './getTranslatedValue'
import { useCallback } from 'react'

export function CheckboxesInput(props: InputProps<CheckboxesField>) {
  const { field, onChange, isSubmitting, lang } = props
  const value = arrayValue(props.value)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updateValue = (event.target as HTMLInputElement).value
      onChange(value.includes(updateValue) ? value.filter(v => v !== updateValue) : [...value, updateValue])
    },
    [onChange, value]
  )

  return (
    <FormControl>
      {field.options?.map(option => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Checkbox />}
          label={getTranslatedValue(option.label, lang)}
          disabled={isSubmitting}
          checked={value.includes(option.value)}
          onChange={handleChange}
        />
      ))}
    </FormControl>
  )
}
