import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import { VideoField } from '@tm/shared-lib/src/field'
import { CampaignSubmit } from '../../../CampaignSubmit'
import { VideoInput } from '../../Default/inputs/VideoInput/VideoInput'
import { UseVideoReview } from '../useVideoReview'
import { SkipButtonState } from '../useSkipButton'
import { SkipButton } from '../SkipButton'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'

type VideoReviewViewProps = UseVideoReview & { demo?: boolean; textReview?: string }

type VideoReviewViewPageProps = VideoReviewViewProps & {
  onVideoSubmit: () => void
  showSubmit: boolean
  skipProps: SkipButtonState
}

export function VideoReviewViewInner(props: VideoReviewViewProps) {
  const { videoError, label, description, demo, textReview, hasVideoReview } = props
  const theme = useTheme()
  const { t } = useI18N()

  if (!hasVideoReview) return null

  const videoField = props.videoField as VideoField

  return (
    <>
      <Stack gap={1}>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body1" whiteSpace="pre-wrap">
          {description}
        </Typography>
      </Stack>
      {!!textReview && (
        <TextField
          variant="filled"
          defaultValue={textReview}
          label={t('video2.textReviewNotesLabel')}
          multiline
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: theme.customShadows.card,
            p: 1,
            '.MuiInputLabel-root': {
              top: 4,
              left: 8,
            },
            '& .MuiInputBase-root.MuiFilledInput-root': {
              bgcolor: 'background.paper',
            },
          }}
        />
      )}
      <VideoInput field={videoField} demo={demo} />
      {!!videoError && <Alert severity="error">{videoError}</Alert>}
    </>
  )
}

export function VideoReviewView(props: VideoReviewViewPageProps) {
  const { onVideoSubmit, showSubmit, skipProps } = props

  return (
    <>
      <VideoReviewViewInner {...props} />
      {!!showSubmit && <CampaignSubmit onClick={onVideoSubmit} />}
      <SkipButton {...skipProps} />
    </>
  )
}
