import { ViewProps } from '../../types'
import { useReviewFields } from '../useReviewFields'
import { useVideoReview } from '../useVideoReview'
import { useSkipButton } from '../useSkipButton'
import { VideoReviewView } from './VideoReviewView'

const defaultText =
  'I recently dined at Kansis and was thoroughly impressed by the exceptional service and delicious food. From the moment we arrived, we were greeted warmly and seated promptly. '

export function VideoReviewLoader(props: ViewProps) {
  const { state, submitForm } = props
  const { reviewFields } = useReviewFields(state.step.fields || [])
  const videoReviewProps = useVideoReview(props, reviewFields)
  const skipProps = useSkipButton(props, reviewFields)

  // Get text review as reference for video review
  const textReview =
    'getValue' in state
      ? state.getValue({
          name: 'comment',
          system: true,
        })
      : undefined

  return (
    <VideoReviewView
      {...videoReviewProps}
      onVideoSubmit={submitForm}
      skipProps={skipProps}
      showSubmit={!!videoReviewProps.videoValue}
      textReview={typeof textReview === 'string' ? textReview : defaultText}
    />
  )
}
