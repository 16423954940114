import { ImageField } from '@tm/shared-lib/src/field'
import React from 'react'
import { useSurveyFile } from '../../hooks/useSurveyFile'
import { FileField } from '../field/FileField'

interface Props {
  field: ImageField
}

function ImageQuestionSingle({ field }: Props): JSX.Element {
  const [file, setFile] = useSurveyFile<File>(field)
  return (
    <FileField
      value={file ? [file] : undefined}
      fileType=".jpg,.jpeg,.png,.gif"
      onValueChange={e => {
        if (e && e.length === 1) setFile(e[0])
        else setFile(undefined)
      }}
    />
  )
}

function ImageQuestionMultiple({ field }: Props): JSX.Element {
  const [file, setFile] = useSurveyFile<File[]>(field)
  return <FileField value={file} fileType=".jpg,.jpeg,.png,.gif" onValueChange={setFile} />
}

export function ImageQuestion({ field }: Props): JSX.Element {
  return field.multiple ? <ImageQuestionMultiple field={field} /> : <ImageQuestionSingle field={field} />
}
