import React from 'react'
import { Updater } from 'use-immer'
import { useMediaStream } from '../../../hooks/useMediaStream'
import { VideoLanding } from './VideoLanding'
import { VideoRecord } from './VideoRecord'

export function VideoCollect({
  constraints,
  setConstraints,
  onChange,
  setAspectRatio,
  onError,
  fullscreenEnabled,
  setFullscreenEnabled,
}: {
  constraints: MediaStreamConstraints
  setConstraints: Updater<MediaStreamConstraints>
  onChange: (file: File) => void
  setAspectRatio: (aspectRatio: number) => void
  onError: (error: string | null) => void
  fullscreenEnabled: boolean
  setFullscreenEnabled: (value: boolean) => void
}): JSX.Element | null {
  const ms = useMediaStream(constraints)
  if (ms.loading) {
    return (
      <VideoLanding
        onUpload={onChange}
        onRecord={() => {
          //Do nothing
        }}
        loading={true}
      />
    )
  } else if (ms.error !== undefined) {
    onError(ms.error)
    return null
  } else {
    const onStop = (blob: Blob, fileExtension: string, mimeType?: string) => {
      const file = new File([blob], `recording${fileExtension}`, { type: mimeType })
      onChange(file)
    }
    const aspectRatio = ms.stream.getVideoTracks()[0].getSettings().aspectRatio
    if (aspectRatio) {
      setAspectRatio(aspectRatio)
    }
    return (
      <VideoRecord
        stream={ms.stream}
        onStop={onStop}
        onUpload={onChange}
        setConstraints={setConstraints}
        fullscreenEnabled={fullscreenEnabled}
        setFullscreenEnabled={setFullscreenEnabled}
      />
    )
  }
}
