import { ReactNode } from 'react'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'

type CampaignGroupProps = {
  children: ReactNode
  plain?: boolean
}

export function CampaignGroup(props: CampaignGroupProps) {
  const theme = useTheme()
  const sx: SxProps | undefined = !props.plain
    ? {
        [theme.breakpoints.up('sm')]: {
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: theme.customShadows.card,
          p: 3,
        },
      }
    : undefined

  return (
    <Stack gap={2} sx={sx}>
      {props.children}
    </Stack>
  )
}
