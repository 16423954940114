import { BaseState, State } from './state'

export interface ClosedState extends BaseState {
  type: 'closed'
}

export const surveyClosedState: ClosedState = {
  type: 'closed',
  processAction: (): State => {
    return surveyClosedState
  },
}
