import { styled } from 'goober'
import React from 'react'

export const ActionButton = styled('button')({
  borderRadius: '999px',
  textTransform: 'uppercase',
  textAlign: 'center',
  letterSpacing: '-0.025em',
  fontWeight: 600,
})

export const CircleButton = styled<'button'>('button')({
  borderRadius: `50%`,
  border: 'none',
  position: 'relative',
  padding: 0,
  div: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
  },
})

export const StartRecordingOuterCircleButton = styled(CircleButton)({
  width: '70px',
  height: '70px',
  flex: '0 0 auto',
  backgroundColor: '#DE715D',
  '&:disabled': {
    cursor: 'default',
  },
  '&:hover:enabled': {
    backgroundColor: '#BB5C4B',
  },
  div: {
    width: '25%',
    height: '25%',
  },
  '@media (max-width: 900px)': {
    width: '40px',
    height: '40px',
  },
})

//Set borderRadious to diameter/2 to create a circle
export const RoundedSquareDiv = styled<'div', { backgroundColor: string; borderRadius?: string }>('div')(props => {
  const { borderRadius = 0, backgroundColor } = props
  return {
    borderRadius,
    border: 'none',
    backgroundColor,
  }
})

export function StartRecordingButton(
  props: React.ClassAttributes<HTMLButtonElement> & React.ButtonHTMLAttributes<HTMLButtonElement>
): JSX.Element {
  return (
    <StartRecordingOuterCircleButton {...props}>
      <RoundedSquareDiv borderRadius="50%" backgroundColor="white" />
    </StartRecordingOuterCircleButton>
  )
}

export const StartRecordingPreviewButton = styled(StartRecordingButton)({
  width: '17.32px',
  height: '17.32px',
  verticalAlign: 'middle',
  '@media (max-width: 900px)': {
    width: '12px',
    height: '12px',
  },
})
