import { InputProps, booleanValue } from './types'
import { CheckboxField } from '@tm/shared-lib/src/field'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { getTranslatedValue } from './getTranslatedValue'
import { useI18N, TranslateFunction } from '@tm/client-form/src/hooks/useI18n'
import { useCallback } from 'react'

const getCheckboxLabel = (field: CheckboxField, lang: string, t: TranslateFunction) => {
  // If inputLabelKey is defined, use it to get the label
  if (field.inputLabelKey) return t(field.inputLabelKey)

  return getTranslatedValue(field.inputLabel, lang)
}

export function CheckboxInput(props: InputProps<CheckboxField>) {
  const { field, onChange, isSubmitting, lang } = props
  const { t } = useI18N()
  const value = booleanValue(props.value)
  const label = getCheckboxLabel(field, lang, t)

  const handleChange = useCallback(() => {
    onChange(!value)
  }, [onChange, value])

  return (
    <FormControl>
      <FormControlLabel
        control={<Checkbox />}
        label={label}
        disabled={isSubmitting}
        checked={value}
        onChange={handleChange}
      />
    </FormControl>
  )
}
