import { CheckboxesField } from '@tm/shared-lib/src/field'
import React from 'react'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Checkbox } from '../field/Checkbox'

interface Props {
  field: CheckboxesField
}

export function CheckboxesQuestion({ field }: Props): JSX.Element {
  const [value = [], setValue] = useSurveyValue(field)
  if (!Array.isArray(value)) {
    throw new Error('Checkboxes does not support value: ' + value)
  }
  const { options = [] } = field
  return (
    <div className="tm-input tm-input--checkboxes">
      {options.map(({ value: optionValue, label = {} }) => {
        const optionIndex = value.indexOf(optionValue)
        const isChecked = optionIndex >= 0

        return (
          <Checkbox
            key={optionValue}
            inputLabel={label}
            value={isChecked}
            onValueChange={(v: boolean) => {
              const newValue: string[] = v
                ? [...value, optionValue]
                : [...value.slice(0, optionIndex), ...value.slice(optionIndex + 1)]
              setValue(newValue)
            }}
          />
        )
      })}
    </div>
  )
}
