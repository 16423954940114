import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyState } from '../../hooks/useSurveyState'

export function CampaignFooter() {
  const { t } = useI18N()
  const [state] = useSurveyState()
  if (!('meta' in state)) return null

  const surveyType = state.meta.surveyType || 'feedback'

  return (
    <Stack
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        p: {
          xs: 2,
          sm: 0,
        },
        mb: 2,
      }}>
      <Box
        sx={{
          bgcolor: '#ffffff',
          borderRadius: 1,
          px: 1.5,
          py: 1,
          boxShadow: theme => theme.customShadows.z4,
        }}>
        <Typography
          variant="body2"
          sx={{
            color: 'text.primary',
            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
sans-serif`,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            a: {
              color: 'text.primary',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          }}>
          <a href={t(`footer.poweredByLinkUrl.${surveyType}`)} target="_blank" rel="noreferrer">
            {t(`footer.poweredByLinkText.${surveyType}`)}
          </a>
          {' by '}
          <Typography
            component="span"
            sx={{
              display: 'inline-block',
              textTransform: 'lowercase',
              fontWeight: '700',
              backgroundImage: `url('https://d2nce6johdc51d.cloudfront.net/source/trustmary.svg')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0 50%',
              backgroundSize: '10px 18px',
              paddingLeft: '13px',
              color: '#0d3b70',
              marginLeft: '2px',
            }}>
            Trustmary
          </Typography>
        </Typography>
      </Box>
    </Stack>
  )
}
