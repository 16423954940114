import Button from '@mui/material/Button'
import { useCampaign } from '../../hooks/useCampaign'
import { useI18N } from '../../hooks/useI18n'

type CampaignSubmitProps = {
  onClick: () => void
  text?: string
  disabled?: boolean
}

export function CampaignSubmit(props: CampaignSubmitProps) {
  const { onClick, text, disabled } = props
  const { buttonSx } = useCampaign()
  const { t } = useI18N()

  return (
    <Button onClick={onClick} disabled={disabled} size="large" variant="contained" sx={buttonSx}>
      {text || t('form.defaultSubmit')}
    </Button>
  )
}
