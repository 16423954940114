import Typography from '@mui/material/Typography'
import { CustomValue } from '@tm/shared-lib/src/api'
import { CampaignSubmit } from '../../CampaignSubmit'
import { NpsInputInner } from '../Default/inputs/NpsInput'
import { CampaignGroup } from '@tm/client-form/src/components/campaign/CampaignGroup'

type NpsQuestionViewProps = {
  name?: string
  value?: number
  label?: string
  onChange?: (value: CustomValue) => void
  description?: string
  disabled?: boolean
  showSubmit?: boolean
  onSubmit?: () => void
}

export function NpsQuestionView(props: NpsQuestionViewProps) {
  const { value, label, onChange, description, disabled, showSubmit, onSubmit } = props

  return (
    <>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        {label}
      </Typography>
      <CampaignGroup>
        <NpsInputInner value={value} onChange={onChange} disabled={disabled} />
      </CampaignGroup>
      {!!description && (
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {description}
        </Typography>
      )}
      {!!showSubmit && (
        <CampaignSubmit
          onClick={() => {
            onSubmit && onSubmit()
          }}
        />
      )}
    </>
  )
}
