import { DragEvent, useCallback, useMemo, useState } from 'react'
import useI18N from '@tm/client-form/src/hooks/useI18n'
import { InputProps } from '../types'

type FileFieldProps = {
  onChange: InputProps<null>['onChange']
  setFile: (value: File | undefined) => void
  fileTypes: string
}

const getExtension = (name: string) => {
  if (typeof name !== 'string') return null

  const nameParts = name.split('.')
  return nameParts[nameParts.length - 1]
}

export function useFileField(props: FileFieldProps) {
  const { onChange, setFile, fileTypes } = props
  const { t } = useI18N()

  const maxSize = 10485760
  const [error, setError] = useState('')
  const [dragOver, setDragOver] = useState(false)

  const acceptedFiletypes = useMemo(
    () => (fileTypes === '*' ? [] : fileTypes.split(',').map(fileType => fileType.trim().replace('.', ''))),
    [fileTypes]
  )

  /**
   * Check if a file is valid to be uploaded:
   * - Filetype is allowed
   * - File's size is less than the maximum size defined
   *
   * @param file File to be validated
   * @returns Boolean on is the file valid or not
   */
  const isFileValid = useCallback(
    (file: File) => {
      const { size, name } = file
      const fileExtension = getExtension(name) || ''
      let fileError: string | null = null
      if (size > maxSize) {
        fileError = `${name} ${t('file.errorMaxSize')}`
      } else if (acceptedFiletypes.length && acceptedFiletypes.indexOf(fileExtension.toLowerCase()) === -1) {
        fileError = `${name} ${t('file.errorWrongType')}`
      }
      if (fileError?.length) {
        setError(fileError)
        return false
      }
      return true
    },
    [acceptedFiletypes, maxSize, t]
  )

  /**
   * Uploads a file and sets the uploaded file's URL as the
   * value of the field.
   *
   * @param event Change event from a file input
   */
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : []
    void handleSelectedFiles(files)
  }

  const handleSelectedFiles = useCallback(
    (files: File[]) => {
      const validFiles = files.filter(file => isFileValid(file))
      if (validFiles.length) setFile(validFiles[0])
    },
    [isFileValid, setFile]
  )

  const onDragOver = (e: DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setDragOver(true)
  }

  const onDragLeave = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(false)
  }

  const onDrop = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(false)
    if (e.dataTransfer.files.length) {
      void handleSelectedFiles(Array.from(e.dataTransfer.files))
    }
  }

  const clearFieldValue = () => {
    setFile(undefined)
    onChange('')
  }

  return {
    error,
    dragOver,
    onFileChange,
    onDragOver,
    onDragLeave,
    onDrop,
    clearFieldValue,
    maxSizeText: (maxSize / 1024 / 1024).toString(),
  }
}
