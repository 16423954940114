import { tmary } from '@tm/client-embed/src/common/window'
import { StepValues, StepValuesIndex } from '@tm/shared-lib/src/api'
import { Field } from '@tm/shared-lib/src/field'
import { ValidationErrors, validateFieldsValues } from '@tm/shared-lib/src/validation'
import React, { useCallback, useEffect, useState } from 'react'
import { Action } from '../../hooks/useSurvey/actions/action'
import { InProgressState } from '../../hooks/useSurvey/states/inProgress'
import { SubmitHandler, useSurveySubmit } from '../../hooks/useSurveySubmit'
import { useSurveyUpload } from '../../hooks/useSurveyUpload'
import { StepFiles } from '../../types/stepFiles'

export type SubmitStatus = 'none' | 'uploading' | 'uploaded' | 'submitting'

const wait = () => new Promise(fulfill => setTimeout(fulfill, 500))

interface BaseProps {
  fields: Field[]
  state: InProgressState
  dispatch: React.Dispatch<Action>
}

export interface PassProps extends BaseProps {
  validationErrors: ValidationErrors
  submitStatus: SubmitStatus
  validateForm: (validateFields?: Field[]) => boolean
  submitForm: () => void
}

interface Props extends BaseProps {
  children: (props: PassProps) => JSX.Element
  submitOverride?: SubmitHandler
  submitDatalayerContext?: Record<string, string>
  conversionId?: string
}

export function SurveyForm({
  fields,
  state,
  dispatch,
  children,
  submitOverride,
  submitDatalayerContext,
  conversionId,
}: Props): JSX.Element {
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('none')
  const [previewValues, setPreviewValues] = useState<StepValues>()

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})

  const preview = window.location.search.includes('preview=1')

  const getFilesToSubmit = useCallback(() => {
    const res: StepFiles = {}
    const processField = (field: Field) => {
      if (field.type === 'content') return
      if (field.type === 'fieldset') {
        for (const fieldsetField of field.fields || []) {
          processField(fieldsetField)
        }
      } else {
        if (field.system) {
          if (field.name in state.files) res[field.name] = state.files[field.name]
        } else {
          if (state.files.custom && field.name in state.files.custom) {
            if (!res.custom) res.custom = {}
            res.custom[field.name] = state.files.custom[field.name]
          }
        }
      }
    }
    fields.forEach(field => processField(field))
    return res
  }, [state.files, fields])

  const getValuesToSubmit = useCallback(() => {
    const res: StepValues = {}
    const processField = (field: Field) => {
      if (field.type === 'content') return
      if (field.type === 'fieldset') {
        if (field.fields) field.fields.forEach(fieldsetField => processField(fieldsetField))
      } else {
        if (field.system && state.values[field.name] !== undefined) {
          res[field.name] = state.values[field.name]
        } else if (state.values.custom?.[field.name] !== undefined) {
          if (!res.custom) res.custom = {}
          res.custom[field.name] = state.values.custom?.[field.name]
        }
      }
    }
    fields.forEach(field => processField(field))
    return res
  }, [state.values, fields])

  const onFieldUploaded = useCallback(
    (field: { name: string; system: boolean }, fileName: string | string[]) => {
      dispatch({ type: 'field uploaded', field, fileName })
    },
    [dispatch]
  )

  const { uploadFields } = useSurveyUpload(onFieldUploaded)

  const submitSurvey = useSurveySubmit({
    stepId: state.step.id,
    eventId: state.eventId,
    answerId: state.answerId,
    surveyId: state.surveyId,
    invitationId: state.invitationId,
    source: state.source,
    customParams: state.customParams,
    submitOverride,
    submitDatalayerContext,
    hiddenFields: { ...tmary('getHiddenFields') },
    conversionId,
    preview,
    previewValues,
  })

  // After step values are submitted, we want to save them to previewValues so that they can be used in preview mode
  const savePreviewValues = useCallback(
    (nextValues: StepValues) => {
      if (!preview) return

      setPreviewValues({
        ...previewValues,
        ...nextValues,
        custom: {
          ...previewValues?.custom,
          ...nextValues.custom,
        },
      })
    },
    [setPreviewValues, previewValues, preview]
  )

  useEffect(() => {
    if (submitStatus === 'uploaded') {
      setSubmitStatus('submitting')
      dispatch({ type: 'submit error', error: undefined })
      const valuesToSubmit = getValuesToSubmit()
      wait()
        .then(() => submitSurvey(valuesToSubmit))
        .then(
          res => {
            setSubmitStatus('none')
            dispatch({ type: 'post response', data: res })
            savePreviewValues(valuesToSubmit)
          },
          () => {
            dispatch({ type: 'submit error', error: 'submit failed' })
            setSubmitStatus('none')
          }
        )
        .finally(() => {
          // In survey pages, we want to reset scroll after page submit
          if (process.env.RESET_SCROLL === '1') window.scrollTo(0, 0)
        })
    }
  }, [submitStatus, getValuesToSubmit, setSubmitStatus, dispatch, submitSurvey, conversionId, setPreviewValues])

  const validateFormValues = useCallback(
    (valuesToSubmit: StepValues, filesToSubmit: StepFiles, validateFields?: Field[]) => {
      const validate: StepValuesIndex = { ...valuesToSubmit, custom: { ...valuesToSubmit.custom } }
      for (const key in filesToSubmit) {
        if (key === 'custom') {
          const customFiles = filesToSubmit.custom
          if (!validate.custom) validate.custom = {}
          for (const key2 in customFiles) {
            const f = customFiles[key2]
            if (Array.isArray(f)) {
              validate.custom[key2] = f.map(ff => ff.name)
            } else {
              validate.custom[key2] = f.name
            }
          }
        } else {
          const f = filesToSubmit[key]
          if (Array.isArray(f)) {
            validate[key] = f.map(ff => ff.name)
          } else {
            validate[key] = f.name
          }
        }
      }
      const errors = validateFieldsValues(Array.isArray(validateFields) ? validateFields : fields, validate)
      setValidationErrors(errors)
      return Object.keys(errors).length === 0
    },
    [fields]
  )

  const validateForm = useCallback(
    (validateFields: Field[]) => {
      const valuesToSubmit = getValuesToSubmit()
      const filesToSubmit = getFilesToSubmit()
      return validateFormValues(valuesToSubmit, filesToSubmit, validateFields)
    },
    [getValuesToSubmit, getFilesToSubmit, validateFormValues]
  )

  const submitForm = useCallback(() => {
    dispatch({ type: 'submit error', error: undefined })
    const valuesToSubmit = getValuesToSubmit()
    const filesToSubmit = getFilesToSubmit()
    if (!validateFormValues(valuesToSubmit, filesToSubmit)) return

    if (Object.keys(filesToSubmit).length > 0) {
      setSubmitStatus('uploading')
      uploadFields(filesToSubmit).then(
        () => {
          setSubmitStatus('uploaded')
        },
        () => {
          dispatch({ type: 'submit error', error: 'upload failed' })
          setSubmitStatus('none')
        }
      )
    } else {
      setSubmitStatus('uploaded')
    }
  }, [getValuesToSubmit, getFilesToSubmit, validateFormValues, uploadFields, dispatch])

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault()
      submitForm()
    },
    [submitForm]
  )

  const passProps: PassProps = {
    fields,
    state,
    dispatch,
    validationErrors,
    submitStatus,
    validateForm,
    submitForm,
  }

  return <form onSubmit={handleSubmit}>{children(passProps)}</form>
}
