import { styled } from 'goober'
import React from 'react'
import useI18n from '../hooks/useI18n'
import '../misc/styled'

const EyeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={14} fill="none">
    <path
      fill="#1D3A6C"
      d="M10 0C7.469 0 5.437 1.156 3.969 2.531 2.5 3.875 1.53 5.5 1.063 6.625a1.12 1.12 0 0 0 0 .781C1.53 8.5 2.5 10.125 3.969 11.5 5.438 12.875 7.469 14 10 14c2.5 0 4.531-1.125 6-2.5 1.469-1.375 2.438-3 2.906-4.094a1.12 1.12 0 0 0 0-.781C18.438 5.5 17.47 3.875 16 2.531 14.531 1.156 12.5 0 10 0ZM5.5 7c0-1.594.844-3.063 2.25-3.875a4.458 4.458 0 0 1 4.5 0C13.625 3.938 14.5 5.406 14.5 7a4.54 4.54 0 0 1-2.25 3.906 4.458 4.458 0 0 1-4.5 0A4.475 4.475 0 0 1 5.5 7ZM10 5c0 1.125-.906 2-2 2a2.22 2.22 0 0 1-.656-.094c-.157-.062-.375.063-.344.25 0 .188.031.407.094.625a2.975 2.975 0 0 0 3.656 2.125c1.625-.437 2.563-2.062 2.125-3.656A3.019 3.019 0 0 0 10.125 4c-.188 0-.313.219-.25.375.063.219.125.406.125.625Z"
    />
  </svg>
)

const Tag = styled('div')({
  position: 'fixed',
  top: '16px',
  left: '16px',
  padding: '4px 8px',
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  borderRadius: '6px',
  background: '#dfe2e9',
  span: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#212936',
  },
})

export function PreviewInfo() {
  const isPreview = window.location.search.includes('preview=1')
  const { t } = useI18n()
  if (!isPreview) return null

  return (
    <Tag>
      <EyeIcon />
      <span>{t('previewInfo', 'This is a preview')}</span>
    </Tag>
  )
}

export default PreviewInfo
