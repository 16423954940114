import { useCallback, useEffect, useMemo } from 'react'

type FrameView = 'full' | 'compact'
const getFrameView = (view: unknown): FrameView => {
  if (view === 'full') return 'full'
  if (view === 'compact') return 'compact'
  return 'compact'
}

export function useCampaignFrame() {
  const search = useMemo(() => new URLSearchParams(location.search), [])
  const frameId = search.get('frameId')
  const frameView = search.get('frameView')

  const onResize = useCallback(() => {
    try {
      const message = {
        frameId,
        type: 'resize',
        attr: {
          height: document.getElementById('app')?.offsetHeight,
        },
      }
      parent.postMessage(message, '*')
    } catch (err) {
      console.log(err)
    }
  }, [frameId])

  useEffect(() => {
    if (!frameId) return
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [frameId, onResize])

  return useMemo(
    () => ({
      inFrame: !!frameId,
      frameId,
      frameView: getFrameView(frameView),
      onResize,
    }),
    [frameId, frameView, onResize]
  )
}

export type CampaignFrameState = ReturnType<typeof useCampaignFrame>
