import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faVideoCamera } from '@fortawesome/free-solid-svg-icons/faVideoCamera'
import Icon from '@trustmary/icons'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { VideoContainer } from './VideoContainer'
import { VideoActionBar } from './VideoActionBar/VideoActionBar'
import { useEffect } from 'react'
import { useVideoContext } from './VideoContext'

export function VideoLanding({
  onRecord,
  error,
  loading,
}: {
  onRecord?: () => void
  error?: string | null
  loading?: boolean
}): JSX.Element {
  const { t } = useI18N()
  const { askedPermission, setAskedPermission } = useVideoContext()

  useEffect(() => {
    if (askedPermission) return
    if (!onRecord) return
    setAskedPermission(true)
    onRecord()
  }, [])

  return (
    <>
      <VideoContainer>
        <Stack
          sx={{
            bgcolor: 'grey.800',
            height: '100%',
            borderRadius: {
              xs: 0,
              sm: 1,
            },
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              textAlign: 'center',
              maxWidth: 400,
              borderRadius: 1,
              color: 'grey.300',
              p: 3,
            }}>
            <Typography>
              {error ? `${t('error.errorNoCameraAccess')} (${error})` : t('video2.permissionHeader')}
            </Typography>
            <Button
              onClick={() => onRecord && onRecord()}
              startIcon={<Icon icon={loading ? faSpinner : faVideoCamera} spin={loading} />}
              variant="outlined"
              color="inherit"
              sx={{
                color: 'grey.200',
                borderColor: 'grey.200',
                ':hover': {
                  borderColor: 'grey.100',
                },
              }}>
              {t('video2.permissionButton')}
            </Button>
          </Stack>
        </Stack>
      </VideoContainer>
      <VideoActionBar />
    </>
  )
}
