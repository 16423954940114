import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faPenNib } from '@fortawesome/free-solid-svg-icons/faPenNib'
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import { thirdPartyCollectSources } from '@tm/client-app/src/survey/editor-v3/Canvas/Thanks/ThirdPartyCollect/sources'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import { Translation } from '@tm/shared-lib/src/i18n'
import { FontAwesomeSvgIcon as Icon } from '@tm/ui/composite/FontAwesomeSvgIcon'
import { useCampaign } from '../../../../hooks/useCampaign'
import { UsePlatformFields } from './usePlatformFields'
import { usePlatformRedirect } from './usePlatformRedirect'
import { ReviewFields } from './useReviewFields'
import { useCallback } from 'react'

const sourceLogos = new Map(thirdPartyCollectSources.map(({ name, logo }) => [name, logo]))
sourceLogos.set('Trustmary', 'https://d2nce6johdc51d.cloudfront.net/source/trustmary.svg')

type PlatformViewProps = Partial<UsePlatformFields> & {
  reviewFields?: ReviewFields
  noRedirect?: boolean
}

type PlatformButtonProps = {
  text: string
  url?: string
  onClick?: () => void
  iconSrc?: string
  faIcon?: IconDefinition
}

function PlatformButton({ text, url, onClick, iconSrc, faIcon }: PlatformButtonProps) {
  const { primaryColor } = useCampaign()

  const buttonSx: SxProps<Theme> = {
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: theme => theme.customShadows.card,
    color: 'text.primary',
    px: 2,
    py: 3,
    flexBasis: {
      xs: '100%',
      md: 'calc(50% - 8px)',
    },
  }

  const buttonInner = (
    <Stack gap={2} sx={{ alignItems: 'center' }}>
      {!!iconSrc && (
        <Box
          sx={{
            display: 'block',
            width: 32,
            height: 32,
          }}
          component="img"
          src={iconSrc}
          alt={text}
        />
      )}
      {!!faIcon && <Icon icon={faIcon} sx={{ fontSize: 24, color: primaryColor }} />}
      <Typography variant="button">{text}</Typography>
    </Stack>
  )

  if (url)
    return (
      <Button component="a" href={url} target="_blank" rel="noreferrer" sx={buttonSx}>
        {buttonInner}
      </Button>
    )

  return (
    <Button sx={buttonSx} onClick={onClick}>
      {buttonInner}
    </Button>
  )
}

const getTranslation = (translations: Translation<string> | undefined, lang: string) => {
  if (!translations) return ''
  return translations[lang] || translations['_d'] || ''
}

export function PlatformView({
  sources,
  lang,
  sourcesLabel,
  noRedirect,
  hasTextReview,
  hasVideoReview,
  setReviewState,
}: PlatformViewProps) {
  const { isRedirecting, redirectUrl } = usePlatformRedirect(sources, noRedirect)
  const { buttonSx } = useCampaign()
  const { t } = useI18N()

  const openReviewView = useCallback(() => {
    if (setReviewState) setReviewState('contact info')
  }, [setReviewState])

  if (!sources) return null
  if (isRedirecting) {
    const [source] = sources
    return (
      <Stack gap={2} alignItems="center" py={5}>
        {source.source && <Box width={48} height={48} component="img" src={sourceLogos.get(source.source)} />}
        <Typography>{t('platform.redirecting')}</Typography>
        {!!redirectUrl && (
          <Button
            component="a"
            href={redirectUrl}
            variant="contained"
            color="primary"
            target="_blank"
            rel="noreferrer,noopener"
            endIcon={<Icon icon={faExternalLinkAlt} />}
            sx={buttonSx}>
            {t('platform.openLink')}
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <>
      {!!sourcesLabel && (
        <Typography variant="body1" sx={{ textAlign: 'center' }} whiteSpace="pre-wrap">
          {sourcesLabel}
        </Typography>
      )}
      <Stack direction="row" gap={2} flexWrap="wrap" justifyContent="center">
        {hasTextReview && (
          <PlatformButton text={t('platform.leaveTextReview')} onClick={openReviewView} faIcon={faPenNib} />
        )}
        {hasVideoReview && !hasTextReview && (
          <PlatformButton text={t('platform.leaveVideoReview')} onClick={openReviewView} faIcon={faVideo} />
        )}
        {sources
          .filter(item => item.id !== 'trustmary')
          .map(source => (
            <PlatformButton
              key={source.id}
              text={getTranslation(source.name, lang || 'en')}
              url={source.url}
              iconSrc={source.source ? sourceLogos.get(source.source) : ''}
            />
          ))}
      </Stack>
    </>
  )
}
