import { useCallback, useMemo } from 'react'
import { useCampaign } from '../../../../hooks/useCampaign'
import { useI18N } from '../../../../hooks/useI18n'
import { ViewProps } from '../types'
import { UsePictureField } from './types'
import { ContactInfoFields } from './useReviewFields'

export function useLogoPictureField(
  { state, dispatch }: Pick<ViewProps, 'state' | 'dispatch'>,
  fields: ContactInfoFields
): UsePictureField {
  const { t } = useI18N()
  const { primaryColor, primaryTextColor } = useCampaign()
  const logoPictureField = useMemo(() => fields.get('companyLogo'), [fields])

  const onLogoPictureChange = useCallback(
    (files: FileList | null) => {
      const [file] = files || []
      dispatch({ type: 'set file', field: { name: 'companyLogo', system: true }, file })
    },
    [dispatch]
  )

  const value = useMemo(
    () =>
      state.getFile({
        name: 'companyLogo',
        system: true,
      }),
    [state]
  )

  const labelKey =
    logoPictureField && 'labelKey' in logoPictureField && logoPictureField.labelKey
      ? logoPictureField.labelKey
      : 'review.companyLogo'
  const label =
    logoPictureField && 'label' in logoPictureField && logoPictureField.label ? logoPictureField.label : t(labelKey)

  return {
    value,
    onChange: onLogoPictureChange,
    label,
    primaryColor,
    primaryTextColor,
    chooseImageButtonText: t('file.chooseImage'),
    imagePlaceholder:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFQSURBVHgB7Zc9S8NQFIbfitJBdHTwYyiCCqYZogVDQaoOddLNf+fmL+jkKA5maHRpCzpIhoKDioMieOugnoaIRYW897bQ4TxLhtzDee57Ljek8GZ6HxhDJjCmqBiLirGoGIuKsagYyySzuHOd4OziEsa8M2WYnZlGuFnG+lopdw2VWBS3aCnh+eX1a0NXVA2VmDQQworXTyCK24iarVy1xvTAYHXGRCp9ehgVVmJZSlGzjVFBjTKjP8L4t9TS/BwO9rfROD1H9+4eLgztuigWp1Df3Rp4ujA0MTl3ci0I6fXgwwWrUWbNjw730LlJ8PD4hMBfHXgf+Cu4TbrWI7VOTKTSZDzUd8I/17iM1EqsVg2+xyb819xlpFZiy6VFYu0CbKDOmCQjn6TjkwZYfiacByqxWnWDbiDIhsJKmaop6J84iYqxqBiLirGoGIuKsXwCh4pUdFiBXQUAAAAASUVORK5CYII=',
  }
}
