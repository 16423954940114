import { useMemo } from 'react'
import { useSurveyState } from './useSurveyState'

export function useSurveyFile<E extends File | File[] = File>(field: {
  name: string
  system?: boolean
}): [E | undefined, (v: E | undefined) => void] {
  const [state, dispatch] = useSurveyState()
  const file = 'getFile' in state ? state.getFile(field) : undefined

  return useMemo(() => {
    const setFile = (newFile: E | undefined) => dispatch({ type: 'set file', field, file: newFile })
    return [file as E | undefined, setFile]
  }, [file, dispatch, field])
}
