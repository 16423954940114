import { SkipButtonState } from './useSkipButton'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'

export type SkipButtonProps = SkipButtonState

export function SkipButton(props: SkipButtonProps) {
  const { skipField, handleSkipClick } = props
  const { t } = useI18N()
  const theme = useTheme()

  if (!skipField) return null

  const buttonText = skipField.name === 'noPublicConsent' ? t('review.noPublicConsent') : t('review.noVideoRecord')

  return (
    <Stack justifyContent="center" alignItems="center">
      <Button
        variant="text"
        onClick={handleSkipClick}
        sx={{
          ...theme.typography.body2,
          textDecoration: 'underline',
          color: 'text.secondary',
        }}>
        {buttonText}
      </Button>
    </Stack>
  )
}
