import { ViewProps } from '../types'
import Stack from '@mui/material/Stack'
import { RenderField } from './RenderField'
import { CampaignSubmit } from '../../CampaignSubmit'

export function DefaultView(props: ViewProps) {
  const { fields, submitForm, isSubmitting } = props

  return (
    <Stack gap={3}>
      {fields.map((field, index) => (
        <RenderField key={`${index}-${field.name}`} field={field} {...props} />
      ))}
      <CampaignSubmit onClick={submitForm} disabled={isSubmitting} />
    </Stack>
  )
}
