import { ThemeWithColors } from '@trustmary/form-styles'
import { styled } from 'goober'
import React, { useMemo } from 'react'
import { SurveyLoader } from '../survey/SurveyLoader'

const Container = styled('div')({
  maxWidth: '960px',
  margin: '0 auto',
  height: '100%',
})

export function Page({
  themeDispatch,
  onResize,
}: {
  themeDispatch: (theme: ThemeWithColors | undefined) => void
  onResize: () => void
}): JSX.Element {
  const pathVariables = window.location.pathname.split('/').filter(Boolean)
  const search = window.location.search
  const queryParams = useMemo(
    () =>
      search
        .substr(1)
        .split('&')
        .reduce<{ [key: string]: string | undefined }>((mapped, str) => {
          const [key, value] = str.split('=')
          if (key) mapped[decodeURIComponent(key)] = decodeURIComponent(value)
          return mapped
        }, {}),
    [search]
  )

  const regex = '^[p][0-9]+$'
  const customParams = useMemo(
    () =>
      Object.keys(queryParams).reduce((params, key) => {
        if (RegExp(regex).exec(key)) params[key] = queryParams[key]

        return params
      }, {}),
    [queryParams]
  )
  if (pathVariables.length < 1) return <div>Not found</div>
  const surveyId = pathVariables[pathVariables.length - 1]

  let answerId: string | undefined
  try {
    answerId = localStorage.getItem(`${surveyId}_answerId`) || undefined
  } catch {
    answerId = undefined
  }

  return (
    <Container className="page-container">
      <SurveyLoader
        surveyId={surveyId}
        invitationId={queryParams['i']}
        overrideLang={queryParams['lang']}
        source={queryParams['source']}
        customParams={customParams}
        answerId={answerId}
        themeDispatch={themeDispatch}
        onResize={onResize}
      />
    </Container>
  )
}
