import { Translation } from '@tm/shared-lib/src/i18n'
import { useStyles } from '@trustmary/form-styles'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { AutoSizer } from '../AutoSizer'
import { CheckIcon } from '../Icon'
import { CommonProps } from './models/commonProps'

interface FoldedProps {
  folded: boolean
}

const RangeArea = styled('div')<FoldedProps>(({ theme, folded }) => ({
  ...theme.styles.rangeArea,
  ...(folded ? theme.styles.rangeAreaFolded : {}),
}))
const RangeWrapper = styled('div')<FoldedProps>(({ theme, folded }) => ({
  ...theme.styles.rangeWrapper,
  ...(folded ? theme.styles.rangeWrapperFolded : {}),
}))
const RangeInner = styled('div')<FoldedProps>(({ theme, folded }) => ({
  ...theme.styles.rangeInner,
  ...(folded ? theme.styles.rangeInnerFolded : {}),
}))
const RangeItem = styled('button')<FoldedProps>(({ theme, folded }) => ({
  ...theme.styles.rangeItem,
  ...(folded ? theme.styles.rangeItemFolded : {}),
}))
const RangeItemButton = styled('div')<FoldedProps>(({ theme, folded }) => ({
  ...theme.styles.rangeItemButton,
  ...(folded ? theme.styles.rangeItemButtonFolded : {}),
}))
const RangeLabels = styled('div')<FoldedProps>(({ theme, folded }) => ({
  ...theme.styles.rangeLabels,
  ...(folded ? theme.styles.rangeLabelsFolded : {}),
}))
const RangeLabelsMin = styled('div')(({ theme }) => theme.styles.rangeLabelsMin)
const RangeLabelsMax = styled('div')(({ theme }) => theme.styles.rangeLabelsMax)
const RangeNoOpinion = styled('div')<FoldedProps>(({ theme, folded }) =>
  folded ? theme.styles.rangeNoOpinionFolded : theme.styles.rangeNoOpinion
)
// const RangeNoOpinionItem = styled('div')(({ theme }) => theme.styles.rangeNoOpinionItem)
const RangeNoOpinionItemIcon = styled('div')(({ theme }) => theme.styles.rangeNoOpinionItemIcon)
const RangeNoOpinionLabel = styled('div')(({ theme }) => theme.styles.rangeNoOpinionLabel)
const RangeNoOpinionLabelText = styled('div')(({ theme }) => theme.styles.rangeNoOpinionLabelText)
const RangeMobileLabel = styled('div')(({ theme }) => theme.styles.rangeMobileLabel)

const NO_OPINION = '_no'

interface Props extends CommonProps<number | string | null> {
  defaultLabels?: boolean
  rangeMin: number
  rangeMax: number
  rangeMinLabel?: Translation<string>
  rangeMaxLabel?: Translation<string>
  rangeNoOpinion?: boolean | undefined
  rangeNoOpinionLabel?: Translation<string>
  folded?: boolean
}

export function Range(props: Omit<Props, 'folded'>): JSX.Element {
  return (
    <AutoSizer>
      {({ width }) => {
        return <RangeComponent {...props} folded={width < 500} />
      }}
    </AutoSizer>
  )
}

function RangeComponent(props: Props): JSX.Element {
  const {
    value,
    //name,
    onValueChange,
    rangeMin,
    rangeMax,
    rangeMinLabel = {},
    rangeMaxLabel = {},
    defaultLabels,
    rangeNoOpinion = false,
    rangeNoOpinionLabel = {},
    folded = false,
  } = props

  const {
    values: { primaryColor },
  } = useStyles()
  const { t, lang } = useI18N()

  const minLabel = defaultLabels ? t('nps.label0') : rangeMinLabel[lang] || ''
  const maxLabel = defaultLabels ? t('nps.label10') : rangeMaxLabel[lang] || ''

  const steps: number[] = []
  for (let i = rangeMax; i >= rangeMin; i--) steps.push(i)

  const classes = ['range']
  const hasValue = typeof value === 'number' || value === NO_OPINION
  const numericValue = typeof value === 'number' ? value : -1

  if (hasValue) {
    classes.push('range--has-value')
  }

  const noOpinionLabel = rangeNoOpinionLabel[lang] || t('range.noOpinion')

  return (
    <RangeArea folded={folded}>
      <RangeWrapper folded={folded}>
        <RangeInner className="tm-range" folded={folded}>
          {steps.map((step, index) => {
            const classes = ['tm-range-item']
            if (folded) classes.push('tm-folded')
            if (hasValue) {
              if (step === numericValue) {
                classes.push('tm-selected')
              } else if (step <= numericValue) {
                classes.push('tm-under')
              } else {
                classes.push('tm-over')
              }
            }
            return (
              <RangeItem
                folded={folded}
                className={classes.join(' ')}
                key={step}
                onClick={e => {
                  e.preventDefault()
                  onValueChange(value === step ? null : step)
                }}>
                <RangeItemButton folded={folded} className="tm-range-item-button">
                  <span className="tm-range-label">{step}</span>
                  {folded && (
                    <>
                      {index === steps.length - 1 ? <RangeMobileLabel>{minLabel}</RangeMobileLabel> : null}
                      {index === 0 ? <RangeMobileLabel>{maxLabel}</RangeMobileLabel> : null}
                    </>
                  )}
                </RangeItemButton>
              </RangeItem>
            )
          })}
        </RangeInner>
        <RangeLabels folded={folded}>
          <RangeLabelsMin>{minLabel}</RangeLabelsMin>
          <RangeLabelsMax>{maxLabel}</RangeLabelsMax>
        </RangeLabels>
      </RangeWrapper>
      {rangeNoOpinion && (
        <RangeNoOpinion folded={folded}>
          <div className="tm-range-no-opinion-item">
            <RangeItem
              folded={folded}
              className={`tm-range-item tm-range-item--icon ${value === NO_OPINION ? 'tm-selected' : ''}`}
              onClick={e => {
                e.preventDefault()
                onValueChange(NO_OPINION)
              }}>
              <RangeItemButton folded={folded} className="tm-range-item-button">
                <span className="tm-range-label">
                  <RangeNoOpinionItemIcon style={folded ? { left: '10px', margin: '-9px 0 0 0' } : {}}>
                    {value === NO_OPINION && <CheckIcon fill={primaryColor} />}
                  </RangeNoOpinionItemIcon>
                </span>
                {folded && <RangeMobileLabel>{noOpinionLabel}</RangeMobileLabel>}
              </RangeItemButton>
            </RangeItem>
          </div>
          <RangeNoOpinionLabel style={folded ? { display: 'none' } : {}}>
            <RangeNoOpinionLabelText>{noOpinionLabel}</RangeNoOpinionLabelText>
          </RangeNoOpinionLabel>
        </RangeNoOpinion>
      )}
    </RangeArea>
  )
}
