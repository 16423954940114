import { Field, FieldSetField } from '@tm/shared-lib/src/field'
import { styled } from 'goober'
import React from 'react'

interface Props {
  field: FieldSetField
  questionRenderer: (field: Field) => JSX.Element
}

const Fieldset = styled('div')(({ theme }) => theme.styles.fieldset)
const FieldsetFields = styled('div')(({ theme }) => theme.styles.fieldsetFields)

export function FieldSetQuestion({ field, questionRenderer }: Props): JSX.Element {
  const { columns = 2 } = field
  return (
    <Fieldset className={`tm-fieldset tm-fieldset--columns-${columns}`}>
      <FieldsetFields className="tm-fieldset-fields">
        {field.fields && field.fields.map(questionRenderer)}
      </FieldsetFields>
    </Fieldset>
  )
}
