import { CssBaseline } from '@mui/material'
import { enUS } from '@mui/material/locale'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { getTheme, GlobalStyles } from '@trustmary/ui/theme'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export function ThemeProvider({ children }: Props) {
  const theme = getTheme(enUS)
  theme.palette.background.default = '#fff'

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <div></div>
      {children}
    </MUIThemeProvider>
  )
}
