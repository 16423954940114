import { useCallback, useMemo } from 'react'
import { useCampaign } from '../../../../hooks/useCampaign'
import { useI18N } from '../../../../hooks/useI18n'
import { ViewProps } from '../types'
import { UsePictureField } from './types'
import { ContactInfoFields } from './useReviewFields'

export function useProfilePictureField(
  { state, dispatch }: Pick<ViewProps, 'state' | 'dispatch'>,
  fields: ContactInfoFields
): UsePictureField {
  const { t } = useI18N()
  const { primaryColor, primaryTextColor } = useCampaign()
  const profilePictureField = useMemo(() => fields.get('imageFilename'), [fields])

  const onProfilePictureChange = useCallback(
    (files: FileList | null) => {
      const [file] = files || []
      dispatch({ type: 'set file', field: { name: 'imageFilename', system: true }, file })
    },
    [dispatch]
  )

  const value = useMemo(
    () =>
      state.getFile({
        name: 'imageFilename',
        system: true,
      }),
    [state]
  )

  const labelKey =
    profilePictureField && 'labelKey' in profilePictureField && profilePictureField.labelKey
      ? profilePictureField.labelKey
      : 'review.imageFilename'
  const label =
    profilePictureField && 'label' in profilePictureField && profilePictureField.label
      ? profilePictureField.label
      : t(labelKey)

  return {
    value,
    onChange: onProfilePictureChange,
    label,
    primaryColor,
    primaryTextColor,
    chooseImageButtonText: t('file.chooseImage'),
    imagePlaceholder:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAChklEQVR4Ae3ay0uUcRTG8WOUQXSBGiiDRCnIAi1KamEbV7Xqj21VKzcVRBaV0A1FUbzBqHhBUBf6ewZGRETH93HOOcLzATcy4zjfea/nNx1b2zu7JpVdMKEoIEkBSQpIUkCSApIUkKSAJAUkKSBJAUkKSFJAkgKSFJCkgCQFJCkgSQFJCkhSQJICkhSQdNGSmVuo2+TMvE1Nz9v6xmbjd7du3rBa+Rl80mfXrl6xTDqyLKyX/8O+/fxrv35PHPu4wad9jZBZpAiIeO8+fLSl5dWWHv/wQbcNDz2zDFIcA7HltRoP/o1P2+evY5ZBeEAc507abY+C5zSPkZHCA46Wra8qbInRwgOeZtc9bKqcraOFB6wTAde0C59/4QGZC+PrCS6qwwP23uuyqrpu1yxaeMCe7uoBBx7ft2jhAe/eqdnAo9OHyHJfnOIkgvtbDAtaheECnpNBmmHCdrkfHv1Rhgl/jr8r6S9b69CLfssiTcAm3J4h5NLK6v41InbVnnKy6S3HS+zymaQLeN7oQpqkgKTwkT6Gqf/LVGV2sd4YLJw0osIZGMdEXIBjsBot9BiIUdZYmeshYhUIibNy5AV1SEBsZe9HvlCjrIOwVb4ZfhlyYe0eEPGw/nHW02TEe/v6lXtE95NIO+IB/ubIp+/mzTUgjnntXMfAmnKV9RWGW0DcqnmsYeBDqnpSqsIt4OSBbxq0k9cH1eQX0HEBCLuyF7eAG44LQBhEeHELWF/2e1OeC+5uAS93XjIvnY6v5RbweRnBe0TEa3h+e0vzQJLGWSQFJCkgSQFJCkhSQJICkhSQpIAkBSQpIEkBSQpIUkCSApIUkKSAJAUkKSBJAUkKSFJAkgKS9gCiFet89AgpLgAAAABJRU5ErkJggg==',
  }
}
