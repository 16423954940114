import { SurveyTranslation } from '@tm/shared-lib/src/i18n'
import { useCallback, useContext, useMemo } from 'react'
import { TranslationsContext } from '../context/translationsContext'

export type TranslateFunction = (txt: string, defaultValue?: string) => string

export function useI18N(): { t: TranslateFunction; lang: string; surveyLanguages: SurveyTranslation[] } {
  const ctx = useContext(TranslationsContext)
  if (!ctx) throw new Error('Translations context not provided')
  const { surveyLanguages = [], lang, useDefault = false } = ctx || {}

  const languages = useMemo(() => {
    return surveyLanguages.filter(e => e.lang === lang)
  }, [surveyLanguages, lang])

  const t: TranslateFunction = useCallback(
    (txt, defaultValue?) => {
      if (languages.length === 1) {
        const translations = languages[0].translations
        if (txt in translations) {
          return translations[txt]
        }
      }
      return typeof defaultValue === 'string' ? defaultValue : txt
    },
    [languages]
  )

  return { t, lang: useDefault ? '_d' : lang, surveyLanguages }
}

export default useI18N
