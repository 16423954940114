import { CustomValue, GetSurveyData, isCustomObj, StepValues } from '@tm/shared-lib/src/api'
import { EditableField, Field } from '@tm/shared-lib/src/field'
import { SurveyTranslation } from '@tm/shared-lib/src/i18n'
import { Step } from '@tm/shared-lib/src/step'
import { MetaData, SocialShare } from '@tm/shared-lib/src/survey'
import { getStepValueByString } from '@tm/shared-lib/src/util'
import { PublicConsentType } from '@tm/types/db/tables'
import { ThanksPage as ThanksPageTexts } from '@tm/types/survey/entities'
import produce, { immerable } from 'immer'
import { StepFiles } from '../../../types/stepFiles'
import { Action } from '../actions/action'
import { surveyClosedState } from './closed'
import { surveyNotFoundState } from './notFound'
import type { BaseState, State } from './state'

function fieldDefaultValue(field: EditableField): '' | [] | false | null | undefined {
  switch (field.type) {
    case 'checkboxes':
    case 'multidropdown':
      return []
    case 'checkbox':
    case 'public_consent':
    case 'privacy_policy':
      return false
    case 'comment':
    case 'email':
    case 'textarea':
    case 'textfield':
      return ''
    case 'dropdown':
    case 'nps':
    case 'range':
    case 'stars':
    case 'radios':
    case 'video':
    case 'image':
    case 'widget':
    case 'contact_info':
      return null
  }
}

export interface InProgressState extends BaseState {
  type: 'in progress'
  eventId: string
  answerId?: string
  invitationId?: string
  source?: string
  lang: string
  surveyId: string
  features: string[]
  meta: MetaData
  step: Step
  values: StepValues
  files: StepFiles
  submitError: string | undefined
  setSubmitError: (error: string | undefined) => void
  getValue: (field: { name: string; system?: boolean }) => CustomValue | undefined
  getFile: (field: { name: string; system?: boolean }) => File | File[] | undefined
  thanksPage?: ThanksPage
  customParams?: Record<string, string>
  publicConsent?: PublicConsentType
  isCampaignSurvey?: boolean
}

function getLang(languages: SurveyTranslation[], overrideLang: string | undefined): string {
  //1: use overrideLang if available
  if (overrideLang) {
    const lang = languages.find(language => language.lang === overrideLang)
    if (lang) return lang.lang
  }

  //2: use browerLang if available
  const [browserLangCode] = navigator.language.split('-')
  const lang = languages.find(i => i.code === browserLangCode)
  if (lang) return lang.lang

  //3: use survey mainLang
  const mainLangs = languages.filter(language => language.main)
  if (mainLangs.length > 0) return mainLangs[0].lang

  //4: use 'fi' as the last fallback
  return 'fi'
}

export class InProgressStateImpl implements InProgressState {
  [immerable] = true
  type: 'in progress' = 'in progress' as const
  values: StepValues
  surveyId: string
  eventId: string
  step: Step
  meta: MetaData
  features: string[]
  answerId?: string
  invitationId?: string
  source?: string
  files: StepFiles = {}
  lang: string
  submitError: string | undefined = undefined
  thanksPage?: ThanksPage
  customParams?: Record<string, string>
  publicConsent?: PublicConsentType
  isCampaignSurvey?: boolean
  constructor(args: {
    values: StepValues
    surveyId: string
    eventId: string
    step: Step
    meta: MetaData
    features: string[]
    invitationId?: string
    source?: string
    answerId?: string
    overrideLang?: string
    customParams?: Record<string, string>
    publicConsent?: PublicConsentType
    isCampaignSurvey?: boolean
  }) {
    this.values = args.values
    this.surveyId = args.surveyId
    this.eventId = args.eventId
    this.step = args.step
    this.meta = args.meta
    this.features = args.features
    this.invitationId = args.invitationId
    this.source = args.source
    this.answerId = args.answerId
    this.lang = getLang(args.meta.languages, args.overrideLang)
    this.customParams = args.customParams
    this.publicConsent = args.publicConsent
    this.isCampaignSurvey = args.isCampaignSurvey
  }

  setLang(lang: string): InProgressStateImpl {
    return produce(this, draft => {
      draft.lang = lang
    })
  }

  setSubmitError(error: string | undefined): InProgressStateImpl {
    return produce(this, draft => {
      draft.submitError = error
    })
  }

  getValue({ name, system = false }: { name: string; system?: boolean }): CustomValue | undefined {
    if (system) {
      const res = this.values[name]
      return isCustomObj(res) ? undefined : res
    } else {
      return this.values.custom?.[name]
    }
  }

  setValue(
    { name, system = false }: { name: string; system?: boolean },
    newValue: CustomValue | undefined
  ): InProgressStateImpl {
    return produce(this, draft => {
      if (system) {
        if (newValue === undefined) delete draft.values[name]
        else draft.values[name] = newValue
      } else {
        if (newValue !== undefined) {
          if (!draft.values.custom) draft.values.custom = {}
          draft.values.custom[name] = newValue
        } else if (draft.values.custom) {
          delete draft.values.custom[name]
        }
      }
    })
  }

  getFile({ name, system = false }: { name: string; system?: boolean }): File | File[] | undefined {
    if (system) {
      return this.files[name]
    } else {
      return this.files.custom?.[name]
    }
  }

  setFile(
    { name, system = false }: { name: string; system?: boolean },
    newValue: File | File[] | undefined
  ): InProgressStateImpl {
    return produce(this, draft => {
      if (system) {
        if (newValue === undefined) delete draft.files[name]
        else draft.files[name] = newValue
      } else {
        if (newValue !== undefined) {
          if (!draft.files.custom) draft.files.custom = {}
          draft.files.custom[name] = newValue
        } else if (draft.files.custom) {
          delete draft.files.custom[name]
        }
      }
    })
  }

  processDefaultvalues(): InProgressStateImpl {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let res: InProgressStateImpl = this

    const processField = (field: Field) => {
      if (field.type === 'content') return
      if (field.type === 'fieldset') {
        if (field.fields) field.fields.forEach(fieldsetField => processField(fieldsetField))
        return
      }
      if (this.getValue(field) !== undefined) return
      if ('defaultValue' in field && field.defaultValue !== undefined) {
        const defaultValue = field.defaultValue
        res = res.setValue(field, defaultValue)
        return
      } else if ('sourceField' in field && field.sourceField !== undefined) {
        const sourceField = field.sourceField
        const val = getStepValueByString(this.values, sourceField)
        if (val !== null) {
          res = res.setValue(field, val)
          return
        }
      }
      res = res.setValue(field, fieldDefaultValue(field))
    }
    for (const field of this.step.fields || []) {
      processField(field)
    }
    return res
  }

  // Widget editor needs to update form dynamically
  setForm(form: GetSurveyData): InProgressStateImpl {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    return produce(this, draft => {
      draft.step = form.step
      draft.meta = form.meta

      const mainLangs = form.meta.languages.filter(language => language.main)
      draft.lang = mainLangs.length > 0 ? mainLangs[0].lang : 'fi'
    })
  }

  processAction(action: Action): State {
    switch (action.type) {
      case 'set form':
        return this.setForm(action.form)
      case 'field uploaded':
        return this.setFile(action.field, undefined).setValue(action.field, action.fileName)
      case 'set language':
        return this.setLang(action.lang)
      case 'set value':
        return this.setValue(action.field, action.value)
      case 'set file': {
        return this.setFile(action.field, action.file)
      }
      case 'submit error': {
        return this.setSubmitError(action.error)
      }
      case 'post response': {
        switch (action.data.type) {
          case 'step': {
            const postResponse = action.data
            try {
              if (postResponse.answerId) localStorage.setItem(`${this.surveyId}_answerId`, postResponse.answerId)
            } catch {
              //Cookies are blocked
            }
            return produce(this, draft => {
              draft.step = postResponse.step
              draft.answerId = postResponse.answerId
            }).processDefaultvalues()
          }
          case 'thanks': {
            const thanksResponse = action.data
            try {
              if (thanksResponse.answerId) localStorage.setItem(`${this.surveyId}_answerId`, thanksResponse.answerId)
            } catch {
              //Cookies are blocked
            }
            if (thanksResponse.thanks?.redirect) window.location.href = thanksResponse.thanks.redirect
            const thanksPage: ThanksPage = {
              thanks: thanksResponse.thanks,
              share: thanksResponse.share,
            }
            return produce(this, draft => {
              draft.thanksPage = thanksPage
              draft.answerId = thanksResponse.answerId
            })
          }
          case 'error': {
            switch (action.data.errorCode) {
              case 'surveyNotFound':
                return surveyNotFoundState
              case 'surveyClosed':
                return surveyClosedState
            }
            return this.setSubmitError('submit failed') //Other error such as 'Internal Error'
          }
        }
      }
    }
    return this
  }
}

interface ThanksPage {
  thanks?: ThanksPageTexts
  share?: SocialShare
}
