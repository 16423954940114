import { CheckboxField } from '@tm/shared-lib/src/field'
import React from 'react'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Checkbox } from '../field/Checkbox'

interface Props {
  field: CheckboxField
}

export function CheckboxQuestion({ field }: Props): JSX.Element {
  const [value, setValue] = useSurveyValue(field)
  if (typeof value === 'string' && (value === 'true' || value === 'false' || value === '')) {
    return <Checkbox value={value === 'true'} onValueChange={setValue} {...field} />
  } else if (value === undefined || typeof value === 'boolean') {
    return <Checkbox value={value} onValueChange={setValue} {...field} />
  }
  throw new Error('Checkbox does not support value: ' + value)
}
