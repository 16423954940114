import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { CampaignGroup } from '@tm/client-form/src/components/campaign/CampaignGroup'
import { CampaignSubmit } from '../../CampaignSubmit'
import { ConsentField, ConsentFieldProps } from '../../ConsentField/ConsentField'

export const FeedbackFieldNames = ['feedback_comment', 'contactName', 'contactEmail', 'contactPhone'] as const

export type FeedbackFieldName = typeof FeedbackFieldNames[number]

type FeedbackViewProps = {
  values: Record<FeedbackFieldName, string>
  onChange: (name: FeedbackFieldName, value: string) => void
  labels: Record<FeedbackFieldName, string>
  onSubmit: () => void
  isSubmitting?: boolean
  title: string
  description: string
  errors: Record<FeedbackFieldName, string[] | undefined>
  requiredFields: FeedbackFieldName[]
  consentFieldProps: ConsentFieldProps
}

type FeedbackFieldProps = Pick<
  FeedbackViewProps,
  'values' | 'errors' | 'labels' | 'isSubmitting' | 'onChange' | 'requiredFields'
> & {
  name: FeedbackFieldName
  type: 'text' | 'email' | 'textarea'
}

function FeedbackField(props: FeedbackFieldProps) {
  const { name, type, values, errors, labels, onChange, isSubmitting, requiredFields } = props
  const isTextarea = type === 'textarea'
  const fieldErrors = (errors[name] || []).join(', ')

  const isRequired = requiredFields.includes(name)
  const label = isRequired ? `${labels[name]} *` : labels[name]

  return (
    <TextField
      id={`q-${name}`}
      label={label}
      value={values[name]}
      error={!!fieldErrors}
      helperText={fieldErrors}
      onChange={event => onChange(name, event.target.value)}
      multiline={isTextarea}
      rows={isTextarea ? 4 : undefined}
      variant="filled"
      disabled={isSubmitting}
      type={type}
    />
  )
}

export function FeedbackView(props: FeedbackViewProps) {
  const {
    values,
    onChange,
    labels,
    onSubmit,
    isSubmitting,
    title,
    description,
    errors,
    requiredFields,
    consentFieldProps,
  } = props

  const fieldProps = {
    values,
    errors,
    labels,
    isSubmitting,
    onChange,
    requiredFields,
  }

  return (
    <>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      <CampaignGroup>
        <FeedbackField name="contactName" type="text" {...fieldProps} />
        <FeedbackField name="contactEmail" type="email" {...fieldProps} />
        <FeedbackField name="contactPhone" type="text" {...fieldProps} />
        <FeedbackField name="feedback_comment" type="textarea" {...fieldProps} />
      </CampaignGroup>
      <ConsentField {...consentFieldProps} />
      <CampaignSubmit onClick={onSubmit} disabled={isSubmitting} />
    </>
  )
}
