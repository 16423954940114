import { useCallback, useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'
import { StepFiles } from '../types/stepFiles'

interface Upload {
  filename: string //'162a18e885fd4dbb0580ab7308dc3ed0.png'
  data: {
    url: string
    fields: { [name: string]: string }
  }
}

interface State {
  uploadFields: (stepFiles: StepFiles) => Promise<void>
  //uploadField: (field: { name: string; system: boolean }, file: File | File[]) => Promise<void>
  fieldUploadProgress: { [key: string]: number | number[] }
}

const constructMultipart = (file: File, json: Upload): FormData => {
  const payload2 = new FormData()
  for (const [key, value] of Object.entries(json.data.fields)) {
    payload2.append(key, value)
  }
  payload2.append('file', file, json.filename)
  return payload2
}

const prepareUpload = (file: File): Promise<Upload> => {
  const payload = { filename: file.name, type: file.type, size: file.size }
  return fetch(`${process.env.API_DOMAIN}/collect/v2/file`, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(res => res.json())
}

export function useSurveyUpload(
  onFieldUploaded: (field: { name: string; system: boolean }, filename: string | string[]) => void
): State {
  const [fieldUploadProgress, updateFieldUploadProgress] = useImmer<{
    [key: string]: number | number[]
  }>({})

  const aborts = useRef<Set<() => void>>(new Set())

  const finishUpload = useCallback(
    (payload: FormData, url: string, options: { xhrHandle?: (xhr: XMLHttpRequest) => void }): Promise<void> => {
      const xhrUpload = new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        options.xhrHandle?.(xhr)
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) resolve()
          else reject()
        }
        xhr.onerror = () => {
          reject()
        }
        aborts.current.add(xhr.abort)
        xhr.addEventListener('loadend', () => {
          aborts.current.delete(xhr.abort)
        })
        xhr.open('POST', url)
        xhr.send(payload)
      })
      return xhrUpload
    },
    []
  )

  const uploadFile = useCallback(
    (file: File, options?: { xhrHandle?: (xhr: XMLHttpRequest) => void }): Promise<string> => {
      return prepareUpload(file).then(json => {
        const formData = constructMultipart(file, json)
        return finishUpload(formData, json.data.url, {
          xhrHandle: options?.xhrHandle,
        }).then(() => json.filename)
      })
    },
    [finishUpload]
  )

  const uploadMultiField = useCallback(
    (field: { name: string; system: boolean }, file: File[]): Promise<void> => {
      const key: string = field.system ? field.name : `custom.${field.name}`
      updateFieldUploadProgress(draft => {
        draft[key] = file.map(() => 0)
      })

      return Promise.all(
        file.map((f, i) => {
          return uploadFile(f, {
            xhrHandle: (xhr: XMLHttpRequest) => {
              xhr.upload.onprogress = e => {
                if (e.lengthComputable) {
                  const progress = e.loaded / e.total
                  updateFieldUploadProgress(draft => {
                    const fieldProgress = draft[key]
                    if (Array.isArray(fieldProgress)) {
                      fieldProgress[i] = progress
                    }
                  })
                }
              }
            },
          }).then(res => {
            updateFieldUploadProgress(draft => {
              const fieldProgress = draft[key]
              if (Array.isArray(fieldProgress)) {
                fieldProgress[i] = 1
              }
            })
            return res
          })
        })
      ).then(fileNames2 => onFieldUploaded(field, fileNames2))
    },
    [uploadFile, onFieldUploaded, updateFieldUploadProgress]
  )

  const uploadSingleField = useCallback(
    (field: { name: string; system: boolean }, file: File): Promise<void> => {
      const key: string = field.system ? field.name : `custom.${field.name}`
      updateFieldUploadProgress(draft => {
        draft[key] = 0
      })
      return uploadFile(file, {
        xhrHandle: xhr => {
          xhr.upload.onprogress = e => {
            if (e.lengthComputable) {
              const progress = e.loaded / e.total
              updateFieldUploadProgress(draft => {
                draft[key] = progress
              })
            }
          }
        },
      }).then(fileName => {
        updateFieldUploadProgress(draft => {
          draft[key] = 1
        })
        onFieldUploaded(field, fileName)
      })
    },
    [uploadFile, onFieldUploaded, updateFieldUploadProgress]
  )

  const uploadField = useCallback(
    (field: { name: string; system: boolean }, file: File | File[]): Promise<void> => {
      if (Array.isArray(file)) {
        return uploadMultiField(field, file)
      } else {
        return uploadSingleField(field, file)
      }
    },
    [uploadMultiField, uploadSingleField]
  )

  const uploadFields = useCallback(
    (stepFiles: StepFiles): Promise<void> => {
      updateFieldUploadProgress({})
      const promises: Promise<void>[] = []
      for (const name in stepFiles) {
        if (name === 'custom') {
          const customFiles = stepFiles.custom
          if (customFiles) {
            for (const name in customFiles) {
              const file = customFiles[name]
              promises.push(uploadField({ name, system: false }, file))
            }
          }
        } else {
          const file = stepFiles[name]
          promises.push(uploadField({ name, system: true }, file))
        }
      }
      return Promise.all(promises).then()
    },
    [uploadField, updateFieldUploadProgress]
  )

  useEffect(() => {
    const abortUpload = aborts.current
    return () => {
      abortUpload.forEach(abortField => abortField())
    }
  }, [])

  return {
    uploadFields,
    fieldUploadProgress,
  }
}
