import { styled } from 'goober'
import React, { useEffect } from 'react'
import { useFieldsToDisplay } from '../../hooks/useFieldsToDisplay'
import { useI18N } from '../../hooks/useI18n'
import { Action } from '../../hooks/useSurvey/actions/action'
import { InProgressState } from '../../hooks/useSurvey/states/inProgress'
import { useTheme } from '../../hooks/useTheme'
import { LangSwitcher } from '../LangSwitch'
import { Footer } from '../page/Footer'
import { PageInner } from '../page/PageInner'
import { ProgressBar } from './ProgressBar'
import { SurveyAction } from './SurveyAction'
import { SurveyFields } from './SurveyFields'
import { PassProps, SurveyForm } from './SurveyForm'
import { SurveyHeader } from './SurveyHeader'
import { UnexpectedError } from './UnexpectedError'

const SurveyFormInner = styled<{ view: string; className: string }>('div')(({ view }) => {
  return view === 'frame'
    ? {
        padding: '0 10px',
      }
    : {
        padding: '30px 20px 20px 20px',
        maxWidth: '820px',
        margin: '0 auto',
        '@media screen and (max-width: 600px)': {
          padding: '10px',
        },
      }
})

const SurveyFormFields = styled('div')<{ view: string }>(({ view }) =>
  view === 'frame' ? { margin: '0 -10px' } : { margin: '20px 0 0', position: 'relative' }
)

export function Survey({ state, dispatch }: { state: InProgressState; dispatch: React.Dispatch<Action> }): JSX.Element {
  const { t } = useI18N()
  const { view } = useTheme()

  useEffect(() => {
    const hash = window.location.hash
    if (hash.length <= 1) return
    const json = window.location.hash.substring(1)
    try {
      const obj = JSON.parse(decodeURIComponent(json))
      for (const [key, value] of Object.entries(obj)) {
        dispatch({ type: 'set value', field: { system: true, name: key }, value: value as any })
      }
      window.location.hash = ''
    } catch (e) {
      console.warn(`window.location.hash is not valid JSON`)
    }
  }, [dispatch])

  useEffect(() => {
    document.title = t('survey.title')
  }, [t])

  const fieldsToDisplay = useFieldsToDisplay(state)

  const setLang = (lang: string) => {
    dispatch({ type: 'set language', lang: lang })
  }

  /**
   * In step level, submit text can be overriden in v2 editor
   */
  const { submitText = {} } = state.step
  const submitButtonText = submitText[state.lang] || t('form.defaultSubmit')

  return (
    <React.Fragment>
      <LangSwitcher setLang={setLang} />
      <PageInner className="page-inner">
        <SurveyForm fields={fieldsToDisplay} state={state} dispatch={dispatch}>
          {({ fields, state, validationErrors, submitStatus }: PassProps) => {
            return (
              <React.Fragment>
                <SurveyFormInner view={view} className="survey-form-inner">
                  <SurveyHeader logo={state.meta.logo} title={state.meta.title || state.meta.companyName || 'Survey'} />
                  {state.submitError && <UnexpectedError />}
                  <ProgressBar stepCount={state.meta.stepCount} stepNum={state.step.stepNum} position={'before'} />
                  <SurveyFormFields view={view}>
                    <SurveyFields fields={fields} validationErrors={validationErrors} />
                  </SurveyFormFields>
                  <ProgressBar stepCount={state.meta.stepCount} stepNum={state.step.stepNum} position={'after'} />
                  {state.submitError && <UnexpectedError />}
                </SurveyFormInner>
                <SurveyAction
                  text={submitButtonText}
                  icon={submitStatus === 'none' ? 'active' : 'loading'}
                  disabled={submitStatus !== 'none'}
                />
              </React.Fragment>
            )
          }}
        </SurveyForm>
      </PageInner>
      {!state.meta.whitelabel && <Footer />}
    </React.Fragment>
  )
}
