import { VideoField } from '@tm/shared-lib/src/field'
import { VideoCollect } from './VideoCollect'
import { VideoLanding } from './VideoLanding'
import { VideoPreview } from './VideoPreview'
import { VideoFooter } from './VideoFooter'
import { VideoDemo } from './VideoDemo'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { VideoContextProvider, useVideoContext } from './VideoContext'

function VideoPageInner() {
  const { page, setPage } = useVideoContext()

  switch (page.type) {
    case 'demo':
      return <VideoDemo />
    case 'landing':
      return (
        <VideoLanding
          onRecord={() => {
            setPage({ type: 'record' })
          }}
          error={page.error}
        />
      )
    case 'record':
      return <VideoCollect />

    default:
      return <VideoPreview file={page.file} />
  }
}

function VideoPage() {
  const { page } = useVideoContext()

  return (
    <Stack gap={2}>
      <Stack
        sx={{
          boxShadow: theme => theme.customShadows.card,
          borderRadius: {
            xs: 0,
            sm: 1,
          },
          background: theme => theme.palette.grey[900],
        }}>
        <VideoPageInner />
      </Stack>
      {page.type !== 'preview' && <VideoFooter />}
    </Stack>
  )
}

export type VideoProps = {
  field: VideoField
  demo?: boolean
}

export function VideoInput({ field, demo }: VideoProps): JSX.Element {
  return (
    <Box
      sx={{
        mx: {
          xs: -2,
          sm: 0,
        },
      }}>
      <VideoContextProvider field={field} demo={demo}>
        <VideoPage />
      </VideoContextProvider>
    </Box>
  )
}
