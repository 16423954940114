import { Field } from '@tm/shared-lib/src/field'
import { useCallback, useMemo } from 'react'
import { useI18N } from '../../../../hooks/useI18n'
import { ViewProps } from '../types'
import { ReviewFields } from './useReviewFields'

const getTranslatedValues = (field: Field | undefined, lang: string) => {
  const values = {
    label: '',
    description: '',
    inputLabel: '',
  }

  if (!field || field.type !== 'comment') return values

  const { label, description, inputLabel } = field

  if (label?.[lang]) values.label = label[lang]
  if (description?.[lang]) values.description = description[lang]
  if (inputLabel?.[lang]) values.inputLabel = inputLabel[lang]

  return values
}

export function useTextReview(props: ViewProps, reviewFields: ReviewFields) {
  const { state, dispatch, validationErrors } = props
  const { t } = useI18N()

  const commentField = reviewFields.get('comment')

  const commentValue = state.values.comment || ''

  const onCommentChange = useCallback(
    (value: string) => {
      dispatch({
        type: 'set value',
        field: {
          name: 'comment',
          system: true,
        },
        value,
      })
    },
    [dispatch]
  )

  const { label, description, inputLabel } = getTranslatedValues(commentField, state.lang)

  const commentError = useMemo(() => {
    if (validationErrors.comment) {
      return validationErrors.comment.map(error => t(error)).join(', ')
    }
    return ''
  }, [validationErrors, t])

  return {
    hasTextReview: !!commentField,
    commentValue,
    onCommentChange,
    commentError,
    label,
    description,
    inputLabel,
  }
}

export type UseTextReview = ReturnType<typeof useTextReview>
