import { useState, useReducer, useRef, useMemo, useEffect, useCallback } from 'react'
import { useMediaDevices } from '@tm/client-form/src/hooks/useMediaDevices'

import { useVideoContext } from '../VideoContext'

export function useVideoRecord(stream: MediaStream) {
  const { setConstraints } = useVideoContext()

  const [recordingStatus, setRecordingStatus] = useState<boolean | number | 'start'>(false) //false = not started, true = recording, number = seconds until recording starts

  const { devices } = useMediaDevices()

  const [recordingTimer, setRecordingTimer] = useReducer(
    (state: number | undefined, action: number | undefined) => action,
    undefined
  )
  const chunks = useRef<Blob[]>([])

  const options = useMemo(() => {
    const mimeType = ['video/mp4', 'video/webm;codecs=vp8,opus'].find(mimeType => {
      return MediaRecorder.isTypeSupported(mimeType)
    })
    return {
      mimeType,
    }
  }, [])

  const fileExtension = useMemo(() => {
    return options.mimeType === 'video/mp4' ? '.mp4' : '.webm'
  }, [options.mimeType])

  const cameraDevices = useMemo(() => {
    if (!devices) return []
    return devices.filter(device => device.kind === 'videoinput')
  }, [devices])

  const microphoneDevices = useMemo(() => {
    if (!devices) return []
    return devices.filter(device => device.kind === 'audioinput')
  }, [devices])

  const mediaRecorder = useMemo(() => {
    const mediaRecorder = new MediaRecorder(stream, options)
    mediaRecorder.ondataavailable = (e: BlobEvent) => {
      chunks.current.push(e.data)
      setRecordingTimer(chunks.current.length * 1000)
    }
    return mediaRecorder
  }, [stream, options])

  useEffect(() => {
    setRecordingTimer(undefined)
    setRecordingStatus(false)
  }, [mediaRecorder])

  const mirror = useMemo(() => {
    return stream.getVideoTracks()[0]?.getSettings().facingMode !== 'environment'
  }, [stream])

  const selectedDevices = useMemo(() => {
    const res = new Set<string>()
    stream.getTracks().forEach(track => {
      const settings = track.getSettings()
      if (settings.deviceId) res.add(settings.deviceId)
    })
    return res
  }, [stream])

  const selectedCamera = cameraDevices.find(device => selectedDevices.has(device.deviceId))
  const selectedMicrophone = microphoneDevices.find(device => selectedDevices.has(device.deviceId))

  const onCameraSelected = useCallback(
    (deviceId: string) => {
      setConstraints(draft => {
        if (typeof draft.video === 'object') {
          draft.video.deviceId = deviceId
          delete draft.video.facingMode
        } else {
          draft.video = { deviceId }
        }
      })
    },
    [setConstraints]
  )

  const onMicrophoneSelected = useCallback(
    (deviceId: string) => {
      setConstraints(draft => {
        if (typeof draft.audio === 'object') {
          draft.audio.deviceId = deviceId
        } else {
          draft.audio = { deviceId }
        }
      })
    },
    [setConstraints]
  )

  useEffect(() => {
    if (recordingStatus === 'start') {
      const timeout = setTimeout(() => {
        setRecordingStatus(true)
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    } else if (typeof recordingStatus === 'number') {
      const fn =
        recordingStatus === 0
          ? () => {
              mediaRecorder.start(1000)
              setRecordingTimer(0)
              setRecordingStatus('start')
            }
          : () => setRecordingStatus(recordingStatus - 1)
      const timeout = setTimeout(fn, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [mediaRecorder, recordingStatus, setRecordingStatus, setRecordingTimer])

  return {
    recordingStatus,
    setRecordingStatus,
    recordingTimer,
    setRecordingTimer,
    chunks,
    options,
    fileExtension,
    mediaRecorder,
    mirror,
    cameraDevices,
    microphoneDevices,
    selectedDevices,
    selectedCamera,
    selectedMicrophone,
    onCameraSelected,
    onMicrophoneSelected,
  }
}
