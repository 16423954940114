import { useVideoContext } from '../VideoContext'

import Button from '@mui/material/Button'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { Icon } from '@trustmary/icons'

export function VideoUpload() {
  const { onChange } = useVideoContext()
  const { t } = useI18N()
  return (
    <Button
      component="label"
      size="small"
      variant="outlined"
      color="inherit"
      startIcon={<Icon icon={faUpload} />}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        input: {
          visibility: 'hidden',
          width: 0,
          position: 'absolute',
        },
        '.MuiButton-startIcon>*:nth-of-type(1)': {
          fontSize: 14,
          color: 'text.secondary',
        },
      }}>
      {t('comment.video.uploadButton')}
      <input
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        onChange={event => {
          event.preventDefault()
          const input = event.target
          if (!input?.files?.length) return
          const file = input.files[0]
          onChange(file)
        }}
        multiple={false}
      />
    </Button>
  )
}
