import { Field } from '@tm/shared-lib/src/field'
import { useMemo } from 'react'
import { useI18N } from '../../../../hooks/useI18n'
import { ViewProps } from '../types'
import { ReviewFields } from './useReviewFields'

const getTranslatedValues = (field: Field | undefined, lang: string) => {
  const values = {
    label: '',
    description: '',
    inputLabel: '',
  }

  if (!field || field.type !== 'video') return values

  const { label, description } = field

  if (label?.[lang]) values.label = label[lang]
  if (description?.[lang]) values.description = description[lang]

  return values
}

export function useVideoReview(props: ViewProps, reviewFields: ReviewFields) {
  const { state, validationErrors } = props
  const { t } = useI18N()

  const videoField = reviewFields.get('comment_video')

  const videoError = useMemo(() => {
    if (validationErrors.comment_video) {
      return validationErrors.comment_video.map(error => t(error)).join(', ')
    }
    return ''
  }, [validationErrors, t])

  const videoValue = state.values.comment_video || state.files.comment_video

  const { label, description } = getTranslatedValues(videoField, state.lang)

  return {
    hasVideoReview: !!videoField,
    videoValue,
    videoError,
    label,
    description,
    videoField,
    demo: false,
  }
}

export type UseVideoReview = ReturnType<typeof useVideoReview>
