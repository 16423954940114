import { Translation } from '@tm/shared-lib/src/i18n'
import { styled } from 'goober'
import React, { useMemo } from 'react'
import { useI18N } from '../../hooks/useI18n'
import { CheckIcon } from '../Icon'
import { CommonProps } from './models/commonProps'

const CheckboxContainer = styled('div')<{ variant?: string }>(({ theme, variant }) =>
  variant === 'footnote' ? theme.styles.checkboxContainerFootnote : theme.styles.checkboxContainer
)
const CheckboxWrapper = styled('div')(({ theme }) => theme.styles.checkboxWrapper)
const CheckboxElement = styled('button')(({ theme }) => theme.styles.checkbox)
const CheckboxIcon = styled('span')(({ theme }) => theme.styles.checkboxIcon)
const CheckboxIconSvg = styled(CheckIcon)(({ theme }) => theme.styles.checkboxIconSvg)
const CheckboxLabel = styled('div')(({ theme }) => theme.styles.checkboxLabel)
const CheckboxLabelRequired = styled('span')(({ theme }) => theme.styles.checkboxLabelRequired)
const CheckboxLabelLinkWrapper = styled('div')(({ theme }) => theme.styles.checkboxLabelLinkWrapper)
const CheckboxLabelLink = styled('a')(({ theme }) => theme.styles.checkboxLabelLink)

interface Props extends CommonProps<boolean> {
  link?: { url: string; text: string }
  renderLabel?: (labelStr?: string) => React.ReactNode
  inputLabel?: Translation<string>
  inputLabelKey?: string
  style?: string
  validation?: string[]
}

export function Checkbox(props: Props): JSX.Element {
  const { inputLabel = {}, inputLabelKey, style, value, onValueChange, link, validation = [], renderLabel } = props
  const { lang, t } = useI18N()

  const classes = ['tm-checkbox']
  if (value) classes.push('tm-checkbox--selected')

  const labelStr = useMemo(() => {
    if (inputLabel[lang]) return inputLabel[lang]
    else if (inputLabelKey) return t(inputLabelKey)
    else return ''
  }, [inputLabel, inputLabelKey, t, lang])

  const isRequired = Array.isArray(validation) && validation.includes('required')
  return (
    <CheckboxContainer variant={style} className="tm-checkbox-wrapper">
      <CheckboxWrapper className={classes.join(' ')}>
        <CheckboxElement
          className="tm-checkbox-icon-wrapper"
          onClick={e => {
            e.preventDefault()
            onValueChange(!value)
          }}>
          <CheckboxIcon className="tm-checkbox-icon">{value ? <CheckboxIconSvg /> : null}</CheckboxIcon>
        </CheckboxElement>
        <CheckboxLabel
          className="tm-checkbox-label"
          onClick={e => {
            if ((e.target as HTMLElement).tagName !== 'A') onValueChange(!value)
          }}>
          {renderLabel ? renderLabel(labelStr) : labelStr}
          {isRequired && <CheckboxLabelRequired className="tm-checkbox-label-required">*</CheckboxLabelRequired>}
          {link && (
            <CheckboxLabelLinkWrapper className="tm-checkbox-label-link">
              <CheckboxLabelLink href={link.url} target="_BLANK" rel="noreferrer">
                {link.text}
              </CheckboxLabelLink>
            </CheckboxLabelLinkWrapper>
          )}
        </CheckboxLabel>
      </CheckboxWrapper>
    </CheckboxContainer>
  )
}
