import { thirdPartyCollectSources } from '@tm/client-app/src/survey/editor-v3/Canvas/Thanks/ThirdPartyCollect/sources'
import useI18N from '@tm/client-form/src/hooks/useI18n'
import { ThirdPartyCollectData, ThirdPartyCollectSource } from '@tm/types/survey/entities'
import { styled } from 'goober'
import React, { useEffect, useState } from 'react'

const ThirdPartyCollectContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5em;
`

const ThirdPartyCollectLinkContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  max-width: 600px;
`

const ThirdPartyCollectLink = styled('a')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 16px;
  gap: 8px;
  width: 137px;
  height: 136px;
  background: #f9fafb;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #636e81;
  text-decoration: none;
  transition: 0.3s background ease-in-out, 0.3s color ease-in-out;
  &:hover {
    background: #e6e6e8;
    color: #232323;
  }
`

const ThirdPartyCollectIcon = styled<{ logo: string }>('div')`
  background: ${props => `url(${props.logo})`};
  height: 50px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const ThirdPartyCollectValidationError = styled<{ errorColor?: string }>('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  height: 30px;
  background: ${props => (props.errorColor ? props.errorColor : `#E4422A`)};
  color: #ffffff;
  white-space: nowrap;
`

const AutoRedirectContainer = styled('div')`
  margin: 24px;
`

const AutoRedirectMessage = styled('div')`
  background: #f9fafb;
  padding: 24px;
  width: auto;
  border-radius: 8px;
  color: #636e81;
  position: relative;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
`

type ThirdPartyStatus = 'loading' | 'ok'

// Regular expression to match an incomplete URL with nothing or possibly just whitespace after the protocol
const justProtocolRegEx = /^https?:\/\/\s*$/

const redirectDelaySeconds = 5

const ThirdPartyCollectInnerLink = ({
  onLinkClick,
  source,
  validationErrors,
  errorColor,
  language,
}: {
  onLinkClick?: (event: React.MouseEvent) => void
  source: ThirdPartyCollectSource
  validationErrors?: Record<string, string>
  errorColor?: string
  language?: string
}) => {
  const tpcSource = thirdPartyCollectSources.find(tpcSource => tpcSource.name === source.source)
  return (
    <ThirdPartyCollectLink onClick={onLinkClick} href={source.url} rel="noreferrer" target="_blank" key={source.id}>
      {!!validationErrors && validationErrors[source.id] && (
        <ThirdPartyCollectValidationError errorColor={errorColor}>
          {validationErrors[source.id]}
        </ThirdPartyCollectValidationError>
      )}
      <ThirdPartyCollectIcon logo={tpcSource?.logo || ''} />
      {source.name && language && source.name[language]}
    </ThirdPartyCollectLink>
  )
}

export const ThirdPartyCollect = ({
  value,
  validationErrors,
  errorColor,
  onLinkClick,
  language,
  autoRedirect = false,
}: {
  value?: ThirdPartyCollectData
  validationErrors?: Record<string, string>
  errorColor?: string
  onLinkClick?: (event: React.MouseEvent) => void
  language?: string
  autoRedirect?: boolean
}) => {
  const { t } = useI18N()
  // We use status to show empty page before redirect,
  // otherwise there's a flash of content before user
  // is redirected and it's confusing
  const [status, setStatus] = useState<ThirdPartyStatus>('loading')
  const [firstSource] = value?.sources || []

  /** -1 = countdown not yet started */
  const [redirectCountdownSeconds, setRedirectCountdownSeconds] = useState(-1)

  const urlSearchParams = new URLSearchParams(location.search)
  const inPreview = urlSearchParams.get('preview')
  /**
   * Autoredirect should work only if there's one source.
   * Autoredirect is also disabled in preview mode.
   */
  const shouldAutoRedirect =
    // Form setting
    autoRedirect &&
    // Only exactly one source should be set
    value?.sources?.length === 1 &&
    // We are not in preview
    !inPreview &&
    // If the firstSourceUrl has nothing after the protocol, we don't want to redirect
    firstSource?.url &&
    !justProtocolRegEx.exec(firstSource.url)

  useEffect(() => {
    if (!shouldAutoRedirect) return setStatus('ok')

    // Skip if countdown already started
    if (redirectCountdownSeconds >= 0) return

    // Redirect after a delay

    setRedirectCountdownSeconds(redirectDelaySeconds)
    setTimeout(() => {
      window.location.href = firstSource?.url || ''
    }, redirectDelaySeconds * 1000)
    setInterval(() => {
      setRedirectCountdownSeconds(seconds => Math.max(0, seconds - 1))
    }, 1000)
  }, [firstSource?.url, shouldAutoRedirect, redirectCountdownSeconds])

  if (!value) {
    return null
  }

  if (!value.sources || (value.sources && !value.sources.length)) {
    return null
  }

  // If will be autoredirecting, display a "please wait" message for a while...
  if (shouldAutoRedirect) {
    const message = t('thirdPartyCollect.autoredirectMessage')
      .toString()
      .replace('{source}', firstSource?.source || '')
      .replace('{seconds}', redirectCountdownSeconds.toString())
    return (
      <AutoRedirectContainer>
        <AutoRedirectMessage>{message}</AutoRedirectMessage>
      </AutoRedirectContainer>
    )
  }

  if (status === 'loading') return null

  return (
    <ThirdPartyCollectContainer>
      <ThirdPartyCollectLinkContainer>
        {value.sources.map(source => {
          // We added Trustmary as source in campaigns, but handle it differently so let's skip it here
          if (source.source === 'trustmary') return null
          if (source.url && justProtocolRegEx.exec(source.url)) return null

          return (
            <ThirdPartyCollectInnerLink
              validationErrors={validationErrors}
              errorColor={errorColor}
              onLinkClick={onLinkClick}
              source={source}
              language={language}
              key={source.id}
            />
          )
        })}
      </ThirdPartyCollectLinkContainer>
    </ThirdPartyCollectContainer>
  )
}
