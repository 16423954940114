import { CustomValue } from '@tm/shared-lib/src/api'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { CampaignSubmit } from '../../CampaignSubmit'
import { CampaignGroup } from '@tm/client-form/src/components/campaign/CampaignGroup'

type NpsCommentViewProps = {
  label?: string
  inputLabel?: string
  description?: string
  value: string
  onChange: (value: CustomValue) => void
  onSubmit: () => void
  isSubmitting?: boolean
  error?: string
}

export function NpsCommentView({
  label,
  inputLabel,
  description,
  value,
  onChange,
  isSubmitting,
  error,
  onSubmit,
}: NpsCommentViewProps) {
  return (
    <>
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="body1">{description}</Typography>
      <CampaignGroup>
        <TextField
          label={inputLabel || ''}
          value={value}
          error={!!error}
          helperText={error}
          onChange={event => onChange(event.target.value)}
          multiline
          rows={4}
          variant="filled"
          disabled={isSubmitting}
          type="textarea"
        />
      </CampaignGroup>
      <CampaignSubmit onClick={onSubmit} disabled={isSubmitting} />
    </>
  )
}
