import { styled } from 'goober'
import React from 'react'
import { useTheme } from '../../hooks/useTheme'

interface Props {
  stepCount: number
  stepNum: number
  position: 'after' | 'before'
}

const ProgressBarWrapper = styled('div')(({ theme }) => theme.styles.progressBarWrapper)
const ProgressBarElement = styled('div')(({ theme }) => theme.styles.progressBar)
const ProgressBarBackground = styled('div')(({ theme }) => theme.styles.progressBarBackground)
const ProgressBarActive = styled('div')(({ theme }) => theme.styles.progressBarActive)
const ProgressBarNumbers = styled('div')(({ theme }) => theme.styles.progressBarNumbers)

export function ProgressBar({ stepCount, stepNum, position }: Props): JSX.Element | null {
  const theme = useTheme()
  if (!theme.progressBar || theme.progressBarPosition !== position || !stepCount) return null

  const showNumbers = theme.progressBarNumbers

  const width = (stepNum / (stepCount + 1)) * 100

  return (
    <ProgressBarWrapper className="s-progressbar">
      <ProgressBarElement>
        <ProgressBarBackground>
          <ProgressBarActive style={{ width: `${width}%` }} />
        </ProgressBarBackground>
        {showNumbers && <ProgressBarNumbers>{`${stepNum}/${stepCount}`}</ProgressBarNumbers>}
      </ProgressBarElement>
    </ProgressBarWrapper>
  )
}
