import { useEffect } from 'react'
import { useSurveyState } from '@tm/client-form/src/hooks/useSurveyState'
import { CustomValue } from '@tm/shared-lib/src/api'

const customValueGuard = (value: unknown): CustomValue => {
  if (Array.isArray(value))
    return value.map(subval => (typeof subval === 'string' ? subval : '')).filter(str => !!str.trim())
  if (typeof value === 'string') return value
  if (typeof value === 'number') return value
  if (typeof value === 'boolean') return value

  return null
}

/**
 * This hook is used to prefill fields from the URL hash.
 * When we send email with links to nps or stars, we encode the values in the URL hash.
 * https://form.trustmary.com/survey-id?i=invitation-id#${encodeUriComponent(JSON.stringify({stars: 5}))}
 */
export function usePrefillFields() {
  const [, dispatch] = useSurveyState()

  useEffect(() => {
    const hash = window.location.hash
    if (hash.length <= 1) return
    const json = window.location.hash.substring(1)
    try {
      const obj = JSON.parse(decodeURIComponent(json)) as Record<string, unknown>
      for (const [key, value] of Object.entries(obj)) {
        dispatch({ type: 'set value', field: { system: true, name: key }, value: customValueGuard(value) })
      }
      window.location.hash = ''
    } catch (e) {
      console.warn(`window.location.hash is not valid JSON`)
    }
  }, [dispatch])

  return {}
}
