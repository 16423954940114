import type { BaseState } from './state'

export interface ErrorState extends BaseState {
  type: 'error'
}

export const permanentError: ErrorState = {
  type: 'error',
  processAction: () => {
    return permanentError
  },
}
