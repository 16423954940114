import { LifeCycleEventType } from '@tm/types/embed/event'
import { WidgetClientResponse } from '../embed'
import { HiddenFields } from './hiddenFields'

export interface DatalayerItem {
  event?: string
  context?: {
    embed_id?: string
    conversion_id?: string
  }
  [key: string]: unknown
}

export interface ConversionItem {
  id: string
}

export interface ControlPopupItem {
  action: string
}

export type PopupControlCallback = (param: ControlPopupItem) => void

export interface InitGlobalTmary {
  q: [string, unknown][]
  app?: string
}

export type GlobalTmaryReturnType = HiddenFields | void

export type GlobalTmaryAction =
  | 'app'
  | 'widgetInit'
  | 'pageview'
  | 'dataLayer'
  | 'conversion'
  | 'popup'
  | 'popupController'
  | 'getHiddenFields'
  | 'popupOpened'
  | 'onscreen'
  | 'widgetInteraction'
  | 'formSubmit'
  | 'widgetReady'
  | 'widgetOnScreen'

export type GlobalTmaryParam =
  | string
  | ConversionItem
  | DatalayerItem
  | ControlPopupItem
  | PopupControlCallback
  | LifeCycleParams
  | WidgetReadyParams

export type GlobalTmary = (action: GlobalTmaryAction, param?: GlobalTmaryParam) => GlobalTmaryReturnType

export type LifeCycleParams = { item_id: string } & {
  event: LifeCycleEventType
}

export type WidgetReadyParams = { element: HTMLDivElement; widgetData: WidgetClientResponse }

export interface EmbedWindow extends Window {
  tmary: InitGlobalTmary | GlobalTmary
  TRUSTMARY_DISABLE_ONLOAD?: boolean
  dataLayer: DatalayerItem[]
  hasTrustmaryPopupFromTag?: boolean
}

export interface TrackEventQuery {
  event: string
  href: string
  embed_id?: string
  conversion_id?: string
}

export function tmary(action: 'app', param: string): void
export function tmary(action: 'widgetInit', param: string): void
export function tmary(action: 'pageview'): void
export function tmary(action: 'dataLayer', param: DatalayerItem): void
export function tmary(action: 'conversion', param: ConversionItem): void
export function tmary(action: 'popup', param: ControlPopupItem): void
export function tmary(action: 'popupController', param: PopupControlCallback): void
export function tmary(action: 'getHiddenFields'): HiddenFields
export function tmary(action: 'popupOpened', widgetId: string): void
export function tmary(action: 'formSubmit', widgetId: string): void
export function tmary(action: 'widgetInteraction', widgetId: string): void
export function tmary(action: 'widgetOnScreen', widgetId: string): void
export function tmary(action: 'widgetReady', param: WidgetReadyParams): void
export function tmary(action: GlobalTmaryAction, param?: GlobalTmaryParam): GlobalTmaryReturnType {
  const { tmary } = window as unknown as EmbedWindow
  if (typeof tmary === 'function') return tmary(action, param)
}

export type GlobalTmaryFunction = typeof tmary
