import { PrivacyPolicyField } from '@tm/shared-lib/src/field'
import React, { useMemo } from 'react'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyState } from '../../hooks/useSurveyState'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Checkbox } from '../field/Checkbox'

interface Props {
  field: PrivacyPolicyField
}

export function PrivacyPolicyQuestion({ field }: Props): JSX.Element {
  const { t } = useI18N()
  const [value, setValue] = useSurveyValue(field)
  const [state] = useSurveyState()
  const privacyPolicy = state.type === 'in progress' ? state.meta.privacyPolicy : ''
  const privacyPolicyOrganization = state.type === 'in progress' ? state.meta.privacyPolicyOrganization : ''

  const booleanValue = useMemo(() => {
    if (typeof value === 'string' && (value === 'true' || value === 'false' || value === '')) {
      return value === 'true'
    } else if (value === undefined || typeof value === 'boolean') {
      return value
    }
    throw new Error('PublicConsent does not support value: ' + value)
  }, [value])

  return (
    <Checkbox
      value={booleanValue}
      onValueChange={setValue}
      renderLabel={() => (
        <>
          {`${t('privacyPolicy.label')} ${privacyPolicyOrganization} `}
          {privacyPolicy ? (
            <a href={privacyPolicy} target="_blank" rel="noreferrer">
              {t('publicConsent.privacyPolicy')}
            </a>
          ) : (
            t('publicConsent.privacyPolicy')
          )}
        </>
      )}
    />
  )
}
