import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { StepValues } from '@tm/shared-lib/src/api'
import { MetaData, SocialShare } from '@tm/shared-lib/src/survey'
import { getStepValueByString } from '@tm/shared-lib/src/util'
import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../../hooks/useI18n'

const ShareButton = styled('a')({
  display: 'inline-block',
  margin: '5px',
  padding: '10px 15px',
  background: '#0F1834',
  height: '40px',
  textAlign: 'center',
  lineHeight: '20px',
  color: '#fff',
  textDecoration: 'none',
  fontSize: '14px',
  fontWeight: 500,
  'i.fab': {
    marginRight: '6px',
  },
  '&:hover': {
    opacity: 0.8,
  },
  svg: {
    marginRight: '6px',
    position: 'relative',
    top: '1px',
  },
})

const ShareButtonTwitter = styled(ShareButton)({
  background: '#56acee',
})

const ShareButtonLinkedIn = styled(ShareButton)({
  background: '#0077b5',
})

const ShareButtonGoogle = styled(ShareButton)({
  background: '#efefef',
  color: '#222937',
  'i.fab': {
    color: '#4285f4',
  },
})

const SurveyShare = styled('div')({ padding: '0 20px 40px' })
const SurveyShareTitle = styled('div')({ marginBottom: '10px', fontSize: '18px', fontWeight: 500 })
const SurveyShareButtons = styled('div')({ textAlign: 'center' })

interface Props {
  answerId?: string
  trackEvent: (type: string, data: unknown) => void
  meta: MetaData
  surveyFeatures?: string[]
  share?: SocialShare
  values: StepValues
}

export function ShareButtons(props: Props): JSX.Element | null {
  const { surveyFeatures = [], meta, trackEvent, answerId, share, values } = props

  const { publicProfile } = meta

  const { t } = useI18N()
  const publicConsent = getStepValueByString(values, 'publicConsent')
  const comment = getStepValueByString(values, 'comment') || ''
  const commentVideo = getStepValueByString(values, 'comment_video') || '' //TODO commentVideo?

  if (typeof comment !== 'string') return null
  if (typeof commentVideo !== 'string') return null
  if (publicConsent !== true) return null
  if (!publicProfile) return null

  if (!surveyFeatures.includes('socialMediaShare')) return null

  const {
    twitter = false,
    twitterHandle = false,
    linkedin = false,
    facebook = false,
    googleReview = false,
    googleReviewUrl = false,
  } = share || {}

  //googleReview = googleReview && typeof googleReviewUrl === 'string' && googleReviewUrl.length

  if (!linkedin && !twitter && !facebook && !googleReview) return null
  if (!answerId) return null

  const shareUrl = encodeURIComponent(
    `${process.env.SHARE_DOMAIN}/${t('publicProfile.prefix')}/${publicProfile}/${answerId}`
  )

  if (comment.trim() === '' && !commentVideo) return null

  return (
    <SurveyShare>
      <SurveyShareTitle>{t('share.title')}</SurveyShareTitle>
      <SurveyShareButtons>
        {twitter ? (
          <ShareButtonTwitter
            href={`https://twitter.com/intent/tweet?text=${shareUrl}${
              twitterHandle ? encodeURIComponent(' ' + twitterHandle) : ''
            }`}
            className="survey-share-button survey-share-button--twitter"
            target="_blank"
            onClick={() => trackEvent('share', { service: 'twitter' })}
            rel="noreferrer">
            <Icon icon={faTwitter} />
            {t('share.twitter')}
          </ShareButtonTwitter>
        ) : null}
        {linkedin ? (
          <ShareButtonLinkedIn
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=&summary=${comment}&source=`}
            className="survey-share-button survey-share-button--linkedin"
            target="_blank"
            onClick={() => trackEvent('share', { service: 'linkedin' })}
            rel="noreferrer">
            <Icon icon={faLinkedin} />
            {t('share.linkedin')}
          </ShareButtonLinkedIn>
        ) : null}
        {facebook ? (
          <ShareButton
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
            className="survey-share-button survey-share-button--facebook"
            target="_blank"
            onClick={() => trackEvent('share', { service: 'facebook' })}
            rel="noreferrer">
            <Icon icon={faFacebook} />
            {t('share.facebook')}
          </ShareButton>
        ) : null}
        {googleReview && googleReviewUrl ? (
          <ShareButtonGoogle
            href={googleReviewUrl}
            className="survey-share-button survey-share-button--google"
            target="_blank"
            onClick={() => trackEvent('share', { service: 'googleReview' })}
            rel="noreferrer">
            <Icon icon={faGoogle} />
            {t('share.googleReview')}
          </ShareButtonGoogle>
        ) : null}
      </SurveyShareButtons>
    </SurveyShare>
  )
}
