import { Theme } from '@tm/shared-lib/src/theme'
import { CSSAttribute } from 'goober'
import { createContext, useContext } from 'react'

export interface ThemeColors {
  main: string
  dark: string
  light: string
  alpha: string
}

export interface ThemeWithColors extends Required<Theme>, ThemeColors {}

export const defaultTheme: Required<Theme> = {
  style: 'color',
  pageBackgroundColor: '#f4f4f4',
  pageBackgroundColorType: 'light',
  pageBackgroundColorDark: 'hsl(0, 0%, 81%)',
  pageBackgroundColorLight: 'hsl(0, 0%, 96%)',
  pageBackgroundColorAlpha: 'hsla(0, 0%, 96%, 0.5)',
  primaryColor: '#0d3b70',
  primaryColorType: 'dark',
  primaryColorDark: 'hsl(212, 79%, 10%)',
  primaryColorLight: 'hsl(212, 79%, 25%)',
  primaryColorAlpha: 'hsla(212, 79%, 25%, 0.5)',
  highlightColor: '#54d62b',
  highlightColorType: 'light',
  highlightColorDark: 'hsl(170, 56%, 37%)',
  highlightColorLight: 'hsl(170, 56%, 52%)',
  highlightColorAlpha: 'hsla(170, 56%, 52%, 0.5)',
  fontSource: 'system',
  fontFamily: 'inherit',
  font: '',
  progressBar: false,
  progressBarNumbers: false,
  progressBarPosition: 'after',
  view: 'page',
}

/*export function getThemeVar(key: keyof Theme, overrideValue?: string | Theme): string | boolean {
  if (overrideValue) {
    if (typeof overrideValue === 'string') return overrideValue
    const res = overrideValue[key]
    return typeof res === 'string' ? res : defaultTheme[key]
    //return typeof overrideValue === 'string' ? overrideValue : overrideValue[key] ? overrideValue[key] : defaultTheme[key]
  } else {
    return defaultTheme[key]
  }
}*/

export function processFont(theme?: Theme): { source: string; family: string | false } {
  if (theme?.font) {
    const [source, family = false] = theme.font.split(':')
    return {
      source,
      family,
    }
  } else {
    return {
      source: 'system',
      family: false,
    }
  }
}

export function loadGoogleFont(family: string): void {
  try {
    let link = document.getElementById('googe-font') as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.id = 'google-font'
      link.rel = 'stylesheet'
      document.head.appendChild(link)
    }

    link.href = `https://fonts.googleapis.com/css2?family=${family.split(' ').join('+')}:wght@400;700&display=swap`
  } catch (err) {
    console.log(err)
  }
}

export function createTheme(customTheme: Theme = {}): ThemeWithColors {
  //const themeVar = (key: keyof Theme) => getThemeVar(key, customTheme)

  const customizedTheme = { ...defaultTheme, ...customTheme }

  const themeColors: ThemeColors =
    customizedTheme.primaryColor === defaultTheme.primaryColor
      ? {
          // If color is the default color, use highlight color instead for continuity
          main: customizedTheme.highlightColor,
          dark: customizedTheme.highlightColorDark,
          light: customizedTheme.highlightColorLight,
          alpha: customizedTheme.highlightColorAlpha,
        }
      : {
          main: customizedTheme.primaryColor,
          dark: customizedTheme.primaryColorDark,
          light: customizedTheme.primaryColorLight,
          alpha: customizedTheme.primaryColorAlpha,
        }

  return { ...customizedTheme, ...themeColors }
}

export const defaultStyles = {
  form: {},
  formInner: {},

  /* Fieldset */
  fieldset: {},
  fieldsetFields: {},

  /* Field */
  fieldWrapper: {},
  fieldLabel: {},
  fieldLabelRequired: {},
  fieldDescription: {},
  fieldErrorsWrapper: {},
  fieldError: {},

  /* Inputs */
  input: {},
  textarea: {},

  /* Checkbox */
  checkboxContainer: {},
  checkboxContainerFootnote: {},
  checkboxWrapper: {},
  checkbox: {},
  checkboxIcon: {},
  checkboxIconSvg: {},
  checkboxLabel: {},
  checkboxLabelRequired: {},
  checkboxLabelLinkWrapper: {},
  checkboxLabelLink: {},

  /* Radio */
  radioButton: {},
  radioIconWrapper: {},
  radioIcon: {},
  radioIconSvg: {},
  radioLabel: {},

  /* File */
  fileWrapper: {},
  fileUploadWrapper: {},
  fileUploadButton: {},
  fileUploadIcon: {},
  fileUploadText: {},
  fileUploadInput: {},
  fileItem: {},
  fileItemPreview: {},
  fileItemPreviewImage: {},
  fileItemName: {},
  fileItemNameInner: {},
  fileItemRemove: {},
  fileItemRemoveButton: {},

  /* Range */
  rangeArea: {},
  rangeAreaFolded: {},
  rangeWrapper: {},
  rangeWrapperFolded: {},
  rangeInner: {},
  rangeInnerFolded: {},
  rangeItem: {},
  rangeItemFolded: {},
  rangeItemButton: {},
  rangeItemButtonFolded: {},
  rangeItemMobile: {},
  rangeItemButtonMobile: {},
  rangeLabels: {},
  rangeLabelsFolded: {},
  rangeLabelsMin: {},
  rangeLabelsMax: {},
  rangeNoOpinion: {},
  rangeNoOpinionFolded: {},
  rangeNoOpinionItem: {},
  rangeNoOpinionItemIcon: {},
  rangeNoOpinionLabel: {},
  rangeNoOpinionLabelFolded: {},
  rangeNoOpinionLabelText: {},
  rangeMobileLabel: {},

  /* Stars */
  starsWrapper: {},
  stars: {},
  starsItem: {},
  starsItemIcon: {},
  starsLabels: {},
  starsLabelsItem: {},

  /* Submit */
  submitWrapper: {},
  submitButton: {},
  submitButtonIcon: {},

  /* Progress bar */
  progressBarWrapper: {},
  progressBar: {},
  progressBarBackground: {},
  progressBarActive: {},
  progressBarNumbers: {},

  /* Chatbot */
  chatbotLabelWrapper: {},
  chatbotLabelRow: {},
  chatbotLabelAfterRow: {},
  chatbotLabel: {},
  chatbotLabelText: {},
  chatbotLabelDescriptionText: {},
  chatbotAvatar: {},

  chatbotField: {},

  chatbotThreeDots: {},

  chatbotDefaultInputParent: {},
  chatbotDefaultInputWrapper: {},

  chatbotRadiosWrapper: {},
  chatbotRadio: {},

  chatbotTextPreviewWrapper: {},
  chatbotTextPreview: {},
  chatbotTextPreviewLabel: {},
  chatbotTextInputParent: {},
  chatbotTextInputWrapper: {},

  /* Dropdown */
  dropdownSelect: {},
}

type MapAttributes<Type> = {
  [Properties in keyof Type]: CSSAttribute
}

export type Styles = MapAttributes<typeof defaultStyles>
export type PartialStyles = Partial<Styles>

export function createStyles(styles: PartialStyles): Styles {
  return { ...defaultStyles, ...styles }
}

export interface StylesContextValue {
  values:
    | ThemeWithColors
    | {
        [key: string]: string
      }
  styles: Styles
}

export const StylesContext = createContext<StylesContextValue>({
  values: {},
  styles: defaultStyles,
})

export function useStyles(): StylesContextValue {
  return useContext(StylesContext)
}
