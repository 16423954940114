import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone'
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons/faMicrophoneSlash'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons/faVideoSlash'
import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React, { useState } from 'react'
import { useI18N } from '../../../hooks/useI18n'
import { AnchoredMenu } from './Menu'
import { StartRecordingPreviewButton } from './VideoButtons'

const TOOLBAR_BUTTON_HEIGHT = '40px'
const TOOLBAR_BUTTON_HEIGHT_MOBILE = '26px'

const VideoToolbarContent = styled('div')({
  position: 'absolute',
  width: '100%',
  top: 0,
  gap: '8px',
  padding: 'min(3%, 25px) min(2%, 25px) 0',
  zIndex: 2,
  display: 'flex',
  flexWrap: 'wrap',
})

const ToolbarButton = styled('button')({
  height: TOOLBAR_BUTTON_HEIGHT,
  '@media (max-width: 900px)': {
    height: TOOLBAR_BUTTON_HEIGHT_MOBILE,
  },
})

export const TransparentButton = styled(ToolbarButton)({
  //height: '40px',
  color: 'white',
  backdropFilter: 'blur(10.814px)',
  backgroundColor: 'rgba(79, 79, 79, 0.3)',
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: 'none',
  '&:hover': {
    backgroundColor: 'rgba(79, 79, 79, 0.85)',
  },
})

export const TransparentCircleButton = styled(TransparentButton)({
  width: TOOLBAR_BUTTON_HEIGHT,
  borderRadius: '20px',
  fontSize: '14px',
  padding: 0,
  justifyContent: 'center',
  '@media (max-width: 900px)': {
    width: TOOLBAR_BUTTON_HEIGHT_MOBILE,
    fontSize: '11px',
  },
})

const DeviceButton = styled(TransparentButton)({
  height: '40px',
  gap: '8px',
  padding: '10px 17px',
  borderRadius: '27.5px',
  position: 'relative',
  '@media (max-width: 900px)': {
    height: '26px',
  },
})

const DeviceIcon = styled('div')({
  flex: '0 0 auto',
  fontSize: '12px',
  '@media (max-width: 900px)': {
    fontSize: '8px',
  },
})

const DeviceText = styled('div')({
  flex: '0 0 auto',
  fontWeight: 'normal',
  textAlign: 'left',
  letterSpacing: '-0.025em',
  maxWidth: '100px',
  whiteSpace: 'nowrap',
  '@media (max-width: 900px)': {
    fontSize: '6px',
    maxWidth: '70px',
  },
  '@media (max-width: 500px)': {
    display: 'none',
  },
})

const DeviceType = styled('div')({
  fontStyle: 'normal',
  fontSize: '12px',
  '@media (max-width: 900px)': {
    fontSize: '8px',
  },
})

const DeviceName = styled('div')({
  fontSize: '8px',
  fontStyle: 'italic',
  //width: '100px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '@media (max-width: 900px)': {
    fontSize: '6px',
  },
})

const UploadInput = styled('input')({
  display: 'none',
})

const Separator = styled('span')({
  borderWidth: '0.5px',
  borderStyle: 'solid',
  opacity: 0.25,
  width: '0px',
  height: '22px',
})

const SeparatorDark = styled(Separator)({
  borderColor: '#FFFFFF',
  '@media (max-width: 500px)': {
    display: 'none',
  },
})

const SeparatorLight = styled(Separator)({
  borderColor: '#0C0C0C',
})

const UploadLabel = styled('label')({
  cursor: 'pointer',
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px 17px',
  gap: '8px',
  verticalAlign: 'middle',
  fontSize: '12px',
  letterSpacing: '-0.03em',
  boxSizing: 'border-box',
  border: '0.5px solid #C2C2C2',
  borderRadius: '5px',
  color: '#000000',
  height: TOOLBAR_BUTTON_HEIGHT,
  backdropFilter: 'blur(10.814px)',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F1F1F1',
  },
  '@media (max-width: 900px)': {
    height: TOOLBAR_BUTTON_HEIGHT_MOBILE,
    gap: '6px',
    fontSize: '9px',
    padding: '10px 10px',
  },
})

const UploadButtonIcon = styled('span')({
  color: '#163429',
})

type UploadProps = Pick<React.ComponentPropsWithoutRef<'input'>, 'accept' | 'capture'> & {
  onUpload: (file: File) => void
}

const UploadButton = ({ onUpload, accept = 'video/mp4,video/x-m4v,video/*' }: UploadProps): JSX.Element => {
  const { t } = useI18N()
  return (
    <UploadLabel>
      <UploadInput
        type="file"
        accept={accept}
        onChange={e => {
          e.preventDefault()
          if (e.target.files && e.target.files.length === 1) {
            const file = e.target.files[0]
            onUpload(file)
          }
        }}
        multiple={false}
      />
      <UploadButtonIcon>
        <Icon icon={faUpload} />
      </UploadButtonIcon>
      <SeparatorLight />
      {t('comment.video.uploadButton')}
    </UploadLabel>
  )
}

const TimerDiv = styled('div')({
  backgroundColor: '#FFFFFF',
  border: '0.5px solid #C2C2C2',
  backdropFilter: 'blur(10.814px)',
  padding: '0 18px',
  borderRadius: '20px',
  display: 'inline-block',
  //loat: 'right',
  lineHeight: '40px',
  textAlign: 'center',
  fontFamily: 'monospace',
  fontWeight: 600,
  fontSize: '14px',
  '@media (max-width: 900px)': {
    padding: '0 12px',
    fontSize: '8px',
    lineHeight: '26px',
  },
})

const MenuParent = styled('div')({ position: 'relative' })

const RecordingHelpMenu = styled(AnchoredMenu)({
  backgroundColor: '#2E2E2E',
  color: 'white',
  borderRadius: '25px 3px 25px 25px',
  fontSize: '12px',
  lineHeight: '15px',
  padding: '20px',
})

const RecordingHelpMenuTitle = styled('h1')({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  margin: 0,
})

const RecordingHelpMenuList = styled('ol')({
  paddingInlineStart: '25px',
  margin: 0,
})

const FlexGroup = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexWrap: 'wrap',
  gap: 'inherit',
  flexDirection: 'inherit',
  alignItems: 'inherit',
  justifyContent: 'flex-end',
})

interface SelectDeviceProps extends React.ComponentPropsWithoutRef<'button'> {
  icon: IconDefinition
  deviceType: string
  deviceName?: string
}

const SelectDeviceButton = ({ icon, deviceType, deviceName, ...rest }: SelectDeviceProps) => {
  return (
    <DeviceButton {...rest}>
      <DeviceIcon>
        <Icon icon={icon} />
      </DeviceIcon>
      <SeparatorDark />
      <DeviceText>
        <DeviceType>{deviceType}</DeviceType>
        <DeviceName>{deviceName}</DeviceName>
      </DeviceText>
    </DeviceButton>
  )
}

const SelectCameraButton = (
  props: React.ComponentPropsWithoutRef<'button'> & { selectedCamera?: MediaDeviceInfo }
): JSX.Element => {
  const { selectedCamera, ...rest } = props
  const { t } = useI18N()
  return (
    <SelectDeviceButton
      icon={selectedCamera ? faVideo : faVideoSlash}
      deviceType={selectedCamera ? t('video.selectCamera') : t('video2.deviceButton.noAccess')}
      deviceName={selectedCamera?.label}
      {...rest}
    />
  )
}

const SelectMicrophoneButton = (
  props: React.ComponentPropsWithoutRef<'button'> & { selectedMicrophone?: MediaDeviceInfo }
): JSX.Element => {
  const { selectedMicrophone, ...rest } = props
  const { t } = useI18N()
  return (
    <SelectDeviceButton
      icon={selectedMicrophone ? faMicrophone : faMicrophoneSlash}
      deviceType={selectedMicrophone ? t('video.selectMicrophone') : t('video2.deviceButton.noAccess')}
      deviceName={selectedMicrophone?.label}
      {...rest}
    />
  )
}

const zeroPrefix = (int: number, minLength = 2): string => {
  let str = int.toString()
  while (str.length < minLength) str = '0' + str
  return str
}

export const VideoToolbar = (props: {
  onUpload: (file: File) => void
  //onRequestFullscreen?: () => void
  onCameraClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onMicrophoneClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  timer?: number
  selectedCamera?: MediaDeviceInfo
  selectedMicrophone?: MediaDeviceInfo
  className?: string
}): JSX.Element => {
  const {
    onUpload,
    //onRequestFullscreen,
    onCameraClick,
    onMicrophoneClick,
    timer,
    selectedCamera,
    selectedMicrophone,
    className,
  } = props

  const [infoMenuAchorEl, setInfoMenuAnchorEl] = useState<HTMLElement>()
  const { t } = useI18N()
  const parts = t('video2.infoMenu.recordList.2').split('�')
  return (
    <VideoToolbarContent className={className}>
      <SelectCameraButton
        disabled={!onCameraClick}
        selectedCamera={selectedCamera}
        onClick={e => {
          e.preventDefault()
          if (onCameraClick) onCameraClick(e)
        }}
      />
      <SelectMicrophoneButton
        disabled={!onMicrophoneClick}
        selectedMicrophone={selectedMicrophone}
        onClick={e => {
          e.preventDefault()
          if (onMicrophoneClick) onMicrophoneClick(e)
        }}
      />
      <FlexGroup>
        {timer === undefined ? (
          <UploadButton onUpload={onUpload} />
        ) : (
          <TimerDiv>
            {zeroPrefix(Math.floor(timer / 60000))}:{zeroPrefix(Math.floor((timer / 1000) % 60))}
          </TimerDiv>
        )}

        <TransparentCircleButton
          onClick={e => {
            e.preventDefault()
            setInfoMenuAnchorEl(infoMenuAchorEl ? undefined : e.currentTarget)
          }}>
          <div>
            <Icon icon={faQuestion} />
          </div>
        </TransparentCircleButton>
      </FlexGroup>
      {infoMenuAchorEl && (
        <RecordingHelpMenu
          anchorEl={infoMenuAchorEl}
          offset={{ y: 5 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformDirection={{ horizontal: 'left', vertical: 'down' }}
          onClickAway={() => {
            setInfoMenuAnchorEl(undefined)
          }}>
          <>
            <RecordingHelpMenuTitle>{t('video2.infoMenu.recordTitle')}</RecordingHelpMenuTitle>
            <RecordingHelpMenuList>
              <li>{t('video2.infoMenu.recordList.1')}</li>
              <li>
                {parts[0]}
                <StartRecordingPreviewButton disabled={true} />
                {parts[1]}
              </li>
            </RecordingHelpMenuList>
            <hr />
            <RecordingHelpMenuTitle>{t('video2.infoMenu.uploadTitle')}</RecordingHelpMenuTitle>
            <RecordingHelpMenuList>
              <li>
                {t('video2.infoMenu.uploadList.1')}
                &nbsp;
                <Icon icon={faUpload} />
              </li>
              <li>{t('video2.infoMenu.uploadList.2')}</li>
            </RecordingHelpMenuList>
          </>
        </RecordingHelpMenu>
      )}
    </VideoToolbarContent>
  )
}
