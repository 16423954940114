import React from 'react'

interface IconProps {
  fill?: string
  className?: string
}

export const CheckIcon = ({ fill = '#fff', ...rest }: IconProps): JSX.Element => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9.475 2.28-5.7 6.842L0 5.347l1.414-1.414L3.64 6.16 7.939 1l1.536 1.28Z"
        fill={fill}
      />
    </svg>
  )
}

export const DotIcon = ({ fill = '#fff', ...rest }: IconProps): JSX.Element => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...rest}>
    <circle cx={5} cy={5} r={3} fill={fill} />
  </svg>
)
