import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { faExclamationTriangle } from '../../misc/icon'

const ErrorWrapper = styled('div')<{ absolute: boolean }>(({ absolute }) => ({
  padding: '10px',
  ...(absolute
    ? {
        position: 'absolute',
        top: 0,
      }
    : {}),
}))

const ErrorMessage = styled('div')({
  padding: '10px',
  background: '#db2828',
  color: '#fff',
  boxShadow: 'inset 0 -1px 0 rgba(0, 0, 0, .1)',
  fontSize: '14px',
  fontWeight: 500,
  maxWidth: '780px',
  margin: '0 auto',
  borderRadius: '3px',
  svg: {
    marginRight: '6px',
    fontSize: '16px',
    opacity: '.6',
  },
})

interface Props {
  code?: string
  className?: string
  absolute?: boolean
}

export function UnexpectedError({ code = 'error', className, absolute = false }: Props): JSX.Element {
  const { t } = useI18N()
  const classNames = ['survey-error']
  if (className) classNames.push(className)
  return (
    <ErrorWrapper className={classNames.join(' ')} absolute={absolute}>
      <ErrorMessage className="survey-error-message">
        <Icon icon={faExclamationTriangle} />
        {t(code)}
      </ErrorMessage>
    </ErrorWrapper>
  )
}
