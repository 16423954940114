import Button from '@mui/material/Button'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { Icon } from '@trustmary/icons'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useState, MouseEventHandler } from 'react'

export function VideoHelp() {
  const { t } = useI18N()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const parts = t('video2.infoMenu.recordList.2').split('�')

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="inherit"
        startIcon={<Icon icon={faLifeRing} />}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          '.MuiButton-startIcon>*:nth-of-type(1)': {
            fontSize: 14,
            color: 'text.secondary',
          },
        }}
        onClick={handleClick}>
        {t('video2.help')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <Stack component="li" px={2} py={1} gap={1} maxWidth={300}>
          <Typography variant="subtitle2">{t('video2.infoMenu.recordTitle')}</Typography>

          <Typography variant="body2" color="text.secondary">
            {t('video2.infoMenu.recordList.1')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`${parts[0]} `}
            <Box
              component="span"
              sx={{
                bgcolor: 'error.main',
                width: 16,
                height: 16,
                borderRadius: '50%',
                display: 'inline-block',
                border: '2px solid #fff',
                boxShadow: '0 0 0 1px rgba(0,0,0,0.2)',
                transform: 'translateY(3px)',
              }}
            />
            {` ${parts[1]}`}
          </Typography>

          <Divider />
          <Typography variant="subtitle2">{t('video2.infoMenu.uploadTitle')}</Typography>

          <Typography variant="body2" color="text.secondary">
            {t('video2.infoMenu.uploadList.1')}
            &nbsp;
            <Icon icon={faUpload} />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('video2.infoMenu.uploadList.2')}
          </Typography>
        </Stack>
      </Menu>
    </>
  )
}
