import { InputProps, stringValue } from './types'
import { TextAreaField, CommentField } from '@tm/shared-lib/src/field'
import TextField from '@mui/material/TextField'
import { getTranslatedValue } from './getTranslatedValue'

export function TextAreaInput(props: InputProps<TextAreaField | CommentField>) {
  const { field, value, onChange, isSubmitting, lang } = props
  const label = getTranslatedValue(field.label, lang)
  const placeholder = 'placeholder' in field ? getTranslatedValue(field.placeholder, lang) : ''

  return (
    <TextField
      value={stringValue(value)}
      onChange={event => onChange(event.target.value)}
      disabled={isSubmitting}
      multiline
      rows={'rows' in field ? field.rows || 4 : 4}
      fullWidth
      variant="filled"
      key={`f-${field.name}`}
      id={`f-${field.name}`}
      hiddenLabel
      placeholder={placeholder || label}
    />
  )
}
