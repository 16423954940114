import { NpsField } from '@tm/shared-lib/src/field'
import React from 'react'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Range } from '../field/Range'

interface Props {
  field: NpsField
  //defaultLabels?: boolean
  //theme: Theme
}

export function NpsQuestion({ field }: Props): JSX.Element {
  const [value, setValue] = useSurveyValue(field)
  if (value !== null && value !== undefined && typeof value !== 'string' && typeof value !== 'number') {
    throw new Error('NPS does not support value: ' + value)
  }
  return <Range value={value} onValueChange={setValue} {...field} rangeMin={0} rangeMax={10} defaultLabels={true} />
}
