function isRecord(obj: unknown): obj is Record<string, string> {
  if (!obj) return false
  if (typeof obj !== 'object') return false

  if (Array.isArray(obj)) return false

  if (Object.getOwnPropertySymbols(obj).length > 0) return false

  return Object.values(obj).every(value => typeof value === 'string')
}

/**
 * Will validate that item is translatable and
 * returns the translated value of the item
 */
export const getTranslatedValue = (item: unknown, lang: string) => {
  if (!isRecord(item)) return ''
  return item[lang] || ''
}
