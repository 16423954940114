import { InputProps, numberValue } from './types'
import { NpsField } from '@tm/shared-lib/src/field'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import { CustomValue } from '@tm/shared-lib/src/api'
import { useCampaign } from '@tm/client-form/src/hooks/useCampaign'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

type NpsInnerProps = {
  value?: number
  onChange?: (value: CustomValue) => void
  disabled?: boolean
}

const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export function NpsInputInner({ value, onChange, disabled }: NpsInnerProps) {
  const { t } = useI18N()
  const { primaryColor, primaryTextColor } = useCampaign()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack gap={2} width="100%" mx="auto">
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={{
          xs: 1,
          sm: 0.5,
        }}
        justifyContent={{
          xs: 'center',
          sm: 'space-between',
        }}>
        {options.map(option => {
          const isSelected = option === value
          return (
            <Button
              key={option}
              onClick={() => onChange && onChange(option)}
              color={isSelected ? 'primary' : 'inherit'}
              variant="contained"
              disabled={disabled}
              sx={{
                minWidth: {
                  xs: '36px',
                  sm: '40px',
                },
                height: {
                  xs: '36px',
                  sm: '40px',
                },
                px: 0,
                textAlign: 'center',
                ...(isSelected && {
                  backgroundColor: primaryColor,
                  color: primaryTextColor,
                  '&:hover': {
                    backgroundColor: primaryColor,
                  },
                  '&:active': {
                    backgroundColor: primaryColor,
                  },
                }),
              }}>
              {option}
            </Button>
          )
        })}
      </Stack>
      <Stack direction="row" justifyContent={{ xs: 'center', sm: 'space-between' }}>
        {isSmall ? (
          <Typography variant="body2" color="text.secondary" textAlign="center">
            {`0 = ${t('nps.label0')}, 10 = ${t('nps.label10')}`}
          </Typography>
        ) : (
          <>
            <Typography variant="body2" color="text.secondary">
              {t('nps.label0')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('nps.label10')}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export function NpsInput(props: InputProps<NpsField>) {
  const { value, onChange, isSubmitting } = props

  return <NpsInputInner value={numberValue(value)} onChange={onChange} disabled={isSubmitting} />
}
