import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { InProgressState } from '../../hooks/useSurvey/states/inProgress'
import { useSurveyState } from '../../hooks/useSurveyState'
import { useTheme } from '../../hooks/useTheme'

const FooterDiv = styled('div')<{ $frame: boolean }>(({ $frame }) =>
  $frame
    ? { display: 'none' }
    : {
        textAlign: 'center',
        padding: '30px 30px 20px',
        fontFamily:
          '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
      }
)

const Inner = styled('div')({
  '@media screen and (min-width: 600px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const FooterText = styled('div')({
  color: 'rgba(0, 0, 0, .6)',
  fontWeight: 500,
  fontSize: '14px',
  padding: '0 0 6px',
  '@media screen and (min-width: 600px)': {
    padding: '0 8px 0 0',
    position: 'relative',
    top: '-2px',
  },
  '.page-footer--dark &': {
    color: 'rgba(255, 255, 255, .6)',
  },
})

const Logo = styled('img')({
  maxWidth: '120px',
  height: '22px',
  '.page-footer--light &': {
    opacity: 0.5,
  },
})

const PoweredByLink = styled('a')({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const FooterInfo = styled('div')({
  marginTop: '10px',
  fontSize: '12px',
  lineHeight: '18px',

  color: 'rgba(0, 0, 0, .4)',
  '.page-footer--dark &': {
    color: 'rgba(255, 255, 255, .4)',
  },
})

const FooterInfoLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: 'inherit',
    textDecoration: 'underline',
  },
})

export function Footer(): JSX.Element {
  const theme = useTheme()
  const { t } = useI18N()
  const [state] = useSurveyState()
  const backgroundType = theme.pageBackgroundColorType
  const logo =
    backgroundType === 'light'
      ? 'https://d2nce6johdc51d.cloudfront.net/img/tm-badge-black.svg'
      : 'https://d2nce6johdc51d.cloudfront.net/img/tm-badge.svg'

  if ((state as InProgressState).meta?.useDynamicBrandingTag) {
    const surveyType = (state as InProgressState).meta.surveyType || 'feedback'
    return (
      <FooterDiv $frame={theme.view === 'frame'} className={`page-footer page-footer--${backgroundType}`}>
        <Inner className="page-footer-inner">
          <FooterText className="page-footer-text">
            <PoweredByLink href={t(`footer.poweredByLinkUrl.${surveyType}`)} target="_blank" rel="noreferrer">
              {t(`footer.poweredByLinkText.${surveyType}`)}
            </PoweredByLink>
            {' by Trustmary'}
          </FooterText>
        </Inner>
        <FooterInfo className="page-footer-info">
          <FooterInfoLink href={t(`footer.poweredByLinkUrl.${surveyType}`)} target="_blank" rel="noreferrer">
            {t(`footer.poweredByLink.secondaryText.${surveyType}`)}
          </FooterInfoLink>
        </FooterInfo>
      </FooterDiv>
    )
  }

  return (
    <FooterDiv $frame={theme.view === 'frame'} className={`page-footer page-footer--${backgroundType}`}>
      <Inner className="page-footer-inner">
        <FooterText className="page-footer-text">{t('footer.providedBy', 'Provided by')}</FooterText>
        <div className="page-footer-logo">
          <a
            href="https://www.trustmary.com/?utm_source=trustmary-survey&utm_medium=survey-bottom-logo-link&utm_campaign=loop"
            target="_blank"
            rel="noreferrer">
            <Logo src={logo} alt="Trustmary" />
          </a>
        </div>
      </Inner>
      <FooterInfo className="page-footer-info">
        <FooterInfoLink
          href="https://www.trustmary.com/feedback-forms/?utm_source=trustmary-survey&utm_medium=survey-bottom-link&utm_campaign=loop"
          target="_blank"
          rel="noreferrer">
          {t('footer.createSurveyLink', 'Create your free review or feedback survey in a minute')}
        </FooterInfoLink>
      </FooterInfo>
    </FooterDiv>
  )
}
