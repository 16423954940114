import { useTitle } from '@tm/client-form/src/hooks/useTitle'
import { ViewProps } from '../types'
import { ContactInfoView } from './ContactInfoView'
import { useContactInfo } from './useContactInfo'
import { usePlatformFields } from './usePlatformFields'
import { useReviewFields } from './useReviewFields'
import { PlatformView } from './PlatformView'

const truncate = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str
  return str.slice(0, maxLength - 3) + '...'
}

/**
 * Helper hook to get html title from current review view
 */
function useReviewTitle(props: {
  contactInfoProps: ReturnType<typeof useContactInfo>
  platformFields: ReturnType<typeof usePlatformFields>
}) {
  const getTitle = () => {
    if (props.platformFields.reviewState === 'choose platform')
      return truncate(props.platformFields.sourcesLabel || '', 40)

    if (props.contactInfoProps.textReviewProps.hasTextReview) return props.contactInfoProps.textReviewProps.label || ''
    if (props.contactInfoProps.videoReviewProps.hasVideoReview)
      return props.contactInfoProps.videoReviewProps.label || ''

    return props.contactInfoProps.title || ''
  }

  useTitle(getTitle())
}

export function ReviewLoader(props: ViewProps) {
  const { state } = props
  const { reviewFields, contactInfoLabel } = useReviewFields(state.step.fields || [])
  const platformFields = usePlatformFields(reviewFields, state.lang)

  const contactInfoProps = useContactInfo(props, reviewFields, contactInfoLabel)

  useReviewTitle({
    contactInfoProps,
    platformFields,
  })

  switch (platformFields.reviewState) {
    case 'choose platform':
      return <PlatformView {...platformFields} />

    default:
      return <ContactInfoView {...contactInfoProps} />
  }
}
