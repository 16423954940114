import { Field } from '@tm/shared-lib/src/field'
import { useCallback, useEffect, useState } from 'react'
import { useSurveyValue } from '@tm/client-form/src/hooks/useSurveyValue'
import { useTitle } from '@tm/client-form/src/hooks/useTitle'
import { ViewProps } from '../types'
import { StarQuestionView } from './StarQuestionView'

/**
 * Star question loader will check how StarQuestionView should be rendered
 * and updates Survey context accordingly
 */

type StarQuestionLoaderInnerProps = {
  field: Field
  lang: string
  submitForm: () => void
  isSubmitting: boolean
}

function StarQuestionLoaderInner({ field, lang, submitForm, isSubmitting }: StarQuestionLoaderInnerProps) {
  const [value, setValue] = useSurveyValue(field)
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [showSubmit, setShowSubmit] = useState(false)
  const label = 'label' in field && field.label ? field.label[lang] : undefined
  useTitle(label)

  const onStarsChange = useCallback(
    (nextValue: number | null) => {
      setValue(nextValue)
      if (showSubmit) return
      setShouldSubmit(true)
    },
    [setValue, showSubmit]
  )

  /**
   * Submit form if value is set and user has given a value
   * With immer it's hard to set value and submit same time,
   * so we listen when value is set and then submit.
   *
   * We use shouldSubmit to wait user to interact with the form,
   * otherwise when user returns to form, this would submit form
   * immediately
   */
  useEffect(() => {
    if (shouldSubmit && typeof value === 'number') {
      submitForm()
    } else if (typeof value === 'number') {
      // Is user lands with value, we want to show submit button to let user know how to go forward
      setShowSubmit(true)
    }
  }, [value, submitForm, shouldSubmit])

  // useEffect(() => {
  //   setValue(5)
  //   setShouldSubmit(true)
  // }, [])

  return (
    <StarQuestionView
      value={typeof value === 'number' ? value : undefined}
      label={label}
      onChange={onStarsChange}
      name={field.name}
      disabled={isSubmitting}
      showSubmit={showSubmit}
      onSubmit={() => setShouldSubmit(true)}
    />
  )
}

export function StarQuestionLoader({ state, submitForm, isSubmitting }: ViewProps) {
  const fields = state.step.fields || []
  const starsField = fields.find(field => field.type === 'stars')
  if (!starsField) return null
  return (
    <StarQuestionLoaderInner field={starsField} lang={state.lang} submitForm={submitForm} isSubmitting={isSubmitting} />
  )
}
