import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { VideoUpload } from './VideoUpload'
import { VideoHelp } from './VideoHelp'

export function VideoFooter() {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        gap: 2,
        px: {
          xs: 2,
          sm: 0,
        },
      }}>
      <VideoHelp />
      <Box
        sx={{
          flexGrow: 1,
        }}
      />
      <VideoUpload />
    </Stack>
  )
}
