import { StepValuesIndex } from './api'
import { Field } from './field'
import { getStepValueByField } from './util'

export interface ValidationErrors {
  [key: string]: string[]
}

const isEmail = (str: string): boolean => {
  if (typeof str !== 'string') return false

  const regex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  return regex.test(str.toLowerCase())
}

export function validateFieldValue(field: Field, values: StepValuesIndex): ValidationErrors {
  if (!field.validation?.length) return {}

  const errors: ValidationErrors = {}

  const setError = (name: string, error: string) => {
    errors[name] = errors[name] || []
    if (errors[name].indexOf(error) === -1) errors[name].push(error)
  }

  const [path, value] = getStepValueByField(values, field)

  for (const rule of field.validation) {
    const [type, attr] = rule.split(':')

    switch (type) {
      case 'required': {
        if (!value && value !== 0) setError(path, 'validation.required')
        else if (Array.isArray(value) && !value.length) setError(path, 'validation.required')
        break
      }
      case 'max': {
        if (typeof value === 'string' && value.length > parseInt(attr)) setError(path, 'validation.max')
        break
      }
      case 'email': {
        if (typeof value === 'string' && !isEmail(value)) setError(path, 'validation.email')
        break
      }
    }
  }

  return errors
}

export function validateFieldsValues(fields: Field[], values: StepValuesIndex): ValidationErrors {
  let errors: ValidationErrors = {}

  for (const field of fields) {
    if (field.type === 'fieldset' && field.fields?.length) {
      const errs = validateFieldsValues(field.fields, values)
      errors = { ...errors, ...errs }
    } else {
      const errs = validateFieldValue(field, values)
      errors = { ...errors, ...errs }
    }
  }

  return errors
}
