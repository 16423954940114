import Stack from '@mui/material/Stack'
import { useCampaign } from '../../hooks/useCampaign'
import { useFieldsToDisplay } from '../../hooks/useFieldsToDisplay'
import { useI18N } from '../../hooks/useI18n'
import { InProgressState } from '../../hooks/useSurvey/states/inProgress'
import { useSurveyState } from '../../hooks/useSurveyState'
import { usePrefillFields } from './usePrefillFields'

import { useEffect, useMemo } from 'react'
import { PassProps, SurveyForm } from '../survey/SurveyForm'
import { FeedbackLoader } from './view/Feedback'
import { ReviewLoader } from './view/Review'
import { StarQuestionLoader } from './view/StarQuestion'
import { ThanksLoader } from './view/Thanks'
import { NpsQuestionLoader } from './view/NpsQuestion'
import { NpsCommentLoader } from './view/NpsComment'
import { DefaultLoader } from './view/Default'
import { VideoReviewLoader } from './view/Review/VideoReviewView'

type CampaignViewProps = {
  state: InProgressState
}

function CampaignViewInner(props: PassProps) {
  const { state, submitStatus } = props
  const id = state.step.id
  const showThanks = !!state.thanksPage
  const isCampaignSurvey = state.isCampaignSurvey
  const { loading, setLoading } = useCampaign()
  const { t } = useI18N()
  const isSubmitting = useMemo(() => submitStatus !== 'none', [submitStatus])

  const viewProps = {
    ...props,
    isSubmitting,
    t,
  }

  /**
   * Pass submit status to campaign context
   * so we can render loading indicator nicer
   */
  useEffect(() => {
    if (!loading && isSubmitting) setLoading(true)
    if (loading && !isSubmitting) setLoading(false)
  }, [isSubmitting, loading, setLoading])

  if (showThanks) return <ThanksLoader state={state} t={t} />

  // Use DefaultLoader for non-campaign surveys to just render all questions
  if (!isCampaignSurvey) return <DefaultLoader {...viewProps} />

  switch (id) {
    case 'stars':
      return <StarQuestionLoader {...viewProps} />

    case 'feedback':
      return <FeedbackLoader {...viewProps} />

    case 'review':
      return <ReviewLoader {...viewProps} />

    case 'nps':
      return <NpsQuestionLoader {...viewProps} />

    case 'nps-comment':
      return <NpsCommentLoader {...viewProps} />

    case 'video-record':
      return <VideoReviewLoader {...viewProps} />

    default:
      return <div>Create view for id {id}</div>
  }
}

function CampaignViewForm({ state, dispatch }: CampaignViewProps & { dispatch: PassProps['dispatch'] }) {
  usePrefillFields()
  const fieldsToDisplay = useFieldsToDisplay(state)
  return (
    <SurveyForm state={state} dispatch={dispatch} fields={fieldsToDisplay}>
      {passProps => (
        <Stack gap={2}>
          <CampaignViewInner {...passProps} />
        </Stack>
      )}
    </SurveyForm>
  )
}

export function CampaignView() {
  const [state, dispatch] = useSurveyState()
  if (state.type !== 'in progress') return null
  return <CampaignViewForm state={state} dispatch={dispatch} />
}
