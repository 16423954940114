import { VideoField } from '@tm/shared-lib/src/field'
import { styled } from 'goober'
import { isMobile } from 'mobile-device-detect'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useImmer } from 'use-immer'
import { useSurveyFile } from '../../../hooks/useSurveyFile'
import { useSurveyValue } from '../../../hooks/useSurveyValue'
import { VideoCollect } from './VideoCollect'
import { VideoLanding } from './VideoLanding'
import { VideoPreview } from './VideoPreview'

interface Props {
  field: VideoField
}

interface PreviewPage {
  type: 'preview'
  file: File | string
}

interface LandingPage {
  type: 'landing'
  error?: string | null //undefined = no error, null = unknown error
}

interface RecordPage {
  type: 'record'
}

type Page = PreviewPage | LandingPage | RecordPage

const VideoWrapper = styled(
  'div',
  React.forwardRef
)({
  width: '100%',
})

const VideoContainer = styled('div')<{ height: number }>(({ height }) => ({
  width: '100%',
  height: `${height}px`,
  maxHeight: '70vh',
  position: 'relative',
}))

const FullscreenableDiv = styled(
  'div',
  React.forwardRef
)<{ fullscreen?: boolean }>(({ fullscreen = false }) => ({
  position: fullscreen ? 'fixed' : 'relative',
  zIndex: fullscreen ? 100 : 'inherit',
  left: fullscreen ? 0 : 'unset',
  top: fullscreen ? 0 : 'auto',
  width: '100%',
  height: '100%',
}))

const defaultConstraints: MediaStreamConstraints = {
  video: {
    width: { ideal: 1920 },
    height: { ideal: 1080 },
    facingMode: 'user',
  },
  audio: true,
}

function VideoPage({
  field,
  setAspectRatio,
}: {
  field: VideoField
  setAspectRatio: React.Dispatch<React.SetStateAction<number>>
}) {
  const [file, setFile] = useSurveyFile<File>(field)
  const [value = null, setValue] = useSurveyValue(field)
  if (value !== null && typeof value !== 'string') throw new Error(`Video value does not support type ${typeof value}`)
  const [constraints, setConstraints] = useImmer<MediaStreamConstraints>(defaultConstraints)
  const [page, setPage] = useReducer(
    (state: Page, action: Page) => action,
    file ? { type: 'preview', file } : value ? { type: 'preview', file: value } : { type: 'landing' }
  )

  const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(false)

  useEffect(() => {
    if (!fullscreenEnabled) return
    const originalMaxHeight = document.body.style.maxHeight
    const originalOverflow = document.body.style.overflow
    document.body.style.maxHeight = '100%'
    document.body.style.overflow = 'hidden'
    const escListener = function (ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        setFullscreenEnabled(false)
      }
    }
    document.addEventListener('keydown', escListener)
    return () => {
      document.removeEventListener('keydown', escListener)
      document.body.style.maxHeight = originalMaxHeight
      document.body.style.overflow = originalOverflow
    }
  }, [fullscreenEnabled, setFullscreenEnabled])

  useEffect(() => {
    if (page.type === 'record') {
      if (isMobile) {
        setFullscreenEnabled(true)
      }
    }
  }, [page.type, setFullscreenEnabled])

  const onChange = (f: File | undefined) => {
    if (f) {
      setPage({ type: 'preview', file: f })
      setFile(f)
    } else {
      setPage({ type: 'record' })
      setFile(undefined)
      setValue(null)
    }
  }

  if (page.type === 'landing')
    return (
      <VideoLanding
        onUpload={onChange}
        onRecord={() => {
          setPage({ type: 'record' })
        }}
        error={page.error}
      />
    )
  return (
    <FullscreenableDiv fullscreen={fullscreenEnabled}>
      {page.type === 'record' ? (
        <VideoCollect
          constraints={constraints}
          setConstraints={setConstraints}
          onChange={onChange}
          setAspectRatio={setAspectRatio}
          fullscreenEnabled={fullscreenEnabled}
          setFullscreenEnabled={setFullscreenEnabled}
          onError={err => {
            setPage({ type: 'landing', error: err })
            setConstraints(defaultConstraints)
          }}
        />
      ) : (
        <VideoPreview
          file={page.file}
          onDelete={() => {
            onChange(undefined)
          }}
          fullscreenEnabled={fullscreenEnabled}
          setFullscreenEnabled={setFullscreenEnabled}
        />
      )}
    </FullscreenableDiv>
  )
}

export function Video({ field }: Props): JSX.Element {
  const [containerWidth, setContainerWidth] = useState<number>()
  const divRef = useRef<HTMLDivElement>(null)
  const [aspectRatio, setAspectRatio] = useState(16 / 9)

  useEffect(() => {
    const updateContainerWidth = () => {
      const div = divRef.current
      if (div) {
        setContainerWidth(div.clientWidth)
      }
    }
    updateContainerWidth()
    window.addEventListener('resize', updateContainerWidth)
    return () => {
      window.removeEventListener('resize', updateContainerWidth)
    }
  }, [])

  return (
    <VideoWrapper ref={divRef}>
      {containerWidth && (
        <VideoContainer height={Math.ceil(containerWidth / aspectRatio)}>
          <VideoPage field={field} setAspectRatio={setAspectRatio} />
        </VideoContainer>
      )}
    </VideoWrapper>
  )
}
