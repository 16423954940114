import { createContext, useContext, useEffect, useState, useRef } from 'react'
import { VideoField } from '@tm/shared-lib/src/field'
import { useSurveyFile } from '@tm/client-form/src/hooks/useSurveyFile'
import { useSurveyValue } from '@tm/client-form/src/hooks/useSurveyValue'
import { useImmer, Updater } from 'use-immer'
import Box from '@mui/material/Box'

interface DemoPage {
  type: 'demo'
}

interface PreviewPage {
  type: 'preview'
  file: File | string
}

interface LandingPage {
  type: 'landing'
  error?: string | null //undefined = no error, null = unknown error
}

interface RecordPage {
  type: 'record'
}

type Page = PreviewPage | LandingPage | RecordPage | DemoPage

type AudioStatus = 'no-sound' | 'has-sound'

type VideoContextType = {
  page: Page
  setPage: (page: Page) => void
  onChange: (file: File | undefined | null) => void
  aspectRatio: number
  setAspectRatio: (aspectRatio: number) => void
  constraints: MediaStreamConstraints
  defaultConstraints: MediaStreamConstraints
  setConstraints: Updater<MediaStreamConstraints>
  containerWidth: number
  containerHeight: number
  audioStatus: AudioStatus
  setAudioStatus: (status: AudioStatus) => void
  demo: boolean
  askedPermission: boolean
  setAskedPermission: (asked: boolean) => void
}

export const VideoContext = createContext<VideoContextType>({} as VideoContextType)

type VideoContextProviderProps = {
  children: React.ReactNode
  field: VideoField
  demo?: boolean
}

const defaultConstraints: MediaStreamConstraints = {
  video: {
    width: { ideal: 1920 },
    height: { ideal: 1080 },
    facingMode: 'user',
  },
  audio: true,
}

function useContainerWidth() {
  const [containerWidth, setContainerWidth] = useState<number>()
  const divRef = useRef<HTMLDivElement>(null)
  const [aspectRatio, setAspectRatio] = useState(16 / 9)

  useEffect(() => {
    const updateContainerWidth = () => {
      const div = divRef.current
      if (div) {
        setContainerWidth(div.clientWidth)
      }
    }
    updateContainerWidth()
    window.addEventListener('resize', updateContainerWidth)
    return () => {
      window.removeEventListener('resize', updateContainerWidth)
    }
  }, [])

  return {
    containerWidth,
    divRef,
    aspectRatio,
    setAspectRatio,
  }
}

export function VideoContextProvider(props: VideoContextProviderProps) {
  const { children, field, demo } = props

  const [file, setFile] = useSurveyFile<File>(field)
  const [value = null, setValue] = useSurveyValue(field)

  const [constraints, setConstraints] = useImmer<MediaStreamConstraints>(defaultConstraints)

  const [page, setPage] = useState<Page>(
    demo
      ? { type: 'demo' }
      : file
      ? { type: 'preview', file }
      : value
      ? { type: 'preview', file: value as string }
      : { type: 'landing' }
  )

  const onChange = (f: File | undefined) => {
    if (f) {
      setPage({ type: 'preview', file: f })
      setFile(f)
    } else {
      setPage({ type: 'record' })
      setFile(undefined)
      setValue(null)
    }
  }

  const { containerWidth, divRef, aspectRatio, setAspectRatio } = useContainerWidth()

  const [audioStatus, setAudioStatus] = useState<AudioStatus>('no-sound')

  const containerHeight = containerWidth ? containerWidth / aspectRatio : 0

  // In VideoLanding we need to know if we have asked permission previously
  // and not repeat if declined
  const [askedPermission, setAskedPermission] = useState(false)

  const contextValue: VideoContextType = {
    page,
    setPage,
    onChange,
    constraints,
    setConstraints,
    defaultConstraints,
    aspectRatio,
    setAspectRatio,
    containerWidth: containerWidth || 0,
    containerHeight: containerWidth && containerHeight > containerWidth ? containerWidth : containerHeight,
    audioStatus,
    setAudioStatus,
    demo: !!demo,
    askedPermission,
    setAskedPermission,
  }

  return (
    <VideoContext.Provider value={contextValue}>
      <Box ref={divRef} sx={{ width: '100%' }}>
        {containerWidth && children}
      </Box>
    </VideoContext.Provider>
  )
}

export function useVideoContext() {
  return useContext(VideoContext)
}
