import { TextAreaField } from '@tm/shared-lib/src/field'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { TextArea } from '../field/TextArea'

interface Props {
  field: TextAreaField
}

export function TextAreaQuestion({ field }: Props): JSX.Element {
  const [value, setValue] = useSurveyValue(field)
  const { lang } = useI18N()
  const { rows = 4 } = field
  return (
    <TextArea
      value={typeof value === 'string' ? value : ''}
      onValueChange={setValue}
      rows={rows}
      name={field.name}
      placeholder={field.placeholder && typeof field.placeholder[lang] === 'string' ? field.placeholder[lang] : ''}
    />
  )
}
