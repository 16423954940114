import { keyframes, styled } from 'goober'
import React, { FunctionComponent } from 'react'
import { PageInner } from '../page/PageInner'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Loader = styled('div')`
  display: flex;
  height: 400px;
`

const SpinnerWrapper = styled('div')`
  margin: auto;
  background: rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 8px;
`

const Spinner = styled('div')`
  position: relative;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-top-color: #fff;
  border-radius: 50%;
  animation: ${rotate360} 1s linear infinite;
`

export const SurveyLoadingIcon: FunctionComponent = () => {
  return (
    <PageInner>
      <Loader>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Loader>
    </PageInner>
  )
}
