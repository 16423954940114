import React, { useEffect, useMemo } from 'react'
import { CampaignProvider } from '../../context/campaignContext'
import { SurveyStateContext } from '../../context/surveyStateContext'
import { TranslationsContext } from '../../context/translationsContext'
import { useCampaign } from '../../hooks/useCampaign'
import { State } from '../../hooks/useSurvey/states/state'
import { useSurvey } from '../../hooks/useSurvey/useSurvey'
import PreviewInfo from '../PreviewInfo'
import { CampaignInfo } from './CampaignInfo'
import { CampaignPage } from './CampaignPage'
import { CampaignView } from './CampaignView'

export interface CampaignSurveyLoaderProps {
  surveyId: string
  invitationId?: string
  source?: string
  answerId?: string
  overrideLang?: string
  customParams?: Record<string, string>
}

interface InnerProps {
  state: State
}

export function SurveyInner({ state }: InnerProps) {
  // When state changes, resize the iframe
  const { campaignFrame } = useCampaign()
  useEffect(() => campaignFrame.onResize(), [state])

  switch (state.type) {
    case 'error':
    case 'closed':
    case 'not found':
    case 'loading get':
      return <CampaignInfo type={state.type} />
    case 'in progress':
      return <CampaignView />
  }
}

function CampaignPreviewInfo() {
  const { campaignFrame } = useCampaign()

  // In frame, don't show the preview info because it's in the way
  if (campaignFrame.inFrame) return null

  return <PreviewInfo />
}

export function CampaignSurveyLoader(props: CampaignSurveyLoaderProps): JSX.Element {
  const { surveyId, invitationId, source, answerId, overrideLang, customParams } = props

  const options = useMemo(
    () => ({ invitationId, source, answerId, overrideLang, customParams }),
    [invitationId, source, answerId, overrideLang, customParams]
  )

  const [state, dispatch] = useSurvey(surveyId, options)

  const lang = 'lang' in state ? state.lang : 'en'
  const surveyStateValue: [State, typeof dispatch] = useMemo(() => [state, dispatch], [state, dispatch])
  const translationValue = useMemo(
    () => ({ surveyLanguages: 'meta' in state ? state.meta.languages : [], lang }),
    [state, lang]
  )
  return (
    <CampaignProvider meta={'meta' in state ? state.meta : undefined}>
      <SurveyStateContext.Provider value={surveyStateValue}>
        <TranslationsContext.Provider value={translationValue}>
          <CampaignPage>
            <SurveyInner state={state} />
          </CampaignPage>
          <CampaignPreviewInfo />
        </TranslationsContext.Provider>
      </SurveyStateContext.Provider>
    </CampaignProvider>
  )
}
