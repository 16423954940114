import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { StarsField } from '@tm/shared-lib/src/field'
import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyValue } from '../../hooks/useSurveyValue'

interface Props {
  field: StarsField
}

const StarsWrapper = styled('div')(({ theme }) => theme.styles.starsWrapper)
const Stars = styled('div')(({ theme }) => theme.styles.stars)
const StarsItem = styled('button')(({ theme }) => theme.styles.starsItem)
const StarsItemIcon = styled('div')(({ theme }) => theme.styles.starsItemIcon)
const StarsLabels = styled('div')(({ theme }) => theme.styles.starsLabels)
const StarsLabelsItem = styled('div')(({ theme }) => theme.styles.starsLabelsItem)

export function StarsQuestion({ field }: Props): JSX.Element {
  const { rangeMinLabel = {}, rangeMaxLabel = {} } = field
  const [value, setValue] = useSurveyValue(field)
  const { lang } = useI18N()
  const minLabel = rangeMinLabel[lang] || ''
  const maxLabel = rangeMaxLabel[lang] || ''

  const steps = [1, 2, 3, 4, 5]

  const [hoverValue, setHoverValue] = React.useState<number>()

  return (
    <StarsWrapper className="tm-stars-wrapper">
      <Stars className="tm-stars">
        {steps.map(step => {
          const classes = ['tm-stars-item']
          const displayValue = hoverValue !== undefined ? hoverValue : value
          if (displayValue !== undefined && displayValue !== null) {
            if (step === displayValue) {
              classes.push('tm-stars-item--selected')
            } else if (step <= displayValue) {
              classes.push('tm-stars-item--under')
            } else {
              classes.push('tm-stars-item--over')
            }
          }
          return (
            <StarsItem
              key={step}
              className={classes.join(' ')}
              onMouseEnter={() => setHoverValue(step)}
              onMouseLeave={() => setHoverValue(undefined)}
              onClick={e => {
                e.preventDefault()
                setValue(step)
              }}>
              <StarsItemIcon>
                <Icon icon={faStar} />
              </StarsItemIcon>
            </StarsItem>
          )
        })}
      </Stars>
      <StarsLabels className="tm-stars-labels-text">
        <StarsLabelsItem className="tm-stars-labels-text-min">{minLabel}</StarsLabelsItem>
        <StarsLabelsItem
          className="tm-stars-labels-text-max"
          style={{
            textAlign: 'right',
          }}>
          {maxLabel}
        </StarsLabelsItem>
      </StarsLabels>
    </StarsWrapper>
  )
}
