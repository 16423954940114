import { faFile } from '@fortawesome/free-solid-svg-icons/faFile'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'

const FileWrapper = styled('div')(({ theme }) => theme.styles.fileWrapper)
const FileUploadWrapper = styled('div')(({ theme }) => theme.styles.fileUploadWrapper)
const FileUploadButton = styled('div')(({ theme }) => theme.styles.fileUploadButton)
const FileUploadIcon = styled('i')(({ theme }) => theme.styles.fileUploadIcon)
const FileUploadText = styled('span')(({ theme }) => theme.styles.fileUploadText)
const FileUploadInput = styled('input')(({ theme }) => theme.styles.fileUploadInput)
const FileItem = styled('div')(({ theme }) => theme.styles.fileItem)
const FileItemPreview = styled('div')(({ theme }) => theme.styles.fileItemPreview)
const FileItemPreviewImage = styled('img')(({ theme }) => theme.styles.fileItemPreviewImage)
const FileItemName = styled('div')(({ theme }) => theme.styles.fileItemName)
const FileItemNameInner = styled('span')(({ theme }) => theme.styles.fileItemNameInner)
const FileItemRemove = styled('div')(({ theme }) => theme.styles.fileItemRemove)
const FileItemRemoveButton = styled('div')(({ theme }) => theme.styles.fileItemRemoveButton)

const formatBytes = (a: number, b?: number): string => {
  if (0 == a) return '0 B'

  const c = 1024,
    d = b || 2,
    e = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))

  return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`
}

interface Props {
  value: File[] | undefined
  multiple?: boolean
  maxSize?: number
  fileType: string
  onValueChange: (e: File[] | undefined) => void
}

export function FileField(props: Props): JSX.Element {
  const { value, fileType = '*', maxSize = 10485760, multiple = false } = props
  const { t } = useI18N()

  const onFileChange = (files: FileList | null) => {
    const res: File[] = []
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (file.size > maxSize) {
          continue
        }
        res.push(file)
      }
      props.onValueChange(res)
    } else {
      props.onValueChange(undefined)
    }
  }

  /* If value is not set, then show upload button */
  if (!value || !value.length) {
    return (
      <FileWrapper className="tm-file">
        <FileUploadWrapper className="tm-file-upload">
          <FileUploadButton className="tm-file-upload-text">
            <Icon icon={faFile} />
            {t('file.upload')}
            <FileUploadText className="tm-file-upload-info">
              {` (${fileType !== '*' ? fileType.split(',').join('/') + ', ' : ''}max. ${formatBytes(maxSize)})`}
            </FileUploadText>
          </FileUploadButton>
          <FileUploadInput
            type="file"
            accept={fileType}
            onChange={e => onFileChange(e.target.files)}
            multiple={multiple}
          />
        </FileUploadWrapper>
      </FileWrapper>
    )
  }
  return (
    <FileWrapper className="tm-file tm-file--selected">
      {value.map((file, fileKey) => {
        const { name: fileName, size } = file
        const preview = URL.createObjectURL(file)
        return (
          <FileItem className="tm-file-item" key={fileKey}>
            <FileItemPreview className="tm-file-item-preview">
              <FileItemPreviewImage src={preview} alt={fileName} />
            </FileItemPreview>
            <FileItemName className="tm-file-item-name">
              <span className="ellipsis">
                {fileName}{' '}
                {size ? (
                  <FileItemNameInner className="tm-file-item-name-size">{formatBytes(size)}</FileItemNameInner>
                ) : null}
              </span>
            </FileItemName>
            <FileItemRemove className="tm-file-item-remove">
              <FileItemRemoveButton
                onClick={e => {
                  e.preventDefault()
                  props.onValueChange([...value.slice(0, fileKey), ...value.slice(fileKey + 1)])
                }}>
                <Icon icon={faTrash} />
              </FileItemRemoveButton>
            </FileItemRemove>
          </FileItem>
        )
      })}
    </FileWrapper>
  )
}
