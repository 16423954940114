import { faVideoCamera } from '@fortawesome/free-solid-svg-icons/faVideoCamera'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone'
import Icon from '@trustmary/icons'
import React from 'react'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

interface DeviceSelectorProps {
  type: 'camera' | 'microphone'
  devices: MediaDeviceInfo[]
  selectedDevice?: MediaDeviceInfo
  onDeviceSelected: (deviceId: string) => void
  error?: boolean
  onClick?: () => void
}

export function DeviceSelector({
  type,
  devices,
  selectedDevice,
  onDeviceSelected,
  error,
  onClick,
}: DeviceSelectorProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = !!anchorEl
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    if (onClick) onClick()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: '#FFFFFF',
          bgcolor: 'rgba(0,0,0,0.5)',
          width: 32,
          minWidth: 32,
          height: 32,
          borderRadius: '50%',
          '&:hover': {
            bgcolor: 'rgba(0,0,0,0.8)',
          },
          ...(error && {
            bgcolor: 'error.main',
            '&:hover': {
              bgcolor: 'error.dark',
            },
          }),
        }}>
        {type === 'camera' && <Icon icon={faVideoCamera} />}
        {type === 'microphone' && <Icon icon={faMicrophone} />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {devices.map(device => (
          <MenuItem
            key={device.deviceId}
            onClick={() => {
              onDeviceSelected(device.deviceId)
              handleClose()
            }}
            disabled={device.deviceId === selectedDevice?.deviceId}
            sx={{
              fontSize: 12,
            }}>
            {device.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
