import { MultidropdownField } from '@tm/shared-lib/src/field'
import React from 'react'
import Select from 'react-select'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { useTheme } from '../../hooks/useTheme'
import { dropdownStyles } from './Dropdown'

interface Props {
  field: MultidropdownField
}

export function MultidropdownQuestion({ field }: Props): JSX.Element {
  const { t, lang } = useI18N()
  const theme = useTheme()
  const { options = [] } = field
  const [value = [], setValue] = useSurveyValue(field)
  if (!Array.isArray(value)) throw new Error('Multidropdown does not support value: ' + String(value))
  const translatedOptions = options.map(current => {
    return { value: current.value, label: current.label[lang] || '' }
  })

  function selectOnChange(event: { label: string; value: string }[]) {
    setValue(event.map(o => o.value))
  }

  return (
    <Select
      styles={dropdownStyles(theme.primaryColor)}
      isMulti={true}
      options={translatedOptions}
      onChange={selectOnChange}
      defaultValue={translatedOptions.filter(o => value.indexOf(o.value) >= 0)}
      placeholder={t('dropdown.selectValue')}
      key={`${lang}-${field.name}`}
    />
  )
}
