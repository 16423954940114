import { Styles, useStyles } from '@trustmary/form-styles'
import { setup } from 'goober'
import { shouldForwardProp } from 'goober/should-forward-prop'
import React from 'react'

declare module 'goober' {
  export interface DefaultTheme {
    styles: Styles
    values: {
      [key: string]: string
    }
  }
}

setup(
  React.createElement,
  undefined,
  useStyles,
  shouldForwardProp(prop => prop['0'] !== '$')
)
