import { useEffect } from 'react'
import { useCampaign } from './useCampaign'

/**
 * This is a helper hook for CampaignContext to set title easily
 */
export function useTitle(title = '') {
  const { setTitle } = useCampaign()

  useEffect(() => {
    setTitle(title)

    return () => {
      setTitle('')
    }
  }, [title])
}
