import useI18N from '@tm/client-form/src/hooks/useI18n'
import { useTitle } from '@tm/client-form/src/hooks/useTitle'
import { Field } from '@tm/shared-lib/src/field'
import { useSurveyValue } from '../../../../hooks/useSurveyValue'
import { ViewProps } from '../types'
import { NpsCommentView } from './NpsCommentView'

type NpsCommentLoaderInnerProps = {
  field: Field
  lang: string
  submitForm: () => void
  isSubmitting: boolean
  validationErrors: ViewProps['validationErrors']
}

function NpsCommentLoaderInner({
  field,
  lang,
  submitForm,
  isSubmitting,
  validationErrors,
}: NpsCommentLoaderInnerProps) {
  const { t } = useI18N()
  const [value, setValue] = useSurveyValue(field)
  const label = 'label' in field && field.label ? field.label[lang] : undefined
  const inputLabel = 'inputLabel' in field && field.inputLabel ? field.inputLabel[lang] : undefined
  const description = 'description' in field && field.description ? field.description[lang] : undefined
  const error = Array.isArray(validationErrors[field.name])
    ? validationErrors[field.name].map(errorId => t(errorId)).join(', ')
    : ''
  useTitle(label)

  return (
    <NpsCommentView
      label={label}
      description={description}
      inputLabel={inputLabel}
      value={typeof value === 'string' ? value : ''}
      onChange={setValue}
      onSubmit={submitForm}
      isSubmitting={isSubmitting}
      error={error}
    />
  )
}

export function NpsCommentLoader({ state, submitForm, isSubmitting, validationErrors }: ViewProps) {
  const fields = state.step.fields || []
  const feedbackield = fields.find(field => field.name === 'feedback')
  if (!feedbackield) return null
  return (
    <NpsCommentLoaderInner
      field={feedbackield}
      lang={state.lang}
      submitForm={submitForm}
      isSubmitting={isSubmitting}
      validationErrors={validationErrors}
    />
  )
}
