import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { PublicConsentType } from '@tm/types/db/tables'
import { useI18N } from '../../../hooks/useI18n'

export type ConsentFieldProps = {
  type: PublicConsentType
  value: boolean
  onChange: (value: boolean) => void
  privacyPolicyOrganization?: string
  privacyPolicyUrl?: string
  validationErrors?: string
  isPublicConsent?: boolean
}

function PrivacyPolicyLink(props: { label: string; url?: string }) {
  if (!props.url) return <span>{props.label}</span>

  return (
    <Box
      component="a"
      href={props.url}
      target="_blank"
      rel="noreferrer"
      sx={{
        color: 'text.primary',
      }}>
      {props.label}
    </Box>
  )
}

const trustmaryPolicyUrl = 'https://trustmary.com/terms-of-service/'

/**
 * privacyPolicy.label: I have read
 * privacyPolicy.privacyPolicy: privacy policy
 *
 * publicConsent.bySubmittingYouConsent: By submitting the form I agree to
 * publicConsent.trustmaryPolicy.text: and
 * publicConsent.trustmaryPolicy.linkText: Trustmary’s Terms of Service
 */

export function ConsentField(props: ConsentFieldProps) {
  const { type, privacyPolicyOrganization, privacyPolicyUrl, value, onChange, validationErrors, isPublicConsent } =
    props
  const { t } = useI18N()

  if (type === 'default')
    return (
      <Typography variant="body2">
        {t('publicConsent.bySubmittingYouConsent')} {privacyPolicyOrganization || ''}{' '}
        <PrivacyPolicyLink label={t('publicConsent.privacyPolicy')} url={privacyPolicyUrl} />{' '}
        {t('publicConsent.trustmaryPolicy.text')}{' '}
        <PrivacyPolicyLink label={t('publicConsent.trustmaryPolicy.linkText')} url={trustmaryPolicyUrl} />
      </Typography>
    )

  // If consent field is public consent, show "I want to publis my comment", otherwise show "I have read privacy policy"
  const privacyPolicyText = isPublicConsent ? t('publicConsent.label') : t('privacyPolicy.label')

  // gdpr-compliant
  return (
    <FormControl error={!!validationErrors}>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={() => onChange(!value)} />}
        label={
          <Typography variant="body2">
            {privacyPolicyText} {privacyPolicyOrganization || ''}{' '}
            <PrivacyPolicyLink label={t('publicConsent.privacyPolicy')} url={privacyPolicyUrl} />{' '}
            {t('publicConsent.trustmaryPolicy.text')}{' '}
            <PrivacyPolicyLink label={t('publicConsent.trustmaryPolicy.linkText')} url={trustmaryPolicyUrl} />
          </Typography>
        }
      />
      {!!validationErrors && (
        <Typography variant="caption" color="error">
          {validationErrors}
        </Typography>
      )}
    </FormControl>
  )
}
