import { EmailField } from '@tm/shared-lib/src/field'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { InputField } from '../field/InputField'

interface Props {
  field: EmailField
}

export function EmailQuestion({ field }: Props): JSX.Element {
  const [value, setValue] = useSurveyValue(field)
  const { lang } = useI18N()
  if (value === undefined || typeof value === 'string') {
    return (
      <InputField
        type="email"
        value={value}
        onValueChange={setValue}
        name={field.name}
        placeholder={field.placeholder && typeof field.placeholder[lang] === 'string' ? field.placeholder[lang] : ''}
      />
    )
  }
  throw new Error('Email does not support value: ' + value)
}
