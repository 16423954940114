import { styled } from 'goober'
import React from 'react'
import { CommonProps } from './models/commonProps'

const Input = styled('input')(({ theme }) => theme.styles.input)

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  CommonProps<string>

export function InputField(props: Props): JSX.Element {
  const {
    value = '',
    name,
    placeholder = '',
    disabled = false,
    className = '',
    onChange,
    onValueChange,
    ...restProps
  } = props

  const classes = ['tm-input'].concat(className.split(' '))
  return (
    <div className={`tm-input tm-input--text`}>
      <Input
        value={value}
        name={name}
        id={`tm-${name}`}
        onChange={e => {
          if (onChange) onChange(e)
          onValueChange(e.target.value)
        }}
        placeholder={placeholder}
        disabled={disabled}
        className={classes.join(' ')}
        maxLength={10000}
        {...restProps}
      />
    </div>
  )
}
