import { InputProps, stringValue } from './types'
import { RadiosField } from '@tm/shared-lib/src/field'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { getTranslatedValue } from './getTranslatedValue'
import { useCallback } from 'react'

export function RadiosInput(props: InputProps<RadiosField>) {
  const { field, value, onChange, isSubmitting, lang } = props

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange((event.target as HTMLInputElement).value)
    },
    [onChange]
  )

  return (
    <FormControl>
      <RadioGroup name={field.name} value={stringValue(value)} onChange={handleChange}>
        {field.options?.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={getTranslatedValue(option.label, lang)}
            disabled={isSubmitting}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
