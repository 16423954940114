import { faPauseCircle } from '@fortawesome/free-regular-svg-icons/faPauseCircle'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons/faPlayCircle'
import Icon from '@trustmary/icons'
import { styled } from 'goober'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useI18N } from '../../../hooks/useI18n'
import { ActionButton } from './VideoButtons'

const PreviewRetakeButton = styled(ActionButton)({
  pointerEvents: 'initial',
  padding: '15px 24px',
  fontSize: '11px',
  lineHeight: '12px',
  color: '#DE715D',
  border: '1px solid #DE715D',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'rgba(222, 113, 93, 0.1)',
  },
  '@media (max-width: 900px)': {
    fontSize: '10px',
    lineHeight: '11px',
    padding: '13px 19px',
  },
})

const PreviewAcceptButton = styled(ActionButton)({
  pointerEvents: 'initial',
  display: 'inline-block',
  padding: '15px 24px',
  fontSize: '11px',
  lineHeight: '12px',
  color: '#FFFFFF',
  border: 'none',
  backgroundColor: '#163429',
  '&:hover': {
    backgroundColor: '#23634B',
  },
  /*':fullscreen &': {
    display: 'inline-block',
  },
  ':-webkit-full-screen &': {
    display: 'inline-block',
  },
  ':-moz-full-screen &': {
    display: 'inline-block',
  },
  ':-ms-fullscreen &': {
    display: 'inline-block',
  },
  '@media (max-width: 900px)': {
    fontSize: '10px',
    lineHeight: '11px',
    padding: '13px 19px',
  },*/
})

const PreviewActionsWrapper = styled('div')({
  pointerEvents: 'none',
  position: 'absolute',
  zIndex: 2,
  bottom: 0,
  left: 0,
  width: '100%',
  padding: '0 min(15px, 2%) min(15px, 2%)',
  textAlign: 'center',
})

const PreviewActionsContainer = styled('div')({
  pointerEvents: 'none',
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '999px',
  paddingLeft: '15px',
  gap: '5px',
  zIndex: 2,
  fontSize: '16px',
  backdropFilter: 'blur(10.814px)',
  '@media (max-width: 900px)': {
    fontSize: '12px',
  },
})

const PlayPauseDiv = styled('div')({
  position: 'absolute',
  zIndex: 3,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#FFFFFF',
  fontSize: '80px',
  pointerEvents: 'none',
  '@media (max-width: 900px)': {
    fontSize: '50px',
  },
})

export const VideoElement = styled(
  'video',
  React.forwardRef
)<{ fullscreen: boolean; mirror?: boolean }>(({ fullscreen, mirror = false }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: fullscreen ? 0 : '10px',
  backgroundColor: '#17221E',
  transform: mirror ? 'scaleX(-1)' : 'initial',
}))

export function VideoPreview({
  file,
  onDelete,
  fullscreenEnabled,
  setFullscreenEnabled,
}: {
  file: File | string
  onDelete: () => void
  fullscreenEnabled: boolean
  setFullscreenEnabled: (value: boolean) => void
}): JSX.Element {
  const { t } = useI18N()
  const [playing, setPlaying] = useState<boolean>(false)

  const videoRef = useRef<HTMLVideoElement>(null)

  const play = () => {
    if (videoRef.current) {
      void videoRef.current.play()
    }
  }

  const pause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      const video: HTMLVideoElement = videoRef.current
      video.currentTime = 0
      video.onplay = () => {
        setPlaying(true)
      }
      video.onpause = () => {
        setPlaying(false)
      }
    }
  }, [setPlaying])

  const videoEl = useMemo(() => {
    return (
      <VideoElement
        fullscreen={fullscreenEnabled}
        playsInline
        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
        controls={false}
        ref={videoRef}
        onClick={e => {
          e.preventDefault()
          if (e.currentTarget.paused) {
            play()
          } else {
            pause()
          }
        }}
      />
    )
  }, [file, fullscreenEnabled])

  return (
    <React.Fragment>
      {videoEl}
      <PlayPauseDiv>{playing ? <Icon icon={faPauseCircle} /> : <Icon icon={faPlayCircle} />}</PlayPauseDiv>
      <PreviewActionsWrapper>
        {!playing && (
          <PreviewActionsContainer>
            {t('video2.preview.readyOrRetake')}&nbsp;
            {fullscreenEnabled && (
              <PreviewAcceptButton
                onClick={e => {
                  e.preventDefault()
                  setFullscreenEnabled(false)
                  //closeFullscreen()
                }}>
                {t('video2.preview.acceptButton')}
              </PreviewAcceptButton>
            )}
            <PreviewRetakeButton
              onClick={e => {
                e.preventDefault()
                onDelete()
              }}>
              {t('video2.preview.retakeButton')}
            </PreviewRetakeButton>
          </PreviewActionsContainer>
        )}
      </PreviewActionsWrapper>
    </React.Fragment>
  )
}
