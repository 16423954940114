import { PublicConsentField } from '@tm/shared-lib/src/field'
import React, { useEffect, useMemo } from 'react'
import { useI18N } from '../../hooks/useI18n'
import { InProgressState } from '../../hooks/useSurvey/states/inProgress'
import { State } from '../../hooks/useSurvey/states/state'
import { useSurveyState } from '../../hooks/useSurveyState'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Checkbox } from '../field/Checkbox'

const trustmaryPolicyUrl = 'https://trustmary.com/terms-of-service/'

interface Props {
  field: PublicConsentField
}

const shouldAskConsent = (state: State) => (state as InProgressState).publicConsent === 'gdpr-compliant'

const PrivacyPolicyLink = ({ label, url }: { url: string | undefined; label: string }) =>
  url ? (
    <a href={url} target="_blank" rel="noreferrer">
      {label}
    </a>
  ) : (
    <>{label}</>
  )

export function PublicConsentQuestion({ field }: Props): JSX.Element {
  const { t } = useI18N()
  const [value, setValue] = useSurveyValue(field)
  const [state] = useSurveyState()
  const privacyPolicyUrl = state.type === 'in progress' ? state.meta.privacyPolicy : ''
  const privacyPolicyOrganization = state.type === 'in progress' ? state.meta.privacyPolicyOrganization : ''

  const booleanValue = useMemo(() => {
    if (typeof value === 'string' && (value === 'true' || value === 'false' || value === '')) {
      return value === 'true'
    } else if (value === undefined || typeof value === 'boolean') {
      return value
    }
    throw new Error('PublicConsent does not support value: ' + value)
  }, [value])

  // No need to request consent if non-GDPR compliant consent is activated so just set this box enabled
  useEffect(() => {
    // Editor will not give the in progress state
    if (state.type !== 'in progress') return

    if (!shouldAskConsent(state)) setValue(true)
  }, [setValue, state])

  // Only render text as checkbox is now not needed

  if (!shouldAskConsent(state))
    return (
      <div data-testid="label">
        {t('publicConsent.bySubmittingYouConsent')} {privacyPolicyOrganization || ''}{' '}
        <PrivacyPolicyLink label={t('publicConsent.privacyPolicy')} url={privacyPolicyUrl} />{' '}
        {t('publicConsent.trustmaryPolicy.text')}{' '}
        <PrivacyPolicyLink label={t('publicConsent.trustmaryPolicy.linkText')} url={trustmaryPolicyUrl} />
      </div>
    )

  // Render checkbox for confirmation

  return (
    <Checkbox
      value={booleanValue}
      onValueChange={setValue}
      renderLabel={() => (
        <>
          {t('publicConsent.label')} {privacyPolicyOrganization || ''}{' '}
          <PrivacyPolicyLink label={t('publicConsent.privacyPolicy')} url={privacyPolicyUrl} />{' '}
          {t('publicConsent.trustmaryPolicy.text')}{' '}
          <PrivacyPolicyLink label={t('publicConsent.trustmaryPolicy.linkText')} url={trustmaryPolicyUrl} />
        </>
      )}
    />
  )
}
