import { Alert, CircularProgress, Stack, Typography } from '@mui/material'
import { State } from '../../hooks/useSurvey/states/state'

type CampaignInfoProps = {
  type: State['type']
}

export function CampaignInfoInner({ type }: CampaignInfoProps) {
  switch (type) {
    case 'loading get':
      return <CircularProgress />

    case 'closed':
      return <Typography>Form is closed</Typography>

    case 'not found':
      return <Typography>Form not found</Typography>

    default:
      return <Alert severity="error">Unexpected error, please try again</Alert>
  }
}

export function CampaignInfo({ type }: CampaignInfoProps) {
  return (
    <Stack height={200} alignItems="center" justifyContent="center">
      <CampaignInfoInner type={type} />
    </Stack>
  )
}
