import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ThirdPartyCollectSource } from '@tm/types/survey/third-party-collect'
import { PlatformView } from '../Review/PlatformView'

type ThanksViewProps = {
  title: string
  text: string
  sources?: ThirdPartyCollectSource[]
  lang: string
}

export function ThanksView({ title, text, sources, lang }: ThanksViewProps) {
  const urlSearchParams = new URLSearchParams(location.search)
  const inPreview = urlSearchParams.get('preview')
  if (sources?.length === 1 && sources[0].source !== 'Trustmary')
    return <PlatformView sources={sources} lang={lang} noRedirect={!!inPreview} />
  return (
    <Stack sx={{ textAlign: 'center', maxWidth: 480, mx: 'auto' }}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography component="div" variant="body1" dangerouslySetInnerHTML={{ __html: text }} />
      {!!sources && <PlatformView sources={sources} lang={lang} noRedirect={!!inPreview} />}
    </Stack>
  )
}
