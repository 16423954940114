import { ThirdPartyCollectSource } from '@tm/types/survey/third-party-collect'
import { useEffect, useMemo } from 'react'
import { useCampaign } from '@tm/client-form/src/hooks/useCampaign'

export const usePlatformRedirect = (sources?: ThirdPartyCollectSource[] | null, noRedirect?: boolean) => {
  const { campaignFrame } = useCampaign()

  const redirectUrl = useMemo(() => {
    return sources?.length === 1 && sources[0].source !== 'Trustmary' && sources[0].url
  }, [sources])

  useEffect(() => {
    if (!redirectUrl || noRedirect) {
      return
    }
  
    if (campaignFrame.inFrame) {
      window.open(redirectUrl, '_blank')
      return
    }

    window.location.href = redirectUrl
  }, [redirectUrl, noRedirect, campaignFrame])

  return {
    isRedirecting: !!redirectUrl,
    redirectUrl,
  }
}
