import { useMemo, useCallback } from 'react'
import { useVideoRecord } from './useVideoRecord'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import { DeviceSelector } from './DeviceSelector'
import { VideoContainer } from '../VideoContainer'
import { VideoActionBar } from '../VideoActionBar'
import { useVideoContext } from '../VideoContext'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const AudioErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    boxShadow: theme.customShadows.dropdown,
    fontSize: 11,
    maxWidth: 200,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
}))

interface VideoRecordProps {
  stream: MediaStream
  onStop: (blob: Blob, fileExtension: string, mimeType?: string) => void
}

type RecordCountDownProps = {
  recordingStatus: number | boolean | 'start'
  onCancel: () => void
}

function RecordCountDown(props: RecordCountDownProps) {
  const { recordingStatus, onCancel } = props
  const { t } = useI18N()

  if (typeof recordingStatus === 'boolean') return null

  return (
    <Stack
      gap={1}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0,0,0,0.5)',
      }}>
      <Typography variant="h1" color="white" m={0} lineHeight={0.9}>
        {typeof recordingStatus === 'number'
          ? recordingStatus + 1
          : recordingStatus === 'start'
          ? t('video2.countdown.start')
          : '1'}
      </Typography>
      <Typography variant="body1" color="white" m={0}>
        {t('video2.countdown.description')}
      </Typography>
      <Button
        onClick={onCancel}
        size="small"
        variant="outlined"
        color="inherit"
        sx={{
          // bgcolor: 'white',
          position: 'absolute',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          borderColor: 'white',
          color: 'white',
          '&:hover': {
            bgcolor: 'white',
            color: 'text.primary',
            borderColor: 'white',
          },
        }}>
        {t('common.cancel')}
      </Button>
    </Stack>
  )
}

export function VideoRecord({ stream, onStop }: VideoRecordProps): JSX.Element {
  const {
    recordingStatus,
    setRecordingStatus,
    recordingTimer,
    setRecordingTimer,
    chunks,
    options,
    fileExtension,
    mediaRecorder,
    mirror,
    cameraDevices,
    microphoneDevices,
    selectedCamera,
    selectedMicrophone,
    onCameraSelected,
    onMicrophoneSelected,
  } = useVideoRecord(stream)

  const { audioStatus } = useVideoContext()

  const { t } = useI18N()

  const videoEl = useMemo(() => {
    return (
      <Box
        component="video"
        playsInline
        autoPlay
        muted
        ref={(v: HTMLVideoElement | null) => {
          if (v) v.srcObject = stream
        }}
        controls={false}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: {
            sm: 1
          },
          backgroundColor: '#17221E',
          transform: mirror ? 'scaleX(-1)' : 'initial',
        }}
      />
    )
  }, [stream, mirror])

  const onRecordClick = useCallback(() => {
    if (recordingStatus === false) {
      setRecordingStatus(2)
      setRecordingTimer(0)
    } else {
      setRecordingTimer(undefined)
      setRecordingStatus(false)
      if (typeof recordingStatus !== 'number') {
        //Stop recording
        mediaRecorder.onstop = () => {
          onStop(new Blob(chunks.current), fileExtension, options.mimeType)
        }
        mediaRecorder.stream.getTracks().forEach(t => t.stop())
        mediaRecorder.stop()
      }
    }
  }, [
    recordingStatus,
    onStop,
    chunks,
    fileExtension,
    mediaRecorder,
    options.mimeType,
    setRecordingStatus,
    setRecordingTimer,
  ])

  const onRecordCancel = useCallback(() => {
    // Can't cancel if already recording
    if (recordingStatus === 'start') return
    setRecordingTimer(undefined)
    setRecordingStatus(false)
  }, [recordingStatus])

  const showAudioError = recordingStatus !== false && audioStatus === 'no-sound'

  return (
    <>
      <VideoContainer>
        {videoEl}
        <RecordCountDown recordingStatus={recordingStatus} onCancel={onRecordCancel} />

        <Stack
          direction="row"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            gap: 1,
            zIndex: 2,
            transition: 'opacity 0.3s',
            ...(recordingStatus !== false &&
              audioStatus === 'has-sound' && {
                opacity: 0,
                pointerEvents: 'none',
              }),
          }}>
          <DeviceSelector
            type="camera"
            devices={cameraDevices}
            selectedDevice={selectedCamera}
            onDeviceSelected={onCameraSelected}
          />

          <AudioErrorTooltip title={t('video2.audioError')} open={showAudioError} arrow placement="bottom-end">
            <Box>
              <DeviceSelector
                type="microphone"
                devices={microphoneDevices}
                selectedDevice={selectedMicrophone}
                onDeviceSelected={onMicrophoneSelected}
                error={showAudioError}
                onClick={onRecordCancel}
              />
            </Box>
          </AudioErrorTooltip>
        </Stack>
      </VideoContainer>
      <VideoActionBar
        recordingStatus={recordingStatus}
        onRecordClick={onRecordClick}
        recordingTimer={recordingTimer}
        stream={stream}
      />
    </>
  )
}
