import { resolveConditions } from '@tm/shared-lib/src/condition'
import { Field } from '@tm/shared-lib/src/field'
import { useMemo } from 'react'
import { InProgressState } from './useSurvey/states/inProgress'

export function useFieldsToDisplay(state: InProgressState, preview = false): Field[] {
  const fieldsToDisplay = useMemo(() => {
    const fields = state.step?.fields || []
    return fields.reduce<Field[]>((acc, field) => {
      const isVisible = !field.conditions || resolveConditions(field.conditions, state.values)
      if (isVisible || preview) {
        // In editor preview, we want to show filtered fields with opacity
        if (!isVisible) field = { ...field, filtered: true }

        if (field.type === 'fieldset') {
          if (!field.fields) {
            acc.push(field)
          } else {
            const f = field.fields.filter(fieldsetField => {
              return !fieldsetField.conditions || resolveConditions(fieldsetField.conditions, state.values)
            })
            acc.push({ ...field, fields: f })
          }
        } else {
          acc.push(field)
        }
      }
      return acc
    }, [])
  }, [state.step, state.values, preview])

  return fieldsToDisplay
}
