import { DropdownField } from '@tm/shared-lib/src/field'
import React from 'react'
import Select, { StylesConfig } from 'react-select'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { useTheme } from '../../hooks/useTheme'

export function dropdownStyles(
  primaryColor: string
): StylesConfig<{ value: string; label: string }, false> | undefined {
  return {
    menu: provided => {
      return {
        ...provided,
        zIndex: 5,
      }
    },
    option: (provided, state) => {
      let backgroundColor = '#fff'
      if (state.isSelected) backgroundColor = primaryColor

      return {
        ...provided,
        backgroundColor: backgroundColor,
        ':hover': {
          backgroundColor: state.isSelected ? primaryColor : '#f4f4f4',
        },
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: `2px solid ${state.isFocused ? primaryColor : '#cecece'}`,
        boxShadow: `0 0 1px ${state.isFocused ? primaryColor : '#cecece'}`,
        borderRadius: '2px',
        color: '#222936',
        ':hover': {
          borderColor: state.isFocused ? primaryColor : '#b3b3b3',
        },
      }
    },
    indicatorsContainer: provided => {
      return {
        ...provided,
        padding: '1px',
      }
    },
  }
}

interface Props {
  field: DropdownField
}

export function DropdownQuestion({ field }: Props): JSX.Element {
  const theme = useTheme()
  const { t, lang } = useI18N()
  const [value, setValue] = useSurveyValue(field)
  const { options = [], validation = [] } = field
  const isRequired = validation.indexOf('required') >= 0

  const translatedOptions = options.map(current => {
    return { value: current.value, label: current.label[lang] || '' }
  })

  if (!isRequired)
    translatedOptions.unshift({
      value: '',
      label: t('dropdown.selectValue'),
    })

  function selectOnChange(event: { label: string; value: string }) {
    setValue(event.value)
  }

  return (
    <Select
      styles={dropdownStyles(theme.primaryColor)}
      options={translatedOptions}
      onChange={selectOnChange}
      defaultValue={translatedOptions.find(o => o.value === value)}
      placeholder={t('dropdown.selectValue')}
      key={`${lang}-${field.name}`}
    />
  )
}
