import { IconDefinition } from '@fortawesome/fontawesome-common-types'

export const faExclamationTriangle: IconDefinition = {
  prefix: 'fad',
  iconName: 'triangle-exclamation',
  icon: [
    512,
    512,
    [],
    'f071',
    [
      'M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z',
      'M256 320C269.3 320 280 309.3 280 296v-128c0-13.25-10.75-24-23.1-24S232 154.8 232 168v128C232 309.3 242.8 320 256 320zM256 353.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 401.9 238.6 416 256 416s31.44-14.08 31.44-31.44C287.4 367.2 273.4 353.1 256 353.1z',
    ],
  ],
}
