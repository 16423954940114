import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useI18N from '@tm/client-form/src/hooks/useI18n'
import { useSurveyValue } from '@tm/client-form/src/hooks/useSurveyValue'

export function VideoReviewIntro() {
  const { t } = useI18N()
  const [comment] = useSurveyValue({ name: 'comment', system: true })
  const commentText = typeof comment === 'string' ? comment : ''

  return (
    <Stack gap={3}>
      <Stack gap={1} textAlign="center">
        <Typography variant="h4">{t('videoIntro.title1')}</Typography>
        <Typography>{t('videoIntro.title2')}</Typography>
      </Stack>
      <Stack gap={1} textAlign="center" bgcolor="background.neutral" borderRadius={1} p={3}>
        {commentText
          .split('\n')
          .filter(s => s.trim() !== '')
          .map((s, k) => (
            <Typography key={k}>{s}</Typography>
          ))}
      </Stack>
    </Stack>
  )
}
