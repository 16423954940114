import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { Icon } from '@trustmary/icons'
import { CSSAttribute, styled } from 'goober'
import React, { useState } from 'react'
import { useI18N } from '../hooks/useI18n'
import { colors } from '../misc/colors'

interface Props {
  setLang: React.Dispatch<React.SetStateAction<string>>
}

const LangSwitcherSelectButton = styled('button')(({ theme }) => {
  const isLight = theme.values.pageBackgroundColorType === 'light'

  const styles: CSSAttribute = {
    display: 'block',
    border: 'none',
    background: 'none',
    textAlign: 'center',
    width: '100%',
    padding: '10px 10px',
    lineHeight: '20px',
    fontSize: '14px',
    minWidth: '120px',
    opacity: '.8',
    '&:hover': {
      opacity: '1',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: `inset 0 0 0 2px ${colors.yellow}`,
    },
  }

  if (!isLight) {
    styles.svg = {
      path: {
        fill: '#fff',
      },
    }
    styles.color = '#fff'
  }

  return styles
})

const LangSwitcherWrapper = styled('div')`
  height: 40px;
`

const LangSwitcherSelect = styled('div')`
  float: right;
  position: relative;
`

const LangSwitcherIcon = styled('i')`
  font-size: 13px;
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.6);
`

const LangSwitcherOverlay = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`

const LangSwitcherMenu = styled<{ className: string }>('div')`
  position: absolute;
  z-index: 4;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  button {
    display: block;
    width: 100%;
    background: #fff;
    margin: 3px;
    border-radius: 3px;
    border: none;
    width: 120px;
    line-height: 20px;
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
    &:hover {
      background: #e6e8ea;
    }
  }
`

export function LangSwitcher({ setLang }: Props): JSX.Element | null {
  const { lang, surveyLanguages } = useI18N()

  const { name: langLabel = lang } = surveyLanguages.find(i => i.lang == lang) || {}
  const [open, setOpen] = useState(false)

  if (surveyLanguages.length < 2) return null

  const changeLang = (lang: string) => {
    setLang(lang)
    setOpen(false)
  }
  return (
    <LangSwitcherWrapper className="survey-langswitcher">
      <LangSwitcherSelect className="survey-langswitcher-select">
        <LangSwitcherSelectButton
          onClick={e => {
            e.preventDefault()
            setOpen(true)
          }}>
          <LangSwitcherIcon>
            <Icon icon={faGlobe} />
          </LangSwitcherIcon>
          {langLabel}
        </LangSwitcherSelectButton>
        {open ? (
          <React.Fragment>
            <LangSwitcherOverlay
              className="survey-langswitcher-overlay"
              onClick={e => {
                e.preventDefault()
                setOpen(false)
              }}
            />
            <LangSwitcherMenu className="survey-langswitcher-menu">
              {surveyLanguages.map(({ lang, name }) => {
                return (
                  <button
                    onClick={e => {
                      e.preventDefault()
                      changeLang(lang)
                    }}
                    key={lang}>
                    {name || lang}
                  </button>
                )
              })}
            </LangSwitcherMenu>
          </React.Fragment>
        ) : null}
      </LangSwitcherSelect>
    </LangSwitcherWrapper>
  )
}
