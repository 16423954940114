import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { VideoStreamVisualizer } from './VideoStreamVisualizer'

type VideoActionBarProps = {
  recordingStatus?: number | boolean | 'start'
  onRecordClick?: () => void
  recordingTimer?: number
  stream?: MediaStream
}

const twoDigits = (num: number) => {
  if (num < 10) return `0${num}`
  return num
}

const getTimer = (recording: boolean, recordingTimer?: number) => {
  if (recordingTimer === undefined) return '00:00'
  if (recording === false) return '00:00'
  const seconds = Math.floor(recordingTimer / 1000)
  const minutes = Math.floor(seconds / 60)
  const secondsLeft = seconds - minutes * 60
  return `${twoDigits(minutes)}:${twoDigits(secondsLeft)}`
}

export function VideoActionBar(props: VideoActionBarProps) {
  const { recordingStatus, onRecordClick, recordingTimer, stream } = props
  const disabled = typeof onRecordClick !== 'function'
  const recording = recordingStatus === true

  const timer = getTimer(recording, recordingTimer)

  return (
    <Stack
      direction="row"
      className="tm-video-action-bar"
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: {
          xs: 1,
          sm: 2,
        },
        px: 2,
        position: 'relative',
        height: {
          xs: 80,
          sm: 88,
        },
      }}>
      <Box>
        {!!timer && (
          <Typography variant="body2" color="grey.500">
            {timer}
          </Typography>
        )}
      </Box>
      <Button
        sx={{
          minWidth: {
            xs: 48,
            sm: 56,
          },
          width: {
            xs: 48,
            sm: 56,
          },
          height: {
            xs: 48,
            sm: 56,
          },
          borderRadius: '50%',
          p: 0,
          bgcolor: '#fff',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '&:hover': {
            bgcolor: '#fff',
          },
          '&[disabled]': {
            bgcolor: 'grey.600',
          },
        }}
        disabled={disabled}
        variant="contained"
        onClick={onRecordClick}>
        <Box
          component="span"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
              xs: 42,
              sm: 48,
            },
            height: {
              xs: 42,
              sm: 48,
            },
            borderRadius: '50%',
            bgcolor: 'error.main',
            transition: '.2s all ease-in-out',
            '.MuiButton-root:hover > &': {
              bgcolor: 'error.dark',
            },
            '.MuiButton-root[disabled] > &': {
              bgcolor: 'grey.700',
            },
            ...(recording && {
              borderRadius: 0.5,
              width: {
                xs: 28,
                sm: 32,
              },
              height: {
                xs: 28,
                sm: 32,
              },
            }),
          }}
        />
      </Button>
      <Box>{stream && <VideoStreamVisualizer stream={stream} recordingStatus={recordingStatus} />}</Box>
    </Stack>
  )
}
