import { styled } from 'goober'
import React from 'react'
import { CommonProps } from './models/commonProps'

const Textarea = styled('textarea')(({ theme }) => theme.styles.textarea)

type Props = CommonProps<string> &
  React.InputHTMLAttributes<HTMLTextAreaElement> & {
    rows?: number | string
  }

export function TextArea(props: Props): JSX.Element {
  const {
    value = '',
    name,
    onValueChange,
    placeholder = '',
    disabled = false,
    className = '',
    rows = 4,
    ...otherProps
  } = props

  return (
    <Textarea
      {...otherProps}
      value={value}
      name={name}
      rows={typeof rows === 'string' ? parseInt(rows) : rows}
      id={`tm-${name}`}
      onChange={event => onValueChange(event.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      className={`tm-input ${className}`}
      maxLength={10000}
    />
  )
}
