import { Styles, ThemeWithColors } from '@trustmary/form-styles'
import { colors } from '../../misc/colors'

export function createBaseStyles(theme: ThemeWithColors): Partial<Styles> {
  const styles: Partial<Styles> = {}

  styles.fieldsetFields = {
    display: 'flex',
    flexWrap: 'wrap',
    '> .tm-item, > .tm-field, > .tm-editor-item': {
      width: '100%',
    },
    '@media screen and (max-width: 599px)': {
      '> .tm-item, > .tm-field, > .tm-editor-item': {
        margin: '8px 0',
      },
    },
    '@media screen and (min-width: 600px)': {
      '.tm-fieldset--columns-1 &': {
        '> .tm-item, > .tm-field, > .tm-editor-item': {
          width: '100%',
        },
      },
      '.tm-fieldset--columns-2 &': {
        '> .tm-item, > .tm-field, > .tm-editor-item': {
          width: '50%',
        },
      },
      '.tm-fieldset--columns-3 &': {
        '> .tm-item, > .tm-field, > .tm-editor-item': {
          width: '33.33%',
        },
      },
      '.tm-fieldset--columns-4 &': {
        '> .tm-item, > .tm-field, > .tm-editor-item': {
          width: '25%',
        },
      },
      '.tm-fieldset--columns-5 &': {
        '> .tm-item, > .tm-field, > .tm-editor-item': {
          width: '20%',
        },
      },
    },
  }

  styles.fieldWrapper = { padding: '10px', position: 'relative' }
  styles.fieldDescription = { marginBottom: '20px', color: '#555', fontSize: '16px', lineHeight: '20px' }
  styles.fieldLabelRequired = { marginLeft: '5px', color: colors.requiredColor }
  styles.fieldErrorsWrapper = {
    position: 'absolute',
    top: '100%',
    right: 0,
    marginRight: '5px',
    zIndex: 10,
    display: 'block',
    background: colors.red,
    borderRadius: '2px',
    pointerEvents: 'none',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
    marginTop: '-12px',
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: '6px',
      marginTop: '-5px',
      width: 0,
      height: 0,
      borderLeft: `5px solid transparent`,
      borderRight: `5px solid transparent`,
      borderBottom: `5px solid ${colors.red}`,
    },
  }

  styles.input = {
    display: 'block',
    width: '100%',
    lineHeight: '26px',
    border: '2px solid #cecece',
    padding: '6px 10px',
    borderRadius: '2px',
    color: '#222936',
    '&:hover': {
      borderColor: '#9b9b9b',
    },
    '&:focus': {
      outline: 'none',
      borderColor: theme.primaryColor,
    },
  }

  styles.textarea = {
    display: 'block',
    width: '100%',
    border: '2px solid #cecece',
    padding: '6px 10px',
    borderRadius: '2px',
    color: '#222936',
    resize: 'vertical',
    lineHeight: '22px',
    '&:hover': {
      borderColor: '#9b9b9b',
    },
    '&:focus': {
      outline: 'none',
      borderColor: theme.primaryColor,
    },
  }

  styles.fieldError = { padding: '0 12px', lineHeight: '26px', fontSize: '14px', color: '#fff' }

  /* Checkbox */
  styles.checkboxContainerFootnote = {
    textAlign: 'center',
    '.tm-checkbox': {
      display: 'inline-flex',
      background: '#f7f7f7',
      padding: '5px 15px 5px 10px',
      borderRadius: '20px',
      '.tm-checkbox-icon-wrapper': {
        minHeight: '30px',
        '.tm-checkbox-icon': {
          width: '20px',
          height: '20px',
          borderWidth: '1px',
          borderRadius: '2px',
          display: 'flex',
          svg: {
            width: '12px',
            height: '12px',
            margin: 'auto',
          },
        },
      },
      '.tm-checkbox-label': {
        paddingTop: '5px',
        lineHeight: '20px',
        paddingLeft: '5px',
        fontSize: '14px',
        color: colors.fontColor.toString(),
        opacity: 0.8,
      },
    },
  }
  styles.checkboxContainer = {}
  styles.checkboxWrapper = { display: 'flex', cursor: 'pointer' }
  styles.checkbox = {
    display: 'flex',
    width: '30px',
    minHeight: '40px',
    background: 'none',
    border: 'none',
    padding: 0,
    verticalAlign: 'middle',
    '&:focus': {
      outline: 'none',
    },
    '.tm-checkbox:hover & .tm-checkbox-icon, .tm-checkbox-icon-wrapper:focus & .tm-checkbox-icon': {
      borderColor: theme.primaryColor,
    },

    '.tm-checkbox--selected & .tm-checkbox-icon': {
      background: theme.primaryColor,
      borderColor: theme.primaryColor,
    },
  }
  styles.checkboxIcon = {
    display: 'flex',
    width: '30px',
    height: '30px',
    border: `2px solid ${colors.borderLightGrey.toString()}`,
    borderRadius: '3px',
    background: '#fff',
    margin: 'auto',
    cursor: 'pointer',
  }
  styles.checkboxIconSvg = {
    width: '16px',
    height: '16px',
    margin: 'auto',
  }
  styles.checkboxLabel = { flexGrow: 1, lineHeight: '26px', paddingTop: '7px', paddingLeft: '10px', textAlign: 'left' }
  styles.checkboxLabelRequired = { marginLeft: '5px', color: colors.requiredColor }

  styles.checkboxLabelLinkWrapper = {
    lineHeight: '20px',
  }

  styles.checkboxLabelLink = {
    color: colors.fontColor.toString(),
    textDecorationColor: colors.fontColor.toString(),
    '&:hover': {
      textDecorationColor: colors.orange,
    },
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 2px ${colors.orange}`,
    },
  }

  /* Radios */
  styles.radioButton = {
    display: 'flex',
    cursor: 'pointer',
    width: '100%',
    background: 'none',
    border: 'none',
    padding: 0,
    textAlign: 'left',
    '&:focus': {
      outline: 'none',
    },
  }

  styles.radioIconWrapper = {
    display: 'flex',
    width: '30px',
    minHeight: '40px',
    background: 'none',
    border: 'none',
    padding: 0,
    verticalAlign: 'middle',
    '&:focus': {
      outline: 'none',
    },
  }

  styles.radioIcon = {
    display: 'flex',
    width: '30px',
    height: '30px',
    border: `2px solid ${colors.borderLightGrey.toString()}`,
    borderRadius: '15px',
    background: '#fff',
    margin: 'auto',
    svg: {
      width: '16px',
      height: '16px',
      margin: 'auto',
    },
    '.tm-radio:hover &, .tm-radio:focus &': {
      borderColor: theme.primaryColor,
    },

    '.tm-radio--selected &': {
      background: theme.primaryColor,
      borderColor: theme.primaryColor,
    },
  }

  styles.radioLabel = {
    flexGrow: 1,
    lineHeight: '26px',
    paddingTop: '7px',
    paddingLeft: '10px',
  }

  styles.submitButton = {
    textAlign: 'center',
    position: 'relative',
    fontWeight: 'bold',
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    maxWidth: '100%',
    '&[disabled]': {
      opacity: 0.8,
      '&, &:hover, &:focus': {
        color: colors.tmblue,
        background: '#fff',
      },
    },
  }

  /**
   * Progress bar
   */

  styles.progressBarWrapper = {
    padding: '10px',
  }

  styles.progressBar = {
    display: 'block',
    maxWidth: '360px',
    margin: '0 auto',
    padding: '7px',
    borderRadius: '9px',
  }

  styles.progressBarBackground = {
    position: 'relative',
    height: '6px',
    background: '#dddddd',
    borderRadius: '3px',
  }

  styles.progressBarActive = {
    height: '6px',
    background: theme.primaryColor,
    borderRadius: '3px',
  }

  styles.progressBarNumbers = {
    paddingTop: '10px',
    textAlign: 'center',
    fontWeight: 700,
    color: '#828282',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '1px',
  }

  /**
   * Files
   */

  styles.fileWrapper = {
    display: 'block',
    minHeight: '42px',
    border: `2px solid ${colors.borderLightGrey.toString()}`,
    borderRadius: '2px',
    '&:hover': {
      borderColor: colors.borderGrey.toString(),
    },
  }
  styles.fileUploadWrapper = { position: 'relative', minHeight: '38px' }
  styles.fileUploadButton = {
    display: 'inline-block',
    lineHeight: '26px',
    padding: '0 10px',
    color: colors.fontColor.toString(),
    fontSize: '12px',
    fontWeight: 500,
    background: colors.lightGrey.toString(),
    border: `1px solid ${colors.borderLightGrey.toString()}`,
    borderRadius: '2px',
    margin: '5px',
    cursor: 'pointer',
    svg: {
      marginRight: '6px',
      opacity: '.6',
    },
  }
  styles.fileUploadText = { opacity: '.8', fontSize: '10px' }
  styles.fileUploadInput = {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  }
  styles.fileItem = {
    display: 'flex',
    height: '38px',
  }
  styles.fileItemPreview = { width: '38px', flexGrow: 0, flexShrink: 0, padding: '5px' }
  styles.fileItemPreviewImage = {
    display: 'block',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    objectFit: 'cover',
  }
  styles.fileItemName = {
    flexGrow: 1,
    lineHeight: '20px',
    padding: '8px',
    fontSize: '14px',
    fontWeight: 500,
  }
  styles.fileItemNameInner = {
    fontSize: '10px',
    opacity: 0.5,
  }
  styles.fileItemRemove = {
    width: '38px',
    flexGrow: 0,
    flexShrink: 0,
    padding: '5px',
  }
  styles.fileItemRemoveButton = {
    background: `${colors.lightGrey.toString()}`,
    border: `1px solid ${colors.borderLightGrey.toString()}`,
    padding: 0,
    width: '28px',
    height: '28px',
    borderRadius: '2px',
    lineHeight: '26px',
    color: `${colors.borderGrey.toString()}`,
    display: 'flex',
    cursor: 'pointer',
    fontSize: '12px',
    '&:hover': {
      color: `${colors.red}`,
      borderColor: `${colors.red}`,
    },
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 2px ${colors.orange}`,
    },
    svg: {
      margin: 'auto',
    },
  }

  /* Range */
  styles.rangeArea = {
    display: 'flex',
  }
  styles.rangeAreaFolded = {
    display: 'block',
  }
  styles.rangeWrapper = {
    flex: '1',
  }
  styles.rangeInner = {
    display: 'flex',
    flexDirection: 'row-reverse',
  }
  styles.rangeInnerFolded = {
    display: 'block',
  }
  styles.rangeItem = {
    display: 'block',
    width: '100%',
    flexGrow: 1,
    padding: '0 5px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:last-child': {
      paddingLeft: 0,
    },
    '&:first-of-type': {
      paddingRight: 0,
    },
  }
  styles.rangeItemFolded = {
    padding: '0 0 5px',
    '&:last-child': {
      padding: 0,
    },
  }

  styles.rangeItemButton = {
    display: 'block',
    width: '100%',
    border: `1px solid ${colors.borderLightGrey.toString()}`,
    borderRadius: '3px',
    background: '#fff',
    boxShadow: '0 2px 4px rgb(0 0 0 / 7%)',
    padding: '10px',
    color: colors.fontColor.toString(),
    height: '40px',
    position: 'relative',
    '.tm-range-item:hover &': {
      borderColor: theme.main,
    },
    '.tm-range-item:focus &': {
      boxShadow: `0 0 0 2px ${theme.main}`,
      borderColor: theme.main,
    },
    '.tm-selected &, .tm-selected:focus &, .tm-selected:hover &': {
      background: theme.main,
      borderColor: theme.dark,
      color: '#fff',
    },
    '.tm-over &': {
      background: colors.lightGrey.darken(5, true).toString(),
    },
  }
  styles.rangeItemButtonFolded = {
    textAlign: 'left',
    fontSize: '15px',
    padding: '10px 15px',
    '.tm-range-no-opinion-item &': {
      paddingLeft: '30px',
    },
  }
  styles.rangeLabels = {
    marginTop: '20px',
    color: colors.fontColor.toString(),
    display: 'flex',
    lineHeight: '20px',
    '.form-fieldset &': {
      marginTop: '10px',
    },
  }
  styles.rangeLabelsFolded = {
    display: 'none',
  }
  styles.rangeLabelsMin = {
    display: 'block',
    width: '50%',
    textAlign: 'left',
  }
  styles.rangeLabelsMax = {
    display: 'block',
    width: '50%',
    textAlign: 'right',
  }
  styles.rangeNoOpinion = {
    paddingLeft: '10px',
    marginLeft: '10px',
    borderLeft: '1px solid #efefef',
    width: '66px',
  }
  styles.rangeNoOpinionFolded = {
    borderLeft: undefined,
    borderTop: '1px solid #efefef',
    width: '100%',
    padding: '10px 0 0',
    margin: '10px 0 0',
  }
  styles.rangeNoOpinionItem = {}
  styles.rangeNoOpinionItemIcon = {
    display: 'inline-flex',
    width: '18px',
    height: '18px',
    background: '#e7e7e7',
    border: '1px solid #c4c4c4',
    borderRadius: '10px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-9px 0 0 -9px',
    '.tm-selected &': {
      background: '#fff',
      borderColor: theme.dark,
    },
    svg: {
      width: '12px',
      height: '12px',
      margin: 'auto',
    },
  }
  styles.rangeNoOpinionLabel = {
    marginTop: '20px',
    position: 'relative',
    height: '20px',
  }
  styles.rangeNoOpinionLabelText = {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '13px',
    textAlign: 'center',
    color: '#222835',
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    transform: 'translateY(-50%)',
    opacity: '.6',
  }
  styles.rangeMobileLabel = {
    display: 'inline-block',
    color: '#000',
    opacity: '.6',
    marginLeft: '6px',
    '&:before': {
      content: '"- "',
      opacity: 0.6,
    },
    '.tm-selected &': {
      color: '#fff',
    },
  }

  /* Stars */

  styles.starsWrapper = {
    display: 'inline-block',
    marginLeft: '-6px',
  }
  styles.stars = { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }
  styles.starsItem = {
    background: 'none',
    border: 'none',
    color: colors.borderLightGrey.toString(),
    display: 'block',
    cursor: 'pointer',
    padding: '6px',
    textAlign: 'center',
    borderRadius: '3px',
    '&.tm-stars-item--selected, &.tm-stars-item--under': {
      color: colors.orange,
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 2px 2px rgba(21, 156, 228, .7)', //'0 0 2px 2px rgba(#159ce4, .7)',
    },
  }
  styles.starsItemIcon = {
    fontSize: '3em',
    '@media screen and (max-width: 700px)': {
      fontSize: '2.6em',
    },
    '@media screen and (max-width: 300px)': {
      fontSize: '1.8em',
    },
  }

  styles.starsLabels = {
    marginTop: '16px',
    color: colors.fontColor.toString(),
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '20px',
    padding: '0 6px',
    '.form-fieldset &': {
      marginTop: '10px',
    },
  }
  styles.starsLabelsItem = {
    width: '50%',
  }

  return styles
}
