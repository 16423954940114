import { ViewProps } from '../types'
import { DefaultView } from './DefaultView'
import { Field } from '@tm/shared-lib/src/field'
import { useTitle } from '@tm/client-form/src/hooks/useTitle'

/**
 * Flatten fields to get first field label
 * if first question is fieldset
 */
const flattenFields = (fields: Field[]): Field[] => {
  return fields.reduce((acc, field) => {
    if ('fields' in field && field.fields) {
      return [...acc, ...flattenFields(field.fields)]
    }

    return [...acc, field]
  }, [] as Field[])
}

const getTitle = (fields: Field[], lang: string) => {
  const [firstField] = flattenFields(fields)
  if (!firstField) return ''

  if ('label' in firstField && firstField.label) {
    return firstField.label[lang] || ''
  }

  if ('inputLabel' in firstField && firstField.inputLabel) {
    return firstField.inputLabel[lang] || ''
  }

  return ''
}

export function DefaultLoader(props: ViewProps) {
  const { fields, state } = props
  useTitle(getTitle(fields, state.lang))
  return <DefaultView {...props} />
}
