import { createContext } from 'react'
import { Action } from '../hooks/useSurvey/actions/action'
import { permanentError } from '../hooks/useSurvey/states/error'
import { State } from '../hooks/useSurvey/states/state'
export const SurveyStateContext = createContext<[State, React.Dispatch<Action>]>([
  permanentError,
  () => {
    throw new Error('You must provide SurveyStateContext to update values')
  },
])
