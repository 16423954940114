import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { FontAwesomeSvgIcon } from '@tm/ui/composite/FontAwesomeSvgIcon'
import { UsePictureField } from './types'

const ContactInfoPicturePreview = ({
  value,
  imagePlaceholder,
}: {
  value: File | File[] | undefined
  imagePlaceholder: string
}) => {
  const file = Array.isArray(value) ? value[0] : value
  const preview = file ? URL.createObjectURL(file) : imagePlaceholder
  return <img src={preview} />
}

export function ContactInfoPictureField(props: UsePictureField) {
  const { value, onChange, label, chooseImageButtonText, imagePlaceholder } = props
  const hasValue = !!value
  return (
    <Stack gap={1}>
      <Typography component="label" htmlFor="picture-input" variant="caption">
        {label}
      </Typography>
      <Stack direction="row" gap={1} alignItems="center">
        <Box
          sx={{
            position: 'relative',
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'grey.200',
            border: theme => `1px solid ${theme.palette.divider}`,
            img: {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              objectFit: 'cover',
            },
          }}>
          <ContactInfoPicturePreview value={value} imagePlaceholder={imagePlaceholder} />
        </Box>
        <Box
          sx={{
            position: 'relative',
            cursor: 'pointer',
            '&:hover .MuiButton-outlined': {
              boxShadow: theme => theme.customShadows.z4,
            },
          }}>
          <Button variant="outlined" color="inherit">
            {chooseImageButtonText}
          </Button>
          <Box
            component="input"
            type="file"
            onChange={event => onChange && onChange(event.target.files)}
            accept=".jpg,.jpeg,.png,.gif,.wepm"
            id="picture-input"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              cursor: 'pointer',
              opacity: 0,
            }}
          />
        </Box>
        {hasValue && (
          <IconButton onClick={() => onChange && onChange(null)} size="small">
            <FontAwesomeSvgIcon icon={faTimes} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}
