import { addMonths, parseISO, setDate, startOfDay } from 'date-fns'
import { CustomValue, isCustomObj, StepValues, StepValuesIndex } from './api'
import { Field } from './field'

export const splitToChunks = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = []
  for (let index = 0; index < array.length; index += chunkSize) {
    chunks.push(array.slice(index, index + chunkSize))
  }

  return chunks
}

export function getStepValueByString(values: StepValuesIndex, field: string): CustomValue | undefined {
  if (!field) return undefined

  const path = field.split('.')
  if (path.length === 2 && path[0] === 'custom') {
    const name = path[1]
    if (values.custom) return values.custom[name]
  } else if (path.length === 1 && path[0] !== 'custom') {
    const name = path[0]
    const value = values[name]
    if (!isCustomObj(value)) return value
  }
  return undefined
}

export function setStepValueByString(values: StepValuesIndex, field: string, value: CustomValue): StepValues {
  const path = field.split('.')
  if (path.length === 2 && path[0] === 'custom') {
    const name = path[1]
    if (!values.custom) values.custom = {}
    values.custom[name] = value
  } else if (path.length === 1 && path[0] !== 'custom') {
    const name = path[0]
    values[name] = value
  }
  return values
}

export function getStepValueByField(values: StepValuesIndex, field: Field): [string, CustomValue | undefined] {
  if (field.system) {
    const res = values[field.name]
    if (!isCustomObj(res)) {
      return [field.name, res]
    }
  } else {
    if (values.custom) return [`custom.${field.name}`, values.custom[field.name]]
  }

  return [values.custom ? `custom.${field.name}` : field.name, undefined]
}

export function getStepValueForField(values: StepValuesIndex, field: Field): StepValuesIndex {
  if (field.system) {
    const val = values[field.name]
    if (val !== undefined && !isCustomObj(val)) {
      return { [field.name]: val }
    }
  } else {
    const val = values.custom?.[field.name]
    if (val !== undefined) return { custom: { [field.name]: val } }
  }

  return {}
}

export function getCustomValueForField(values: StepValuesIndex, field: Field): CustomValue | undefined {
  if (field.system) {
    const value = values[field.name]
    if (!isCustomObj(value)) return value
  } else if (values.custom) {
    return values.custom[field.name]
  }

  return undefined
}

export function setStepValueByField(values: StepValuesIndex, field: Field, value: CustomValue): StepValuesIndex {
  if (field.system) values[field.name] = value
  else {
    if (!values.custom) values.custom = {}
    values.custom[field.name] = value
  }
  return values
}

/**
 * Takes organization's limit check date and transforms it to a monthly variant
 * relative to current date. This is done because collect plans can have a yearly limit,
 * but display plans only have a monthly limit.
 * @param limitCheckDate The date when limits are reseted.
 * @returns Date that is used for checking widget limits.
 */
export function transformLimitCheckDateForWidgets(limitCheckDate: Date | string) {
  const today = startOfDay(new Date())
  const checkDate = typeof limitCheckDate === 'string' ? parseISO(limitCheckDate) : limitCheckDate
  return today.getDate() >= checkDate.getDate()
    ? setDate(addMonths(today, 1), checkDate.getDate())
    : setDate(today, checkDate.getDate())
}
