import { InputProps, numberValue, stringValue } from './types'
import { RangeField } from '@tm/shared-lib/src/field'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import { CustomValue } from '@tm/shared-lib/src/api'
import { useCampaign } from '@tm/client-form/src/hooks/useCampaign'
import { getTranslatedValue } from './getTranslatedValue'

const NO_OPINION = '_no'

type RangeInnerProps = {
  value?: number | typeof NO_OPINION
  onChange?: (value: CustomValue) => void
  disabled?: boolean
  minLabel: string
  minValue: number
  maxLabel: string
  maxValue: number
  rangeNoOpinion?: boolean
  rangeNoOpinionLabel: string
}

export function RangeInputInner(props: RangeInnerProps) {
  const { value, onChange, disabled, maxValue, minValue, minLabel, maxLabel, rangeNoOpinion, rangeNoOpinionLabel } =
    props
  const { primaryColor, primaryTextColor } = useCampaign()

  const options: number[] = []
  for (let i = minValue; i <= maxValue; i++) options.push(i)

  return (
    <Stack gap={2} width="100%">
      <Stack direction="row" justifyContent={{ xs: 'center', sm: 'center' }}>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {`${minValue} = ${minLabel}, ${maxValue} = ${maxLabel}`}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={1}
        justifyContent={{
          xs: 'center',
          sm: 'center',
        }}>
        {options.map(option => {
          const isSelected = option === value
          return (
            <Button
              key={option}
              onClick={() => onChange && onChange(option)}
              color={isSelected ? 'primary' : 'inherit'}
              variant="contained"
              disabled={disabled}
              sx={{
                minWidth: {
                  xs: '36px',
                  sm: '40px',
                },
                height: {
                  xs: '36px',
                  sm: '40px',
                },
                px: 0,
                textAlign: 'center',
                ...(isSelected && {
                  backgroundColor: primaryColor,
                  color: primaryTextColor,
                  '&:hover': {
                    backgroundColor: primaryColor,
                  },
                  '&:active': {
                    backgroundColor: primaryColor,
                  },
                }),
              }}>
              {option}
            </Button>
          )
        })}
      </Stack>
      {!!rangeNoOpinion && (
        <Stack alignItems="center">
          <RadioGroup value={stringValue(value)} onChange={() => onChange && onChange(NO_OPINION)}>
            <FormControlLabel value={NO_OPINION} control={<Radio />} label={rangeNoOpinionLabel} disabled={disabled} />
          </RadioGroup>
        </Stack>
      )}
    </Stack>
  )
}

export function RangeInput(props: InputProps<RangeField>) {
  const { field, value, onChange, isSubmitting, lang } = props
  const { rangeMin, rangeMax, rangeMinLabel, rangeMaxLabel, rangeNoOpinion, rangeNoOpinionLabel } = field

  return (
    <RangeInputInner
      value={value === NO_OPINION ? value : numberValue(value)}
      onChange={onChange}
      disabled={isSubmitting}
      minLabel={getTranslatedValue(rangeMinLabel, lang)}
      minValue={parseInt(rangeMin)}
      maxLabel={getTranslatedValue(rangeMaxLabel, lang)}
      maxValue={parseInt(rangeMax)}
      rangeNoOpinion={rangeNoOpinion}
      rangeNoOpinionLabel={getTranslatedValue(rangeNoOpinionLabel, lang)}
    />
  )
}
