import { createStyles, Styles, ThemeWithColors } from '@trustmary/form-styles'
import { createBaseStyles } from './baseStyles'
// import { colors } from '../../misc/colors'

export function createFrameStyles(theme: ThemeWithColors): Styles {
  const baseStyles = createBaseStyles(theme)
  const styles: Partial<Styles> = { ...baseStyles }

  styles.form = {
    padding: '4px 10px',
  }

  styles.fieldWrapper = {
    ...baseStyles.fieldWrapper,
    margin: '0 0 15px',
    padding: '8px',
    '.tm-fieldset &': {
      margin: '5px 0',
    },
    '@media screen and (max-width: 600px)': {
      padding: 0,
      '.tm-field--fieldset &': {
        padding: '0px 8px',
      },
    },
    '&.tm-field--fieldset': {
      padding: 0,
    },
  }

  styles.fieldsetFields = {
    ...baseStyles.fieldsetFields,
    '@media screen and (max-width: 600px)': {
      margin: '0 -8px',
    },
  }

  styles.fieldLabel = {
    display: 'block',
    fontSize: '16px',
    fontWeight: 500,
    margin: '0 0 10px 0',
  }

  /* Submit */
  styles.submitWrapper = {
    textAlign: 'center',
  }

  styles.submitButton = {
    ...baseStyles.submitButton,
    background: theme.primaryColor,
    color: '#ffffff',
    display: 'inline-block',
    margin: 0,
    borderRadius: '20px',
    borderColor: theme.primaryColor,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 18px',
    '&:hover': {
      background: '#fff',
      color: theme.primaryColor,
      borderColor: theme.primaryColor,
      opacity: 1,
    },
  }

  styles.submitButtonIcon = {
    display: 'inline-block',
    fontSize: '12px',
    marginRight: 'calc(4px - 0.125em)',
    marginLeft: 'calc(-0.125em - 8px)',
  }

  return createStyles(styles)
}
