import { RangeField } from '@tm/shared-lib/src/field'
import React from 'react'
import { useSurveyValue } from '../../hooks/useSurveyValue'
import { Range } from '../field/Range'

interface Props {
  field: RangeField
}

export function RangeQuestion({ field }: Props): JSX.Element {
  const { rangeMin, rangeMax } = field

  const [value, setValue] = useSurveyValue(field)
  if (value !== null && value !== undefined && typeof value !== 'string' && typeof value !== 'number') {
    throw new Error('Range field does not support value: ' + value)
  }
  return (
    <Range
      value={value}
      onValueChange={setValue}
      {...field}
      rangeMax={parseInt(rangeMax)}
      rangeMin={parseInt(rangeMin)}
    />
  )
}
