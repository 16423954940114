import { useMemo } from 'react'
import { CustomValue } from '@tm/shared-lib/src/api'
import { useSurveyState } from './useSurveyState'

export function useSurveyValue(field: {
  name: string
  system?: boolean
}): [CustomValue | undefined, (value: CustomValue) => void] {
  const [state, dispatch] = useSurveyState()
  const value = 'getValue' in state ? state.getValue(field) : undefined

  return useMemo(() => {
    const setValue = (newValue: CustomValue) => dispatch({ type: 'set value', field: field, value: newValue })
    return [value, setValue]
  }, [value, dispatch, field])
}
