import { useMemo } from 'react'
import { ThemeWithColors, Styles } from '@trustmary/form-styles'
import { createFrameStyles } from './surveyStyles/frameStyles'
import { createPageStyles } from './surveyStyles/pageStyles'

interface SurveyStylesOut {
  surveyStyles: Styles
}

export function useSurveyStyles(theme: ThemeWithColors): SurveyStylesOut {
  const surveyStyles = useMemo<Styles>(() => {
    if (theme.view === 'frame') return createFrameStyles(theme)

    return createPageStyles(theme)
  }, [theme])

  return { surveyStyles }
}
