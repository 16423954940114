import { useCallback, useEffect, useMemo, useState } from 'react'
import { ViewProps } from '../types'
import { ReviewFields } from './useReviewFields'

/**
 * Hook to handle noPublicConsent or noVideoRecord skip checkbox
 * and then submit form
 */
export function useSkipButton(props: ViewProps, reviewFields: ReviewFields) {
  const { state, dispatch, submitForm } = props
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const skipField = useMemo(() => {
    if (reviewFields.get('noPublicConsent')) return reviewFields.get('noPublicConsent')
    if (reviewFields.get('noVideoRecord')) return reviewFields.get('noVideoRecord')
    return null
  }, [reviewFields])

  const value = skipField && 'getValue' in state ? state.getValue(skipField) : undefined

  const handleSkipClick = useCallback(() => {
    if (!skipField) return
    dispatch({ type: 'set value', field: skipField, value: true })
    setShouldSubmit(true)
  }, [skipField, dispatch])

  /**
   * We need to wait that state updates with correct value for skip field
   * and then we can submit form
   */
  useEffect(() => {
    if (!shouldSubmit) return
    if (!value) return
    submitForm()
  }, [submitForm, shouldSubmit, value])

  return {
    skipField,
    handleSkipClick,
  }
}

export type SkipButtonState = ReturnType<typeof useSkipButton>
