import { WidgetField } from '@tm/types/survey'
import { styled } from 'goober'
import React, { useEffect } from 'react'
const basePath = process.env.WIDGET_SCRIPT_URL || 'https://widget.trustmary.com/'
const WidgetLabel = styled('div')(({ theme }) => theme.styles.fieldLabel)
interface Props {
  field: WidgetField
}

export const WidgetEmbed = ({ field }: Props) => {
  const { widgetId } = field
  useEffect(() => {
    if (!widgetId) return
    //set hasTrustmaryPopupFromTag to prevent popups from loading
    window['hasTrustmaryPopupFromTag'] = true
    const parentElement = document.getElementById('tm-survey-widget-script')
    const scriptElement = document.createElement('script')
    scriptElement.src = basePath + widgetId
    parentElement?.appendChild(scriptElement)
  }, [])

  return widgetId ? (
    <>
      {field.label && <WidgetLabel>{field.label}</WidgetLabel>}
      <div id="tm-survey-widget-script"></div>
    </>
  ) : null
}
