import { createStyles, Styles, ThemeWithColors } from '@trustmary/form-styles'
import { createBaseStyles } from './baseStyles'
import { CSSAttribute } from 'goober'
import { colors } from '../../misc/colors'

export function createPageStyles(theme: ThemeWithColors): Styles {
  const baseStyles = createBaseStyles(theme)

  const styles: Partial<Styles> = { ...baseStyles }

  styles.fieldsetFields = {
    ...baseStyles.fieldsetFields,
    margin: '0 -10px',
  }

  styles.fieldWrapper = {
    ...baseStyles.fieldWrapper,
    margin: '20px 0',
  }

  styles.fieldLabel = {
    textAlign: 'left',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: 1.25,
    letterSpacing: '.3px',
    display: 'block',
    margin: '0 0 20px 0',
    cursor: 'pointer',
    color: colors.fontColor.darken(2, true).toString(),
    '.tm-fieldset &': {
      fontSize: '16px',
      fontWeight: 500,
      margin: '0 0 10px 0',
    },
  }

  styles.fieldDescription = {
    ...baseStyles.fieldDescription,
    '.tm-field-label + &': {
      marginTop: '-10px',
    },
  }

  /* Submit */
  styles.submitWrapper = {
    backgroundColor: theme.style === 'color' ? theme.primaryColor : '#ffffff',
    textAlign: 'center',
    color: '#fff',
    padding: '30px',
    borderTop: theme.style === 'contrast' ? '1px solid #dddddd' : undefined,
  }

  const submitButtonCommon: CSSAttribute = {
    ...baseStyles.submitButton,
    width: '220px',
    height: '50px',
    display: 'block',
    margin: '0 auto',
    borderRadius: '30px',
    fontSize: '16px',
  }

  if (theme.style === 'color') {
    styles.submitButton = {
      ...submitButtonCommon,
      background: '#ffffff',
      borderColor: '#fff',
      color: theme.primaryColor,
      '&:hover': {
        color: '#fff',
        background: theme.primaryColor,
        opacity: 1,
      },
    }
  } else {
    styles.submitButton = {
      ...submitButtonCommon,
      background: theme.primaryColor,
      borderColor: theme.primaryColor,
      color: '#ffffff',
      boxShadow: '0 1px 6px rgba(0,0,0,0.1)',
      '&:hover': {
        background: '#fff',
        color: theme.primaryColor,
        borderColor: theme.primaryColor,
        opacity: 1,
      },
    }
  }

  styles.submitButtonIcon = {
    position: 'absolute',
    top: '16px',
    left: '16px',
  }

  return createStyles(styles)
}
