import { CSSAttribute, styled } from 'goober'
import React, { useEffect, useRef } from 'react'

export const Menu = ({
  children,
  onClickAway,
  className,
}: {
  children: React.ReactChild
  onClickAway?: () => void
  className?: string
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const clickAwayListener = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement) {
        let currentElement: HTMLElement | null = e.target
        while (currentElement !== null) {
          if (currentElement === ref.current) {
            return
          }
          currentElement = currentElement.parentElement
        }
        if (onClickAway) onClickAway()
      }
    }
    document.addEventListener('click', clickAwayListener)
    return () => {
      document.removeEventListener('click', clickAwayListener)
    }
  }, [onClickAway])
  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
}

export const AnchoredMenu = styled(Menu)<{
  anchorEl: HTMLElement
  anchorOrigin?: { vertical: 'top' | 'bottom' | 'center'; horizontal: 'left' | 'right' | 'center' }
  transformDirection?: { vertical: 'up' | 'down'; horizontal: 'left' | 'right' }
  offset?: { x?: number; y?: number }
}>(props => {
  const {
    anchorEl,
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    transformDirection = { vertical: 'down', horizontal: 'right' },
    offset = { x: 0, y: 5 },
  } = props
  if (offset.x === undefined) offset.x = 0
  if (offset.y === undefined) offset.y = 0
  const box = anchorEl.getBoundingClientRect()
  const res: CSSAttribute = { position: 'fixed', zIndex: 3 }
  const anchor = {
    x:
      anchorOrigin.horizontal === 'right'
        ? box.right
        : anchorOrigin.horizontal === 'left'
        ? box.left
        : box.left + box.width / 2,
    y:
      anchorOrigin.vertical === 'top'
        ? box.top
        : anchorOrigin.vertical === 'bottom'
        ? box.bottom
        : box.top + box.height / 2,
  }
  if (transformDirection.vertical === 'up') res.bottom = `${window.document.body.clientWidth - anchor.y + offset.y}px`
  else res.top = `${anchor.y + offset.y}px`

  if (transformDirection.horizontal === 'right') res.left = `${anchor.x + offset.x}px`
  else res.right = `${window.document.body.clientWidth - anchor.x + offset.x}px`

  return res
})
