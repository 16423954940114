import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp'
import { ContentField } from '@tm/shared-lib/src/field'
import { Icon } from '@trustmary/icons'
import { keyframes, styled } from 'goober'
import React from 'react'
import { useI18N } from '../../hooks/useI18n'
import { useSurveyValue } from '../../hooks/useSurveyValue'

const thumbUp = keyframes({
  '0%': {
    opacity: 0,
  },
  '60%': {
    transform: 'rotate(-6deg) translate3d(-2px, -5px, 0) scale(1.1)',
    opacity: 0,
  },
  '85%': {
    transform: 'rotate(3deg) translate3d(3px, 5px, 0) scale(1.2)',
    opacity: 1,
  },
  '100%': {
    transform: 'none',
  },
})

const VideoIntroHeader = styled('div')({
  textAlign: 'center',
})
const VideoIntroHeaderTitle1 = styled('div')({
  fontSize: '18px',
  lineHeight: '24px',
  color: '#5a6374',
})
const VideoIntroHeaderTitleIcon = styled('span')({
  color: '#54d62b',
  marginRight: '10px',
  animation: `${thumbUp} 1s ease-in-out`,
})

const VideoIntroHeaderTitle2 = styled('div')({
  maxWidth: '460px',
  margin: '15px auto 0',
  fontSize: '30px',
  lineHeight: '32px',
  color: '#364155',
})

const VideoIntroQuote = styled('div')({
  position: 'relative',
  display: 'flex',
  minHeight: '200px',
  maxWidth: '600px',
  margin: '40px auto 0',
  border: `1px solid #cecece`,
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
  color: '#364155',
  borderRadius: '3px',
  padding: '0 20px',
  '&:before': {
    content: '" "',
    position: 'absolute',
    top: '100%',
    left: '50%',
    border: '16px solid transparent',
    borderTopColor: '#fff',
    zIndex: 2,
    marginLeft: '-8px',
  },
  '&:after': {
    content: '" "',
    position: 'absolute',
    top: '100%',
    left: '50%',
    border: '18px solid transparent',
    borderTopColor: '#cecece',
    marginLeft: '-10px',
  },
})

const VideoIntroQuoteComment = styled('div')({
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  fontSize: '20px',
  lineHeight: '24px',
  margin: 'auto',
  '.tm-video-intro-comment--small &': {
    fontSize: '16px',
    lineHeight: '20px',
  },
})

const VideoIntroQuoteCommentP = styled('p')({
  margin: '0 0 20px',
  '&:last-child': {
    margin: 0,
  },
})

const CommentBody = styled('div')({
  color: '#222937',
  h1: {
    fontSize: '30px',
    lineHeight: '1.2',
    letterSpacing: '.3px',
    margin: '0 0 15px',
  },
  h2: {
    fontSize: '24px',
    lineHeight: '1.2',
    letterSpacing: '.3px',
    margin: '0 0 15px',
  },
  h3: {
    fontSize: '20px',
    margin: '0 0 15px',
  },
  img: {
    display: 'inline-block',
    maxWidth: '100%',
    height: 'auto',
  },
  p: {
    margin: '0 0 0.7em',
    lineHeight: '1.3',
  },
  ol: {
    margin: '10px 0 15px',
    padding: '0 0 0 20px',

    li: {
      lineHeight: '20px',
      marginBottom: '4px',
    },
  },
  ul: {
    margin: '10px 0 15px',
    padding: 0,
  },
  'ul li': {
    counterIncrement: 'li-count',
    position: 'relative',
    marginLeft: '20px',
    lineHeight: '20px',
    marginBottom: '4px',
  },
  'li.ql-indent-1': {
    marginLeft: '20px',
  },
  'li.ql-indent-2': {
    marginLeft: '35px',
  },
  'li.ql-indent-3': {
    marginLeft: '50px',
  },
})

function VideoIntro() {
  const { t } = useI18N()
  const [comment] = useSurveyValue({ name: 'comment', system: true })
  const commentText = typeof comment === 'string' ? comment : ''
  const textSize = commentText.length > 159 ? 'small' : 'large'

  return (
    <div className="tm-video-intro">
      <div className="tm-video-intro-inner">
        <VideoIntroHeader className="tm-video-intro-header">
          <VideoIntroHeaderTitle1 className="tm-video-intro-header-t1">
            <VideoIntroHeaderTitleIcon className="animate-thumb-up">
              <Icon icon={faThumbsUp} />
            </VideoIntroHeaderTitleIcon>
            {t('videoIntro.title1')}
          </VideoIntroHeaderTitle1>
          <VideoIntroHeaderTitle2 className="tm-video-intro-header-t2">
            {t('videoIntro.title2')
              .split('\n')
              .filter(s => s.trim() !== '')
              .map((s, k) => (
                <div key={k}>{s}</div>
              ))}
          </VideoIntroHeaderTitle2>
        </VideoIntroHeader>
        <VideoIntroQuote className="tm-video-intro-quote">
          <VideoIntroQuoteComment className={`tm-video-intro-comment tm-video-intro-comment--${textSize}`}>
            <div className="tm-video-intro-comment-inner">
              {commentText
                .split('\n')
                .filter(s => s.trim() !== '')
                .map((s, k) => (
                  <VideoIntroQuoteCommentP key={k}>{s}</VideoIntroQuoteCommentP>
                ))}
            </div>
          </VideoIntroQuoteComment>
        </VideoIntroQuote>
      </div>
    </div>
  )
}

interface Props {
  field: ContentField
}

export function ContentQuestion({ field }: Props): JSX.Element | null {
  const { lang } = useI18N()
  const { content = {}, videoIntro = false } = field

  if (videoIntro) return <VideoIntro /> //<VideoIntro {...props} />

  const contentStr = content[lang]

  if (!contentStr) return null

  return (
    <div className="tm-content">
      <CommentBody
        className="tm-content-body"
        dangerouslySetInnerHTML={{
          __html: contentStr,
        }}
      />
    </div>
  )
}
