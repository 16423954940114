import { useVideoContext } from './VideoContext'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'

export function VideoContainer(props: { children: React.ReactNode; sx?: SxProps }) {
  const { containerHeight } = useVideoContext()

  return (
    <Box
      sx={{
        width: '100%',
        height: `${containerHeight}px`,
        maxHeight: '70vh',
        position: 'relative',
        ...props.sx,
      }}>
      {props.children}
    </Box>
  )
}
