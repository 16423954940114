import { Field } from '@tm/shared-lib/src/field'
import { ValidationErrors } from '@tm/shared-lib/src/validation'
import React, { Fragment } from 'react'
import { Question } from '../question/Question'

interface Props {
  fields: Field[]
  validationErrors?: ValidationErrors
}

export function SurveyFields({ fields, validationErrors = {} }: Props): JSX.Element {
  return (
    <Fragment>
      {fields.map((field, index) => {
        return <Question key={index} field={field} validationErrors={validationErrors} />
      })}
    </Fragment>
  )
}
