// Force build counter: 2
import { createTheme, loadGoogleFont, StylesContext, ThemeWithColors } from '@trustmary/form-styles'
import { styled } from 'goober'
import 'promise-polyfill/src/polyfill'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { Page } from './components/page/Page'
import { ThemeContext } from './context/themeContext'
import { useGlobalStyles } from './globalStyles'
import { useSurveyStyles } from './hooks/useSurveyStyles'
import './misc/styled'

export const StyledPage = styled('div')<{ $frame: boolean; fontFamily: string }>(({ $frame, fontFamily }) =>
  $frame
    ? {}
    : {
        fontFamily,
        padding: '20px 0 50px',
      }
)

const themeReducer = (state: ThemeWithColors, action: ThemeWithColors | undefined) => {
  return action ? action : createTheme()
}

export const FormRoot = () => {
  const locationQuery = useMemo(() => queryString.parse(location.search), [])

  const frameId = locationQuery.frame

  const view = frameId ? 'frame' : 'page'

  const [theme, themeDispatch] = useReducer(themeReducer, createTheme({ view: view }))
  const { surveyStyles } = useSurveyStyles(theme)

  const onResize = useCallback(() => {
    try {
      parent.postMessage(
        {
          frameId,
          type: 'resize',
          attr: {
            height: document.body.offsetHeight,
          },
        },
        '*'
      )
    } catch (err) {
      console.log(err)
    }
  }, [frameId])

  useEffect(() => {
    if (view === 'frame') {
      onResize()
      window.addEventListener('resize', onResize)
      return () => {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [view, onResize])

  useEffect(() => {
    if (theme.fontSource === 'google') {
      loadGoogleFont(theme.fontFamily)
    }
  }, [theme.fontSource, theme.fontFamily])

  // Setup global styles
  useGlobalStyles(theme)

  const stylesContextValue = useMemo(
    () => ({
      styles: surveyStyles,
      values: theme,
    }),
    [surveyStyles, theme]
  )

  return (
    <StylesContext.Provider value={stylesContextValue}>
      <ThemeContext.Provider value={theme}>
        <StyledPage
          $frame={view === 'frame'}
          fontFamily={theme.fontFamily}
          className={`page page--${view === 'page' ? 'default' : view}`}>
          <Page themeDispatch={themeDispatch} onResize={onResize} />
        </StyledPage>
      </ThemeContext.Provider>
    </StylesContext.Provider>
  )
}

export default FormRoot
