import { faPauseCircle } from '@fortawesome/free-regular-svg-icons/faPauseCircle'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons/faPlayCircle'
import Icon from '@trustmary/icons'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useI18N } from '@tm/client-form/src/hooks/useI18n'
import { useVideoContext } from './VideoContext'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { VideoContainer } from './VideoContainer'

export function VideoPreview({ file }: { file: File | string }) {
  const { onChange } = useVideoContext()
  const { t } = useI18N()
  const [playing, setPlaying] = useState<boolean>(false)

  const videoRef = useRef<HTMLVideoElement>(null)

  const play = () => {
    if (videoRef.current) {
      void videoRef.current.play()
    }
  }

  const pause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      const video: HTMLVideoElement = videoRef.current
      video.currentTime = 0
      video.onplay = () => {
        setPlaying(true)
      }
      video.onpause = () => {
        setPlaying(false)
      }
    }
  }, [setPlaying])

  const videoEl = useMemo(() => {
    return (
      <Box
        component="video"
        playsInline
        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
        controls={false}
        ref={videoRef}
        onClick={e => {
          e.preventDefault()
          if (e.currentTarget.paused) {
            play()
          } else {
            pause()
          }
        }}
        sx={{
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: {
            xs: 0,
            sm: 1,
          },
          cursor: 'pointer',
        }}
      />
    )
  }, [file])

  return (
    <>
      <VideoContainer>
        {videoEl}
        <Box
          sx={{
            position: 'absolute',
            zIndex: 3,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#FFFFFF',
            fontSize: '80px',
            pointerEvents: 'none',
            trasition: 'opacity 0.3s ease-in-out',
            '@media (max-width: 900px)': {
              fontSize: '50px',
            },
            ...(playing && {
              opacity: 0,
              '&:hover': {
                opacity: 1,
              },
            }),
          }}>
          {playing ? <Icon icon={faPauseCircle} /> : <Icon icon={faPlayCircle} />}
        </Box>
      </VideoContainer>
      <Stack direction={{ sm: 'row' }} justifyContent="space-between" alignItems="center" p={2} gap={1}>
        <Typography variant="body2" color="grey.300">
          {t('video2.preview.readyOrRetake')}
        </Typography>
        <Button
          onClick={() => onChange(undefined)}
          variant="outlined"
          color="inherit"
          size="small"
          sx={{
            color: 'grey.300',
            borderColor: 'grey.500',
            textWrap: 'nowrap',
            '&:hover': {
              borderColor: 'grey.300',
            },
          }}>
          {t('video2.preview.retakeButton')}
        </Button>
      </Stack>
    </>
  )
}
