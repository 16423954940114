import { InputProps, numberValue } from './types'
import { StarsField } from '@tm/shared-lib/src/field'
import Rating from '@mui/material/Rating'
import Stack from '@mui/material/Stack'

export function StarsInput(props: InputProps<StarsField>) {
  const { field, value, onChange, isSubmitting } = props
  const { name } = field

  return (
    <Stack
      id="stars-question"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Rating
        value={numberValue(value, -1)}
        size="large"
        disabled={isSubmitting}
        precision={1}
        max={5}
        name={`q-${name || 'question'}`}
        sx={{
          '.MuiRating-icon svg': {
            width: { xs: 48, sm: 60 },
            height: { xs: 48, sm: 60 },
          },
        }}
        onChange={(_event, newValue) => {
          if (!onChange) return
          // In Rating, if current value is clicked it will set value to null
          // and in surveys we want to let user unset value
          // User will have current value if they return to the form
          onChange(newValue === null && typeof value === 'number' ? value : newValue)
        }}
      />
    </Stack>
  )
}
