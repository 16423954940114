import { styled } from 'goober'
import React from 'react'
import { useTheme } from '../../hooks/useTheme'
import { colors } from '../../misc/colors'

export const HeaderDiv = styled('div')<{ $frame: boolean }>(({ $frame }) =>
  $frame
    ? { display: 'none' }
    : {
        textAlign: 'center',
        paddingBottom: '20px',
        borderBottom: `1px solid ${colors.borderLighterGrey}`,
        margin: '0 10px 20px',
      }
)

const HeaderImg = styled('img')({
  border: 0,
  display: 'block',
  margin: '0 auto',
  width: '300px',
  height: '80px',
  objectFit: 'contain',
  '@media screen and (max-width: 330px)': {
    width: '100%',
    height: '100px',
  },
})

const HeaderName = styled('div')({
  fontSize: '28px',
  fontWeight: 300,
  display: 'inline-block',
  margin: '20px auto 10px',
})

interface Props {
  logo?: string
  title: string
}

export function SurveyHeader({ logo, title }: Props): JSX.Element {
  const theme = useTheme()
  return (
    <HeaderDiv $frame={theme.view === 'frame'} className="survey-form-header">
      {logo ? (
        <div className="survey-form-header-logo">
          <HeaderImg src={logo} />
        </div>
      ) : (
        <HeaderName className="survey-form-header-name">{title}</HeaderName>
      )}
    </HeaderDiv>
  )
}
