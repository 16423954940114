//assumes 3 or 6 -char hex color (not including #)
export function getBrightness(c: string) {
  if (c.length === 4) {
    c = c
      .split('')
      .map(function (hex) {
        return hex + hex
      })
      .join('')
  }

  const color = c.replace('#', '') // strip two # chars
  const rgb = parseInt(color, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  const luma: number = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
  return luma //0-255
}

export function isLight(c: string | boolean | string[]) {
  if (typeof c !== 'string') return true
  return getBrightness(c) > 160 ? true : false
}
