import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { CampaignSubmit } from '../../CampaignSubmit'
import { ConsentField } from '../../ConsentField/ConsentField'
import { SkipButton } from './SkipButton'
import { ContactInfoPictureField } from './ProfilePictureField'
import { UseContactInfo } from './useContactInfo'
import { ContactInfoFieldName } from './useReviewFields'
import { CampaignGroup } from '@tm/client-form/src/components/campaign/CampaignGroup'
import { TextReviewView } from './TextReviewView'
import { VideoReviewViewInner } from './VideoReviewView'

type ContactInfoFieldProps = Pick<
  UseContactInfo,
  'values' | 'errors' | 'labels' | 'isSubmitting' | 'onChange' | 'requiredFields'
> & {
  name: ContactInfoFieldName
  type: 'text' | 'email' | 'textarea'
}

const contactInfoFieldOrder = [
  'name',
  'neighborhood',
  'location',
  'state',
  'country',
  'imageFilename',
  'organization',
  'website',
  'position',
  'team',
  'companyLogo',
]

function ContactInfoField(props: ContactInfoFieldProps) {
  const { name, type, values, errors, labels, onChange, isSubmitting, requiredFields } = props
  const isTextarea = type === 'textarea'
  const fieldErrors = (name in errors && Array.isArray(errors[name]) ? errors[name] : []).join(', ')

  const isRequired = requiredFields.includes(name)
  const label = isRequired ? `${labels[name]} *` : labels[name]

  return (
    <TextField
      id={`q-${name}`}
      label={label}
      value={values[name]}
      error={!!fieldErrors}
      helperText={fieldErrors}
      onChange={event => onChange(name, event.target.value)}
      multiline={isTextarea}
      rows={isTextarea ? 4 : undefined}
      variant="filled"
      disabled={isSubmitting}
      type={type}
    />
  )
}

export function ContactInfoView(props: UseContactInfo) {
  const {
    values,
    onChange,
    labels,
    onSubmit,
    isSubmitting,
    title,
    errors,
    requiredFields,
    consentFieldProps,
    profilePictureFieldProps,
    logoPictureFieldProps,
    skipProps,
  } = props

  // Iteare over contactInfoFieldOrder and for each value add ContactInfoField component to an array
  // if type of the value is string and ContactInfoPictureField if the type is boolean and value is true.
  const contactInfoFields = useMemo(() => {
    return contactInfoFieldOrder.map(key => {
      const value = values[key]
      if (typeof value === 'string') {
        const fieldProps = {
          values,
          errors,
          labels,
          isSubmitting,
          onChange,
          requiredFields,
        }
        return (
          <ContactInfoField
            name={key as ContactInfoFieldName}
            key={`review-contact-info-${key}`}
            type="text"
            {...fieldProps}
          />
        )
      }
      if (profilePictureFieldProps && key === 'imageFilename' && value) {
        return <ContactInfoPictureField key={`review-contact-info-${key}`} {...profilePictureFieldProps} />
      }
      if (logoPictureFieldProps && key === 'companyLogo' && value) {
        return <ContactInfoPictureField key={`review-contact-info-${key}`} {...logoPictureFieldProps} />
      }
    })
  }, [values, profilePictureFieldProps, logoPictureFieldProps, errors, labels, isSubmitting, onChange, requiredFields])

  return (
    <>
      <VideoReviewViewInner {...props.videoReviewProps} />
      <TextReviewView {...props.textReviewProps} />
      <Typography variant="subtitle1">{title}</Typography>
      <CampaignGroup>{contactInfoFields}</CampaignGroup>
      <ConsentField {...consentFieldProps} />
      <CampaignSubmit onClick={onSubmit} disabled={isSubmitting} />
      <SkipButton {...skipProps} />
    </>
  )
}
