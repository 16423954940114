import { useCallback, useEffect } from 'react'
import { useI18N } from '../../../hooks/useI18n'
import { Action } from '../../../hooks/useSurvey/actions/action'
import { InProgressState } from '../../../hooks/useSurvey/states/inProgress'
import { ConsentFieldProps } from './ConsentField'

export type UseConsentProps = {
  state: InProgressState
  dispatch: React.Dispatch<Action>
  validationErrors: Record<string, string[]>
}

export type ConsentField = 'contactConsent' | 'publicConsent' | string

/**
 * Handle consent functionality
 */
export function useConsentField(props: UseConsentProps, consentFieldName: ConsentField = 'contactConsent') {
  const { state, dispatch, validationErrors } = props
  const publicConsent = state.publicConsent || 'default'
  const consentValue = state.values[consentFieldName] === true
  const { t } = useI18N()

  const setConsentValue = useCallback(
    (value: boolean) => {
      dispatch({
        type: 'set value',
        field: {
          name: consentFieldName,
          system: true, // Consent fields are always system fields
        },
        value,
      })
    },
    [dispatch, consentFieldName]
  )

  useEffect(() => {
    if (publicConsent === 'gdpr-compliant') return
    if (consentValue === true) return
    setConsentValue(true)
  }, [consentValue, publicConsent, setConsentValue])

  const consentFieldProps: ConsentFieldProps = {
    type: publicConsent,
    value: consentValue,
    onChange: setConsentValue,
    privacyPolicyOrganization: state.meta.privacyPolicyOrganization,
    privacyPolicyUrl: state.meta.privacyPolicy,
    validationErrors: (validationErrors[consentFieldName] || []).map(str => t(str)).join(', '),
    isPublicConsent: consentFieldName === 'publicConsent',
  }

  return consentFieldProps
}
