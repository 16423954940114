import { VideoContainer } from './VideoContainer'
import { VideoActionBar } from './VideoActionBar'
import Stack from '@mui/material/Stack'

export function VideoDemo() {
  return (
    <Stack>
      <VideoContainer
        sx={{
          bgcolor: 'black',
          borderRadius: 1,
        }}>
        <div />
      </VideoContainer>
      <VideoActionBar
        onRecordClick={() => {
          // Empty function
        }}
      />
    </Stack>
  )
}
