import { BaseState, State } from './state'

export interface NotFoundState extends BaseState {
  type: 'not found'
}

export const surveyNotFoundState: NotFoundState = {
  type: 'not found',
  processAction: (): State => {
    return surveyNotFoundState
  },
}
