import { InputProps, stringValue } from './types'
import { TextFieldField, EmailField } from '@tm/shared-lib/src/field'
import TextField from '@mui/material/TextField'
import { getTranslatedValue } from './getTranslatedValue'
import { useI18N, TranslateFunction } from '@tm/client-form/src/hooks/useI18n'

const getTextFieldLabel = (field: TextFieldField | EmailField, lang: string, t: TranslateFunction) => {
  // If labelKey is defined, use it to get the label
  if (field.labelKey) return t(field.labelKey)

  return getTranslatedValue(field.label, lang)
}

export function TextFieldInput(props: InputProps<TextFieldField | EmailField>) {
  const { field, value, onChange, isSubmitting, lang } = props
  const { t } = useI18N()
  const label = getTextFieldLabel(field, lang, t)
  const placeholder = getTranslatedValue(field.placeholder, lang)
  const type = field.type === 'email' ? 'email' : 'text'

  return (
    <TextField
      value={stringValue(value)}
      onChange={event => onChange(event.target.value)}
      disabled={isSubmitting}
      fullWidth
      variant="filled"
      key={`f-${field.name}`}
      id={`f-${field.name}`}
      hiddenLabel
      placeholder={placeholder || label}
      type={type}
    />
  )
}
