import { CustomValue } from '@tm/shared-lib/src/api'

export type InputProps<F> = {
  field: F
  value: CustomValue | undefined
  onChange: (value: CustomValue) => void
  lang: string
  isSubmitting: boolean
}

export const stringValue = (value: CustomValue | undefined, defaultValue = '') => {
  if (typeof value === 'string') return value
  return defaultValue
}

export const arrayValue = (value: CustomValue | undefined, defaultValue = []) => {
  if (Array.isArray(value)) return value
  return defaultValue
}

export const numberValue = (value: CustomValue | undefined, defaultValue?: number) => {
  if (typeof value === 'number') return value
  return defaultValue
}

export const booleanValue = (value: CustomValue | undefined, defaultValue?: boolean) => {
  if (typeof value === 'boolean') return value
  return defaultValue
}
