import Box from '@mui/material/Box'
import { SxProps, keyframes } from '@mui/material/styles'
import { useCampaign } from '@tm/client-form/src/hooks/useCampaign'

type OverlayType = 'radial' | 'circles'

type CampaignPageOverlayProps = {
  type: OverlayType
}

const fullPageSx: SxProps = {
  display: {
    xs: 'none',
    sm: 'block',
  },
  position: 'fixed',
  zIndex: 1,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
}

const w = 'rgba(255,255,255, 1)'
const w50 = 'rgba(255,255,255, .5)'
const w0 = 'rgba(255,255,255, 0)'

type BaseGradientParam = {
  colors: {
    pos?: number
    color: string
  }[]
  repeating?: boolean
  location?: 'top left' | 'top right' | 'bottom left' | 'bottom right' | string
}

type LinearGradientParam = BaseGradientParam & {
  type: 'linear'
}

type RadialGradientParam = BaseGradientParam & {
  type: 'radial'
  shape?: 'circle' | 'ellipse'
}

type ConicGradientParam = BaseGradientParam & {
  type: 'conic'
  deg?: number
}

type GradientParam = LinearGradientParam | RadialGradientParam | ConicGradientParam

const getGradient = (param: GradientParam) => {
  const colorsStr = param.colors
    .map(({ pos, color }) => `${color}${typeof pos === 'number' ? ` ${pos}%` : ''}`)
    .join(', ')
  const repeatingStr = param.repeating ? 'repeating-' : ''
  const locationStr = param.location ? ` at ${param.location}` : ''

  switch (param.type) {
    case 'linear':
      return `${repeatingStr}linear-gradient(${locationStr || '50% 50%'}, ${colorsStr})`
    case 'radial':
      return `${repeatingStr}radial-gradient(${param.shape || 'circle'}${locationStr}, ${colorsStr})`
    case 'conic':
      return `${repeatingStr}conic-gradient(from ${param.deg || 90}deg${locationStr}, ${colorsStr})`
  }

  return ''
}

const gradient = (...params: GradientParam[]) => {
  return params.map(getGradient).join(', ')
}

function RadialOverlay() {
  return (
    <Box
      sx={{
        ...fullPageSx,
        background: gradient(
          {
            type: 'radial',
            location: 'top left',
            colors: [
              { pos: 0, color: w },
              { pos: 50, color: w0 },
            ],
          },
          {
            type: 'radial',
            location: 'bottom right',
            colors: [
              { pos: 0, color: w50 },
              { pos: 40, color: w0 },
            ],
          }
        ),
        mixBlendMode: 'overlay',
        opacity: 0.8,
      }}
    />
  )
}

const circlesAnimation = keyframes({
  '0%': { backgroundPosition: '50% 50%, 50% 50%, 50% 50%' },
  '33%': { backgroundPosition: '80% 30%, 30% 20%, 20% 50%' },
  '66%': { backgroundPosition: '70% 100%, 60% 40%, 35% 10%' },
  '100%': { backgroundPosition: '50% 50%, 50% 50%, 50% 50%' },
})

function CirclesOverlay() {
  const { editor } = useCampaign()
  return (
    <Box
      sx={{
        ...fullPageSx,
        background: gradient(
          {
            type: 'radial',
            location: '60% 40%',
            colors: [
              { pos: 0, color: w0 },
              { pos: 10, color: w0 },
              { pos: 35, color: w },
              { pos: 35, color: w0 },
            ],
          },
          {
            type: 'radial',
            location: '30% 50%',
            colors: [
              { pos: 0, color: w0 },
              { pos: 10, color: w0 },
              { pos: 30, color: w },
              { pos: 30, color: w0 },
            ],
          },
          {
            type: 'radial',
            location: '60% 30%',
            colors: [
              { pos: 0, color: w0 },
              { pos: 10, color: w0 },
              { pos: 35, color: w },
              { pos: 35, color: w0 },
            ],
          }
        ),
        backgroundSize: '300% 300%',
        mixBlendMode: 'overlay',
        opacity: 0.3,
        ...(!editor && { animation: `${circlesAnimation} 180s linear infinite` }),
      }}
    />
  )
}

export function CampaignPageOverlay({ type }: CampaignPageOverlayProps) {
  switch (type) {
    case 'radial':
      return <RadialOverlay />
    case 'circles':
      return <CirclesOverlay />
    default:
      return null
  }
}
