import { ContentField } from '@tm/shared-lib/src/field'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

type ContentInputProps = {
  field: ContentField
  lang: string
}

export function ContentInput({ field, lang }: ContentInputProps) {
  const theme = useTheme()
  if (!field.content) return null
  const content = field.content[lang] || field.content._d
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: content }}
      sx={{
        ...theme.typography.body1,
        h1: theme.typography.h3,
        h2: theme.typography.h4,
        h3: theme.typography.h5,
        h4: theme.typography.h6,
        h5: theme.typography.h6,
        h6: theme.typography.h6,
        '& p': {
          m: 0,
        },
        '> *:first-child': {
          mt: 0,
        },
        ul: {
          pl: 2,
        },
        ol: {
          pl: 2,
        },
      }}
    />
  )
}
