import { StepValues } from '@tm/shared-lib/src/api'
import { sanitizeWysiwygHtml } from '@tm/shared-lib/src/sanitize'
import { MetaData, SocialShare } from '@tm/shared-lib/src/survey'
import { ThanksPage, ThirdPartyCollectData } from '@tm/types/survey/entities'
import { styled } from 'goober'
import React, { useEffect } from 'react'
import { useI18N } from '../../../hooks/useI18n'
import { useTheme } from '../../../hooks/useTheme'
import { HeaderDiv } from '../SurveyHeader'
import { ShareButtons } from './ShareButtons'
import { ThirdPartyCollect } from './ThirdPartyCollect'

const SurveyInner = styled('div')<{ view: string }>(({ view }) =>
  view === 'frame'
    ? {}
    : {
        padding: '30px 20px 20px 20px',
        maxWidth: '820px',
        margin: '0 auto',
        '@media screen and (max-width: 600px)': {
          padding: '10px',
        },
      }
)

const FrameThanksWrapper = styled('div')({
  textAlign: 'center',
  padding: '20px',
  background: '#f4f4f4',
  borderRadius: '3px',
})
const FrameThanksTitle = styled('h2')({ margin: '0 0 1em', fontSize: '1.2em', fontWeight: 500 })
const FrameThanksContent = styled('div')({
  '.survey-thanks-greetings': {
    marginTop: '2em',
    opacity: 0.5,
  },
})

const ThanksLogo = styled('img')({
  border: 0,
  display: 'block',
  margin: '0 auto',
  width: '160px',
  height: '80px',
  objectFit: 'contain',
  '@media screen and (max-width: 330px)': {
    width: '100%',
    height: '100px',
  },
})

const ThanksHeader = styled('div')({
  fontSize: '28px',
  fontWeight: 300,
  display: 'inline-block',
  margin: '20px auto 10px',
})
const ThanksContent = styled('div')({
  textAlign: 'center',
  fontSize: '18px',
  color: '#364155',
})

const ThanksContentMessage = styled('div')({
  padding: '20px 20px 60px',
  h2: {
    fontSize: '30px',
    lineHeight: 1.2,
    letterSpacing: '1px',
  },
  p: {
    margin: 0,
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    margin: '1em auto',
    height: 'auto',
  },
})

function FrameThanks({
  title,
  text,
  thirdPartyCollect,
}: {
  title: string
  text: string
  thirdPartyCollect?: ThirdPartyCollectData
}) {
  const { lang } = useI18N()
  return (
    <FrameThanksWrapper>
      <FrameThanksTitle>
        {title.split('\n').map((s, k) => (
          <div key={k}>{s}</div>
        ))}
      </FrameThanksTitle>
      <FrameThanksContent
        className="frame-thanks-content"
        dangerouslySetInnerHTML={{ __html: sanitizeWysiwygHtml(text) }}
      />
      <ThirdPartyCollect value={thirdPartyCollect} language={lang} />
    </FrameThanksWrapper>
  )
}

export interface Props {
  meta: MetaData
  answerId?: string
  view?: string
  surveyFeatures?: string[]
  handleSurveyReset: () => void
  preview?: boolean
  thanks?: Partial<ThanksPage>
  share?: SocialShare
  values: StepValues
  updateTitle?: boolean
}

export function SurveyThanks(props: Props): JSX.Element {
  const {
    meta,
    view = 'default',
    surveyFeatures = [],
    handleSurveyReset,
    preview = false,
    thanks,
    values,
    updateTitle = true,
  } = props
  const theme = useTheme()
  const { t, lang } = useI18N()

  useEffect(() => {
    if (!updateTitle) return
    document.title = t('survey.title')
  }, [t])

  useEffect(() => {
    if (surveyFeatures.includes('autoReset')) {
      const timeout = setTimeout(handleSurveyReset, 5000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [surveyFeatures, handleSurveyReset])

  const { logo = false, companyName, title = false } = meta

  const thanksPage = {
    title: thanks?.title || {},
    text: thanks?.text || {},
    thirdPartyCollect: thanks?.thirdPartyCollect,
  }
  const { title: thanksTitle, text: thanksText, thirdPartyCollect } = thanksPage

  const thanksTitleStr = !(lang in thanksTitle) ? t('thanks.title') : thanksTitle[lang]

  const thanksTextStr =
    typeof thanksText[lang] === 'undefined'
      ? /*html*/ `${t('thanks.text')}
  <div class="survey-thanks-greetings">${t('thanks.greetings')}<br>${companyName}</div>`
      : thanksText[lang]

  if (view === 'frame') return <FrameThanks title={thanksTitleStr} text={thanksTextStr} />

  return (
    <div className="survey-thanks">
      <SurveyInner className="survey-thanks-inner" view={view}>
        {!preview && (
          <HeaderDiv $frame={theme.view === 'frame'} className="survey-thanks-header">
            {logo ? (
              <div className="survey-thanks-header-logo">
                <ThanksLogo src={logo} />
              </div>
            ) : (
              <ThanksHeader className="survey-thanks-header-name">{title || companyName}</ThanksHeader>
            )}
          </HeaderDiv>
        )}
        <ThanksContent className="survey-thanks-content">
          <ThanksContentMessage className="survey-thanks-content-message">
            <h2>
              {thanksTitleStr.split('\n').map((s, k) => (
                <div key={k}>{s}</div>
              ))}
            </h2>
            <div
              className="survey-thanks-content-message-content"
              dangerouslySetInnerHTML={{ __html: sanitizeWysiwygHtml(thanksTextStr) }}
            />
            {!preview && (
              <ThirdPartyCollect
                value={thirdPartyCollect}
                language={lang}
                autoRedirect={thirdPartyCollect?.autoRedirect}
              />
            )}
          </ThanksContentMessage>
          <ShareButtons
            {...props}
            trackEvent={() => {
              //
            }}
            values={values}
          />
        </ThanksContent>
      </SurveyInner>
    </div>
  )
}
