import { styled } from 'goober'
import React from 'react'
import { useTheme } from '../../hooks/useTheme'

export const PageInnerDiv = styled('div')<{ $frame: boolean }>(({ $frame, theme }) =>
  $frame
    ? {}
    : {
        background: '#fff',
        boxShadow: '0 2px 6px rgba(0, 0, 0, .05)',
        '@media screen and (min-width: 600px)': {
          marginTop: '40px',
        },
        '.page .survey-langswitcher +&': {
          marginTop: 0,
        },
      }
)

export function PageInner({ children, className }: { children: JSX.Element; className?: string }): JSX.Element {
  const { view } = useTheme()
  return (
    <PageInnerDiv $frame={view === 'frame'} className={className}>
      {children}
    </PageInnerDiv>
  )
}
