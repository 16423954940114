import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'
import { faExclamationTriangle } from '../../misc/icon'
import { PageInner } from '../page/PageInner'

interface Props {
  message: string
}

const SurveyMessageWrapper = styled('div')({
  display: 'flex',
  height: '400px',
})

const SurveyMessageInner = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  maxWidth: '500px',
})

const SurveyMessageIcon = styled('div')({
  display: 'inline-block',
  marginBottom: '20px',
  fontSize: '48px',
  color: '#999',
})

const SurveyMessageText = styled('div')({
  fontSize: '26px',
})

export function SurveyMessage({ message }: Props): JSX.Element {
  return (
    <PageInner>
      <SurveyMessageWrapper className="survey-message">
        <SurveyMessageInner className="survey-message-inner">
          <SurveyMessageIcon className="survey-message-icon">
            <Icon icon={faExclamationTriangle} />
          </SurveyMessageIcon>
          <SurveyMessageText className="survey-message-text">{message}</SurveyMessageText>
        </SurveyMessageInner>
      </SurveyMessageWrapper>
    </PageInner>
  )
}
