import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { Icon } from '@trustmary/icons'
import { styled } from 'goober'
import React from 'react'

const Action = styled('div')(({ theme }) => theme.styles.submitWrapper)
const Button = styled('button')(({ theme }) => theme.styles.submitButton)
const IconWrapper = styled('div')(({ theme }) => theme.styles.submitButtonIcon)

interface Props {
  text: string
  icon: 'active' | 'loading'
  disabled?: boolean
  type?: 'submit' | 'button' | 'reset'
}

export function SurveyAction({ text, icon, disabled = false, type = 'submit' }: Props): JSX.Element {
  return (
    <Action className="survey-form-submit">
      <Button className="survey-form-submit-button" type={type} disabled={disabled}>
        <IconWrapper>
          <Icon icon={icon === 'loading' ? faSpinner : faChevronRight} spin={icon === 'loading'} />
        </IconWrapper>
        <span>{text}</span>
      </Button>
    </Action>
  )
}
